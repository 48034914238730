import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FieldSelect } from '../../components';

const FieldSelectOption = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    validate,
    placeHolder,
    options,
    requiredLabel,
  } = props;
  const classes = classNames(rootClassName || className);
  return options ? (
    <FieldSelect
      className={classes}
      id={id}
      name={name}
      label={label}
      validate={validate}
      requiredLabel={requiredLabel}
    >
      {placeHolder && (
        <option disabled value="">
          {placeHolder}
        </option>
      )}
      {options.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

FieldSelectOption.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  requiredLabel: null,
  placeHolder: null,
  validate: null,
  options: [],
};

const { string, func, array } = PropTypes;

FieldSelectOption.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: string,
  requiredLabel: string,
  name: string.isRequired,
  placeHolder: string,
  validate: func,
  options: array,
};

export default FieldSelectOption;
