import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ListingLink } from '../../components';
import { EditListingCoacheeForm } from '../../forms';

import css from './EditListingCoacheePanel.module.css';

const { LatLng } = sdkTypes;

const EditListingCoacheePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const {
    publicData: {
      firstName,
      familyName,
      roleTitle,
      emailCoachee,
      location,
      reportingLine,
      careerPhase,
      preferredLanguage,
      isCoacheeInvited,
    },
  } = currentListing.attributes;
  const selectedPlace = location?.selectedPlace;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingCoacheePanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingCoacheePanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <h3 className={css.subTitle}>
        <FormattedMessage
          id="EditListingCoacheePanel.subTitle"
        />
      </h3>
      <EditListingCoacheeForm
        className={css.form}
        keepDirtyOnReinitialize
        initialValues={{
          firstName,
          familyName,
          roleTitle,
          emailCoachee,
          location: !isEmpty(selectedPlace)
            ? {
                ...location,
                selectedPlace: {
                  ...selectedPlace,
                  origin: new LatLng(selectedPlace.origin.lat, selectedPlace.origin.lng), // validator will check if it is instance of LatLng
                },
              }
            : null,
          reportingLine,
          careerPhase,
          preferredLanguage
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            firstName,
            familyName,
            roleTitle,
            emailCoachee: newEmailCoachee,
            location,
            reportingLine,
            careerPhase,
            preferredLanguage
          } = values;
          const engagementTitle = location?.selectedPlace
            ? `Engagement Listing: ${firstName}, ${location.selectedPlace.address}`
            : `Engagement Listing: ${firstName}`;
          const updateValues = {
            title: engagementTitle,
            publicData: {
              firstName,
              familyName,
              roleTitle,
              emailCoachee: newEmailCoachee,
              location,
              reportingLine,
              careerPhase,
              preferredLanguage,
              isCoacheeInvited: newEmailCoachee === emailCoachee ? isCoacheeInvited : false,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingCoacheePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingCoacheePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  ensuredCurrentUser: object
};

export default EditListingCoacheePanel;
