import React, { useState } from 'react';
// Import moment from moment-timezone. 10-year range only.
// The full data included in moment-timezone dependency is mostly irrelevant
// and slows down the first paint.
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { isInclusivelyAfterDay } from 'react-dates';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { drop, isEqual } from 'lodash';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  bookingDateRequired,
  required,
} from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCurrencyInput, FieldSelectOption, FieldDateInput } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './EditListingEngagementForm.module.css';
import { coachingFormatOptionsForDropdown, REQUIRED_LABEL } from '../../marketplace-custom-config';
import { dateIsAfter, getDefaultTimeZoneOnBrowser, getMonthStartInTimeZone, nextMonthFn, prevMonthFn, timeOfDayFromLocalToTimeZone, timeOfDayFromTimeZoneToLocal } from '../../util/dates';
import NextMonthIcon from '../BookingTimeForm/NextMonthIcon';
import PreviousMonthIcon from '../BookingTimeForm/PreviousMonthIcon';

const MIN_SESSIONS = 4;
const MAX_SESSIONS = 20;
const TODAY = new Date();

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const Next = () => {
  return <NextMonthIcon />;
};

const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

const EditListingEngagementFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values,
      } = formRenderProps;

      const timeZone = defaultTimeZone();
      const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(TODAY, timeZone));
      const [submittedData, setSubmittedData] = useState();

      // Reason for coaching
      const reasonForCoachingLabel = intl.formatMessage({
        id: 'EditListingEngagementForm.reasonForCoachingLabel',
      });
      const reasonForCoachingPlaceholder = intl.formatMessage({
        id: 'EditListingEngagementForm.reasonForCoachingPlaceholder',
      });
      const reasonForCoachingRequiredMessage = intl.formatMessage({
        id: 'EditListingEngagementForm.reasonForCoachingRequired',
      });
      const reasonForCoachingRequired = required(reasonForCoachingRequiredMessage);

      // Coaching format
      const coachingFormatLabel = intl.formatMessage({
        id: 'EditListingEngagementForm.coachingFormatLabel',
      });
      const coachingFormatPlaceholder = intl.formatMessage({
        id: 'EditListingEngagementForm.coachingFormatPlaceholder',
      });
      const coachingFormatRequiredMessage = intl.formatMessage({
        id: 'EditListingEngagementForm.coachingFormatRequired',
      });
      const coachingFormatRequired = required(coachingFormatRequiredMessage);

      // What are the goals of this coaching engagement? What will success look like?
      const goalsOfEngagementLabel = intl.formatMessage({
        id: 'EditListingEngagementForm.goalsOfEngagementLabel',
      });

      // Please explain any further context the coach should know to prepare them for the chemistry meeting
      const explainFurtherContextLabel = intl.formatMessage({
        id: 'EditListingEngagementForm.explainFurtherContextLabel',
      });

      // How many 1-hour sessions do you anticipate for this
      const howManyOneHourSessionsLabel = intl.formatMessage({
        id: 'EditListingEngagementForm.howManyOneHourSessionsLabel',
      });
      const howManyOneHourSessionsPlaceholder = intl.formatMessage({
        id: 'EditListingEngagementForm.howManyOneHourSessionsPlaceholder',
      });
      const howManyOneHourSessionsRequiredMessage = intl.formatMessage({
        id: 'EditListingEngagementForm.howManyOneHourSessionsRequired',
      });
      const howManyOneHourSessionsRequired = required(howManyOneHourSessionsRequiredMessage);

      // Max budget
      const maxBudgetLabel = intl.formatMessage(
        { id: 'EditListingEngagementForm.maxBudgetLabel' },
        { currency: config.currency }
      );
      const maxBudgetPlaceholder = intl.formatMessage({
        id: 'EditListingEngagementForm.maxBudgetPlaceholder',
      });
      const maxBudgetRequiredMessage = intl.formatMessage({
        id: 'EditListingEngagementForm.maxBudgetRequired',
      });
      const maxBudgetRequired = required(maxBudgetRequiredMessage);

      // Engagement start date
      const startDateLabel = intl.formatMessage({
        id: 'EditListingEngagementForm.bookingStartTitle',
      });
      const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };
      const startDatePlaceholder = intl.formatDate(TODAY, dateFormattingOptions);
      const startDateRequiredMessage = intl.formatMessage({
        id: 'EditListingEngagementForm.startDateRequiredMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const pristineSinceLastSubmit = isEqual(values, submittedData);
      const submitReady = (updated && pristineSinceLastSubmit) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const reasonForCoachingOptions = findOptionsForSelectFilter('developmentSituations', filterConfig);
      const sessionsOptions = drop([...Array(MAX_SESSIONS).keys()].map(i => i + 1), MIN_SESSIONS)
        .map(number => ({ key: number, label: number }));

      const onMonthClick = monthFn => {
        setCurrentMonth(monthFn(currentMonth, timeZone));
      };

      const isOutsideRange = day => {
        return !isInclusivelyAfterDay(day, moment());
      };

      return (
        <Form
          className={classes}
          onSubmit={e => {
            setSubmittedData(values);
            handleSubmit(e);
          }}
        >
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.rowWrapper}>
            <FieldSelectOption
              className={css.dropdownField}
              id="reasonForCoaching"
              name="reasonForCoaching"
              options={reasonForCoachingOptions}
              label={reasonForCoachingLabel}
              placeHolder={reasonForCoachingPlaceholder}
              validate={reasonForCoachingRequired}
              requiredLabel={REQUIRED_LABEL}
            />
            <FieldSelectOption
              className={css.dropdownField}
              id="coachingFormat"
              name="coachingFormat"
              options={coachingFormatOptionsForDropdown}
              label={coachingFormatLabel}
              placeHolder={coachingFormatPlaceholder}
              validate={coachingFormatRequired}
              requiredLabel={REQUIRED_LABEL}
            />
          </div>

          <FieldTextInput
            className={css.goalsOfEngagement}
            type="textarea"
            id="goalsOfEngagement"
            name="goalsOfEngagement"
            label={goalsOfEngagementLabel}
          />

          <FieldTextInput
            className={css.explainFurtherContext}
            type="textarea"
            id="explainFurtherContext"
            name="explainFurtherContext"
            label={explainFurtherContextLabel}
          />

          <div className={css.rowWrapper}>
            <FieldSelectOption
              className={css.dropdownField}
              id="howManyOneHourSessions"
              name="howManyOneHourSessions"
              options={sessionsOptions}
              label={howManyOneHourSessionsLabel}
              placeHolder={howManyOneHourSessionsPlaceholder}
              validate={howManyOneHourSessionsRequired}
              requiredLabel={REQUIRED_LABEL}
            />
            <FieldCurrencyInput
              id="maxBudget"
              name="maxBudget"
              className={css.maxBudget}
              label={maxBudgetLabel}
              placeholder={maxBudgetPlaceholder}
              currencyConfig={config.currencyConfig}
              validate={maxBudgetRequired}
              requiredLabel={REQUIRED_LABEL}
            />
          </div>

          <FieldDateInput
            className={css.fieldDateInput}
            name="engagementStartDate"
            id="engagementStartDate"
            label={startDateLabel}
            placeholderText={startDatePlaceholder}
            format={v =>
              v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
            }
            parse={v =>
              v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
            }
            onPrevMonthClick={() => onMonthClick(prevMonthFn)}
            onNextMonthClick={() => onMonthClick(nextMonthFn)}
            navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
            navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
            useMobileMargins
            showErrorMessage={false}
            validate={bookingDateRequired(startDateRequiredMessage)}
            isOutsideRange={isOutsideRange}
            requiredLabel={REQUIRED_LABEL}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingEngagementFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingEngagementFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingEngagementFormComponent);
