import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal, NamedLink, ExternalLink } from '..';
import config from '../../config';

import css from './ModalWelcomeAnonymous.module.css';

class ModalWelcomeAnonymous extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      isOpen,
      onClose,
      isAuthenticated,
    } = this.props;

    const { siteToContact } = config;

    const classes = classNames(rootClassName || css.root, className);

    const closeButtonMessage = <FormattedMessage id="ModalWelcomeAnonymous.closeModal" />;

    const loginLink = (
      <NamedLink className={css.mainContentLink} name="LoginPage">
        <FormattedMessage id="ModalWelcomeAnonymous.loginText" />
      </NamedLink>
    );

    const signupLink = (
      <NamedLink className={css.mainContentLink} name="SignupPage">
        <FormattedMessage id="ModalWelcomeAnonymous.signupText" />
      </NamedLink>
    );

    const coachSignupLink = (
      <NamedLink className={css.subContentLink} name="SignupPage">
        <FormattedMessage id="ModalWelcomeAnonymous.here" />
      </NamedLink>
    );

    const emailLink = (
      <ExternalLink className={css.subContentLink} href={`mailTo:${siteToContact}`}>
        <FormattedMessage id="ModalWelcomeAnonymous.here" />
      </ExternalLink>
    );
    
    const zohoFormLink = (
      <ExternalLink
        className={css.subContentLink}
        href={`https://npqusd-glf.maillist-manage.com/ua/Optin?od=11287ecc786fdd&zx=12859b91f&tD=110bf14b6ae55d3c1&sD=110bf14b6ae66f30f`}
      >
        <FormattedMessage id="ModalWelcomeAnonymous.zohoFormLinkText" />
      </ExternalLink>
    );

    const createEngagementLink = (
      <NamedLink className={css.mainContentLink} name="NewEngagementPage">
        <FormattedMessage id="ModalWelcomeAnonymous.here" />
      </NamedLink>
    );

    const anonymousContent = (
      <>
        <h1 className={css.modalTitle}>
          <FormattedMessage id={'ModalWelcomeAnonymous.title'} />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="ModalWelcomeAnonymous.welcomeText" />
        </p>
        <p className={css.modalMessage}>
          <FormattedMessage
            id="ModalWelcomeAnonymous.engagementFeeText"
            tagName={null}
            values={{
              feesLink: (
                <ExternalLink href="https://boldly.app/knowledge-hub/how-does-boldly-costing-work">
                  <FormattedMessage id="ModalWelcomeAnonymous.feesLinkText" />
                </ExternalLink>
              ),
            }}
          />
        </p>
        <p className={css.mainContent}>
          <FormattedMessage
            id="ModalWelcomeAnonymous.mainContent"
            values={{ loginLink, br: <br />, signupLink }}
          />
        </p>
        <p className={css.subContent}>
          <FormattedMessage
            id="ModalWelcomeAnonymous.subContent"
            values={{ coachSignupLink, br: <br />, zohoFormLink }}
          />
        </p>
      </>
    );

    const buyerHasNoActiveEngagement = (
      <>
        <h1 className={css.modalTitle}>
          <FormattedMessage id={'ModalWelcomeAnonymous.title'} />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="ModalWelcomeAnonymous.authenticatedWelcomeText" />
        </p>
        <p className={css.mainContent}>
          <FormattedMessage
            id="ModalWelcomeAnonymous.createEngagementText"
            values={{ createEngagementLink }}
          />
          <br />
          <FormattedMessage
            id="ModalWelcomeAnonymous.contactText"
            values={{
              emailLink: (
                <ExternalLink className={css.mainContentLink} href={`mailTo:${siteToContact}`}>
                  <FormattedMessage id="ModalWelcomeAnonymous.here" />
                </ExternalLink>
              ),
            }}
          />
        </p>
      </>
    );

    return (
      <Modal
        id="ModalWelcomeAnonymous"
        containerClassName={containerClassName}
        isOpen={isOpen}
        onClose={onClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
      >
        <div className={classes}>
          {isAuthenticated ? buyerHasNoActiveEngagement : anonymousContent}
        </div>
      </Modal>
    );
  }
}

ModalWelcomeAnonymous.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
  isFreePlan: true,
  isOpen: false,
  subscriptionPlanNameText: null,
  isAuthenticated: false,
};

ModalWelcomeAnonymous.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,
  isFreePlan: bool,
  onManageDisableScrolling: func.isRequired,
  isOpen: bool,
  onClose: func,
  subscriptionPlanNameText: string,
  isAuthenticated: bool,
};

ModalWelcomeAnonymous.displayName = 'ModalWelcomeAnonymous';

export default ModalWelcomeAnonymous;
