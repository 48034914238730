import React from 'react';
import Top from './Top';
import Left from './Left';
import Right from './Right';
import { FormattedMessage } from 'react-intl';
import css from './ProcessDiagram.module.css';

const Step = ({ firstStep, children }) => (
  <div className={css.stepContainer}>
    {!firstStep && <li className={css.stepArrow} />}
    <li className={css.step}>{children}</li>
  </div>
);

const Arrow = () => (
  <div className={css.arrow}>
    <p>
      <FormattedMessage id="EditListingProcessPanel.processDiagram.arrowDescription" />
    </p>
    <svg
      width="455"
      height="18"
      viewBox="0 0 455 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M455 8.99998L440 0.339723L440 17.6602L455 8.99998ZM7.89687e-08 10.5L441.5 10.5L441.5 7.49998L-7.89687e-08 7.5L7.89687e-08 10.5Z"
        fill="#FF5866"
      />
    </svg>
  </div>
);

export default function ProcessDiagram() {
  const steps = [
    'The coachee',
    'The coaching need',
    'Success factor',
    'Agreement',
    'Listing fee \n $59 USD',
  ];

  return (
    <div className={css.diagram}>
      <div className={css.background}>
        <Top className={css.illustratorTop} />
        <Left className={css.illustratorLeft} />
        <Right className={css.illustratorRight} />
      </div>
      <p className={css.description}>
        <FormattedMessage id="EditListingProcessPanel.processDiagram.description" />
      </p>
      <p className={css.ctaDescription}>
        <FormattedMessage id="EditListingProcessPanel.processDiagram.ctaDescription" />
      </p>
      <div className={css.foreground}>
        <div className={css.processContainer}>
          <ul className={css.process}>
            {steps.map((step, index) => (
              <Step key={step} firstStep={index === 0}>
                {step}
              </Step>
            ))}
          </ul>
          <Arrow />
        </div>
      </div>
    </div>
  );
}
