import * as React from 'react';
const Top = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={148}
    zoomAndPan="magnify"
    viewBox="0 0 110.88 98.249997"
    height={131}
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <defs>
      <filter x="0%" y="0%" width="100%" height="100%" id={8295855490}>
        <feColorMatrix
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          colorInterpolationFilters="sRGB"
        />
      </filter>
      <clipPath id="ea14b580d7">
        <path d="M 1 27 L 6 27 L 6 32 L 1 32 Z M 1 27 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="7c1e452cbd">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0dd5d148a4">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="108df2691a">
        <path d="M 10 18 L 15 18 L 15 23 L 10 23 Z M 10 18 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="6ecf407fc3">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="03a3d86270">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="28b335d89c">
        <path d="M 19 9 L 24 9 L 24 14 L 19 14 Z M 19 9 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="07e1c8dcea">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="bebc473b71">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d77c01b1dd">
        <path d="M 29 0 L 34 0 L 34 6 L 29 6 Z M 29 0 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="b5fbeec518">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f343142350">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="7a2916f74b">
        <path d="M 10 36 L 15 36 L 15 41 L 10 41 Z M 10 36 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="58260e210a">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="7445166f4d">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="3b871251ed">
        <path d="M 19 27 L 24 27 L 24 33 L 19 33 Z M 19 27 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="47ae09d3b4">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0476f05128">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="8f766f11ee">
        <path d="M 28 19 L 33 19 L 33 24 L 28 24 Z M 28 19 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="4450d50962">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="86545745d3">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="91e7fc4ce3">
        <path d="M 37 10 L 43 10 L 43 15 L 37 15 Z M 37 10 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="66395fae89">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b42b78cc8b">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="82fcd03283">
        <path d="M 17 44 L 25 44 L 25 52 L 17 52 Z M 17 44 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="be7bfe25ee">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="910d50c362">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="7a689264e6">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="9b7be89fba">
        <path d="M 2 2 L 7 2 L 7 7 L 2 7 Z M 2 2 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="176d128a08">
        <path
          d="M -16.636719 -14.4375 L 13.996094 -43.789062 L 78.878906 23.925781 L 48.246094 53.277344 Z M -16.636719 -14.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="5debe3f278">
        <path
          d="M -16.636719 -14.4375 L 13.996094 -43.789062 L 78.878906 23.925781 L 48.246094 53.277344 Z M -16.636719 -14.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="79baef2ac2">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="5cd6d68c35">
        <path d="M 27 35 L 34 35 L 34 43 L 27 43 Z M 27 35 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="b549b06b81">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f0e86e2d93">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="d0d8dea3d9">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="5865731cc0">
        <path d="M 1 2 L 6 2 L 6 7 L 1 7 Z M 1 2 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="303d537329">
        <path
          d="M -26.636719 -5.4375 L 3.996094 -34.789062 L 68.878906 32.925781 L 38.246094 62.277344 Z M -26.636719 -5.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="7dab741062">
        <path
          d="M -26.636719 -5.4375 L 3.996094 -34.789062 L 68.878906 32.925781 L 38.246094 62.277344 Z M -26.636719 -5.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="23c979f8d7">
        <rect x={0} width={7} y={0} height={8} />
      </clipPath>
      <clipPath id="1a463dc361">
        <path d="M 36 27 L 43 27 L 43 34 L 36 34 Z M 36 27 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="bdae9775f6">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="2ecffc5e30">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="d6e900155d">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="1856146bb5">
        <path d="M 1 1 L 6 1 L 6 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="1dda9bf4df">
        <path
          d="M -35.636719 2.5625 L -5.003906 -26.789062 L 59.878906 40.925781 L 29.246094 70.277344 Z M -35.636719 2.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="35bf396698">
        <path
          d="M -35.636719 2.5625 L -5.003906 -26.789062 L 59.878906 40.925781 L 29.246094 70.277344 Z M -35.636719 2.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="ecdb95b113">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <clipPath id="7329470d3c">
        <path d="M 45 18 L 53 18 L 53 26 L 45 26 Z M 45 18 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="50b7f8c8f4">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="94e6a3cabd">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="44fdc0486c">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="efc29b9aa9">
        <path d="M 1 1 L 6 1 L 6 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="dda906ffd5">
        <path
          d="M -44.636719 11.5625 L -14.003906 -17.789062 L 50.878906 49.925781 L 20.246094 79.277344 Z M -44.636719 11.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="32665ee7ff">
        <path
          d="M -44.636719 11.5625 L -14.003906 -17.789062 L 50.878906 49.925781 L 20.246094 79.277344 Z M -44.636719 11.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="a255f11a71">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="f5ac6abe12">
        <path d="M 26 53 L 34 53 L 34 61 L 26 61 Z M 26 53 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="a1e52b5209">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="29314168f6">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="935c51ac16">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="e3ad8f0e0d">
        <path d="M 1 2 L 7 2 L 7 7 L 1 7 Z M 1 2 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="fe37fb5dc8">
        <path
          d="M -25.636719 -23.4375 L 4.996094 -52.789062 L 69.878906 14.925781 L 39.246094 44.277344 Z M -25.636719 -23.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="7b46a3a860">
        <path
          d="M -25.636719 -23.4375 L 4.996094 -52.789062 L 69.878906 14.925781 L 39.246094 44.277344 Z M -25.636719 -23.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="5abf5e4ba9">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="c93bcb2b01">
        <path d="M 35 45 L 43 45 L 43 52 L 35 52 Z M 35 45 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="710f8ff5d9">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="4690fb61c9">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="707b9af035">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="9bd41d335c">
        <path d="M 2 1 L 7 1 L 7 6 L 2 6 Z M 2 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="d0ff3bb77c">
        <path
          d="M -34.636719 -15.4375 L -4.003906 -44.789062 L 60.878906 22.925781 L 30.246094 52.277344 Z M -34.636719 -15.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="9f9f01f344">
        <path
          d="M -34.636719 -15.4375 L -4.003906 -44.789062 L 60.878906 22.925781 L 30.246094 52.277344 Z M -34.636719 -15.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d14812ad8b">
        <rect x={0} width={8} y={0} height={7} />
      </clipPath>
      <clipPath id="08f6f8762d">
        <path d="M 45 36 L 52 36 L 52 43 L 45 43 Z M 45 36 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="aef2ad886e">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="08de772a36">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="187eb1aa01">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="69a0150e36">
        <path d="M 1 1 L 6 1 L 6 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="15a9b7c421">
        <path
          d="M -44.636719 -6.4375 L -14.003906 -35.789062 L 50.878906 31.925781 L 20.246094 61.277344 Z M -44.636719 -6.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="aff9ea4ddf">
        <path
          d="M -44.636719 -6.4375 L -14.003906 -35.789062 L 50.878906 31.925781 L 20.246094 61.277344 Z M -44.636719 -6.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="10ebdbd465">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <clipPath id="802ca9248a">
        <path d="M 54 27 L 61 27 L 61 35 L 54 35 Z M 54 27 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="2551fe1e7d">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="525ab9d9f6">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="23b74a950d">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="e733e65f4b">
        <path d="M 1 1 L 6 1 L 6 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="d1a5e8cc8e">
        <path
          d="M -53.636719 2.5625 L -23.003906 -26.789062 L 41.878906 40.925781 L 11.246094 70.277344 Z M -53.636719 2.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="737d09d882">
        <path
          d="M -53.636719 2.5625 L -23.003906 -26.789062 L 41.878906 40.925781 L 11.246094 70.277344 Z M -53.636719 2.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="a0fb9d9f3d">
        <rect x={0} width={7} y={0} height={8} />
      </clipPath>
      <clipPath id="6f8cb0f5b4">
        <path d="M 35 63 L 43 63 L 43 71 L 35 71 Z M 35 63 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="66c4f8590e">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="194f7e8b4a">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="ee181d0103">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="0d9a484d83">
        <path d="M 1 1 L 6 1 L 6 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="261d47fed0">
        <path
          d="M -34.636719 -33.4375 L -4.003906 -62.789062 L 60.878906 4.925781 L 30.246094 34.277344 Z M -34.636719 -33.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="5d38916314">
        <path
          d="M -34.636719 -33.4375 L -4.003906 -62.789062 L 60.878906 4.925781 L 30.246094 34.277344 Z M -34.636719 -33.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="69ed3c189b">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="2da719c446">
        <path d="M 44 54 L 52 54 L 52 62 L 44 62 Z M 44 54 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="b41dfbd69a">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b50ca2be29">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id={Infinity}>
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="0dab956dcc">
        <path d="M 1 1 L 7 1 L 7 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="7a9e0bc04d">
        <path
          d="M -43.636719 -24.4375 L -13.003906 -53.789062 L 51.878906 13.925781 L 21.246094 43.277344 Z M -43.636719 -24.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="5bff3b2f23">
        <path
          d="M -43.636719 -24.4375 L -13.003906 -53.789062 L 51.878906 13.925781 L 21.246094 43.277344 Z M -43.636719 -24.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="14b4c84e56">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="fcda64e9cf">
        <path d="M 54 45 L 61 45 L 61 53 L 54 53 Z M 54 45 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="7655c984c0">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="3ba587a60d">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="3b2e2fe95b">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="bd0de14e84">
        <path d="M 1 1 L 6 1 L 6 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="c8ad27969e">
        <path
          d="M -53.636719 -15.4375 L -23.003906 -44.789062 L 41.878906 22.925781 L 11.246094 52.277344 Z M -53.636719 -15.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="ee648f83f5">
        <path
          d="M -53.636719 -15.4375 L -23.003906 -44.789062 L 41.878906 22.925781 L 11.246094 52.277344 Z M -53.636719 -15.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="8f6eff54b3">
        <rect x={0} width={7} y={0} height={8} />
      </clipPath>
      <clipPath id="12c20f53b6">
        <path d="M 63 36 L 70 36 L 70 44 L 63 44 Z M 63 36 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="307247b3f8">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="248bcd86e5">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="ba55670f48">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="16854a581c">
        <path d="M 1 1 L 6 1 L 6 7 L 1 7 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="197d72c3dd">
        <path
          d="M -62.636719 -6.4375 L -32.003906 -35.789062 L 32.878906 31.925781 L 2.246094 61.277344 Z M -62.636719 -6.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="994323793d">
        <path
          d="M -62.636719 -6.4375 L -32.003906 -35.789062 L 32.878906 31.925781 L 2.246094 61.277344 Z M -62.636719 -6.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="a76a924d4b">
        <rect x={0} width={7} y={0} height={8} />
      </clipPath>
      <clipPath id="39bd712336">
        <path d="M 44 72 L 52 72 L 52 80 L 44 80 Z M 44 72 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="cb0f54ff1f">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="fcdbf91c32">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="6b5869b6f2">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="4bd6478ffa">
        <path d="M 1 1 L 6 1 L 6 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="feb7cdd7f8">
        <path
          d="M -43.636719 -42.4375 L -13.003906 -71.789062 L 51.878906 -4.074219 L 21.246094 25.277344 Z M -43.636719 -42.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="2976b96f33">
        <path
          d="M -43.636719 -42.4375 L -13.003906 -71.789062 L 51.878906 -4.074219 L 21.246094 25.277344 Z M -43.636719 -42.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="bd75b4fb9a">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="eb76fadac2">
        <path d="M 53 63 L 61 63 L 61 71 L 53 71 Z M 53 63 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="50315ae47d">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="9edffad452">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="2af0184d75">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="46a2bc05ab">
        <path d="M 1 1 L 7 1 L 7 7 L 1 7 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="d4737224c4">
        <path
          d="M -52.636719 -33.4375 L -22.003906 -62.789062 L 42.878906 4.925781 L 12.246094 34.277344 Z M -52.636719 -33.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="781acfad33">
        <path
          d="M -52.636719 -33.4375 L -22.003906 -62.789062 L 42.878906 4.925781 L 12.246094 34.277344 Z M -52.636719 -33.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0ebdb86284">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="5727cf19e1">
        <path d="M 62 54 L 70 54 L 70 62 L 62 62 Z M 62 54 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="05e505915e">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="edf9af1d13">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="5f6882e9d4">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="5c718f0314">
        <path d="M 2 2 L 7 2 L 7 7 L 2 7 Z M 2 2 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="a84fd4d8e6">
        <path
          d="M -61.636719 -24.4375 L -31.003906 -53.789062 L 33.878906 13.925781 L 3.246094 43.277344 Z M -61.636719 -24.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="5c4b6d3e90">
        <path
          d="M -61.636719 -24.4375 L -31.003906 -53.789062 L 33.878906 13.925781 L 3.246094 43.277344 Z M -61.636719 -24.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="3bc1fe0c08">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="0ba862aae6">
        <path d="M 71 45 L 79 45 L 79 53 L 71 53 Z M 71 45 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="90bb20e2ef">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f2ef41b265">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="5251d8781a">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="d8969854eb">
        <path d="M 2 2 L 7 2 L 7 7 L 2 7 Z M 2 2 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="d63fff177b">
        <path
          d="M -70.636719 -15.4375 L -40.003906 -44.789062 L 24.878906 22.925781 L -5.753906 52.277344 Z M -70.636719 -15.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b6140e19cd">
        <path
          d="M -70.636719 -15.4375 L -40.003906 -44.789062 L 24.878906 22.925781 L -5.753906 52.277344 Z M -70.636719 -15.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f097978e73">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="925c3c41b5">
        <path d="M 53 81 L 61 81 L 61 89 L 53 89 Z M 53 81 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="f71d347a83">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="4fccd02fe7">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="b9a1d96769">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="519d61379e">
        <path d="M 1 1 L 6 1 L 6 7 L 1 7 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="22602a54ce">
        <path
          d="M -52.636719 -51.4375 L -22.003906 -80.789062 L 42.878906 -13.074219 L 12.246094 16.277344 Z M -52.636719 -51.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f398590eae">
        <path
          d="M -52.636719 -51.4375 L -22.003906 -80.789062 L 42.878906 -13.074219 L 12.246094 16.277344 Z M -52.636719 -51.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b6516b93b3">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="26b6b746a3">
        <path d="M 62 72 L 70 72 L 70 80 L 62 80 Z M 62 72 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="9a4d16b82b">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="dae5626d40">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="8afbfdb0bc">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="f1917db80e">
        <path d="M 1 2 L 6 2 L 6 7 L 1 7 Z M 1 2 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="49d4cee09e">
        <path
          d="M -61.636719 -42.4375 L -31.003906 -71.789062 L 33.878906 -4.074219 L 3.246094 25.277344 Z M -61.636719 -42.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b5c892c50a">
        <path
          d="M -61.636719 -42.4375 L -31.003906 -71.789062 L 33.878906 -4.074219 L 3.246094 25.277344 Z M -61.636719 -42.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b2bb925a19">
        <rect x={0} width={8} y={0} height={8} />
      </clipPath>
      <clipPath id="fd9a453d25">
        <path d="M 71 64 L 79 64 L 79 71 L 71 71 Z M 71 64 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="353839057e">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f39aa28fae">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="53e544d73f">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="7637951c09">
        <path d="M 1 1 L 7 1 L 7 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="0ac0ab804d">
        <path
          d="M -70.636719 -34.4375 L -40.003906 -63.789062 L 24.878906 3.925781 L -5.753906 33.277344 Z M -70.636719 -34.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="38da2d3540">
        <path
          d="M -70.636719 -34.4375 L -40.003906 -63.789062 L 24.878906 3.925781 L -5.753906 33.277344 Z M -70.636719 -34.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="62ef32d306">
        <rect x={0} width={8} y={0} height={7} />
      </clipPath>
      <clipPath id="a9d2dcf6b8">
        <path d="M 80 55 L 88 55 L 88 62 L 80 62 Z M 80 55 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="6fb8988c81">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="67456efcdb">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="598abed214">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="95ad556984">
        <path d="M 2 1 L 7 1 L 7 6 L 2 6 Z M 2 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="598c24a55d">
        <path
          d="M -79.636719 -25.4375 L -49.003906 -54.789062 L 15.878906 12.925781 L -14.753906 42.277344 Z M -79.636719 -25.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d162906115">
        <path
          d="M -79.636719 -25.4375 L -49.003906 -54.789062 L 15.878906 12.925781 L -14.753906 42.277344 Z M -79.636719 -25.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b49a322988">
        <rect x={0} width={8} y={0} height={7} />
      </clipPath>
      <clipPath id="b205705436">
        <path d="M 62 90 L 69 90 L 69 97.503906 L 62 97.503906 Z M 62 90 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="6b35610e51">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="91dabc2310">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="334388b3c5">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="b8b8749b6e">
        <path d="M 1 2 L 6 2 L 6 7 L 1 7 Z M 1 2 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="3d047baa0b">
        <path
          d="M -61.636719 -60.4375 L -31.003906 -89.789062 L 33.878906 -22.074219 L 3.246094 7.277344 Z M -61.636719 -60.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="da9ba82848">
        <path
          d="M -61.636719 -60.4375 L -31.003906 -89.789062 L 33.878906 -22.074219 L 3.246094 7.277344 Z M -61.636719 -60.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="08000dd6de">
        <rect x={0} width={7} y={0} height={8} />
      </clipPath>
      <clipPath id="fc3b79adb4">
        <path d="M 71 82 L 78 82 L 78 89 L 71 89 Z M 71 82 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="37b925d161">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="90c19b875c">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="013c1cb2f0">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="8ccd4a58ca">
        <path d="M 1 1 L 6 1 L 6 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="dcc2279e9c">
        <path
          d="M -70.636719 -52.4375 L -40.003906 -81.789062 L 24.878906 -14.074219 L -5.753906 15.277344 Z M -70.636719 -52.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0bb186f95f">
        <path
          d="M -70.636719 -52.4375 L -40.003906 -81.789062 L 24.878906 -14.074219 L -5.753906 15.277344 Z M -70.636719 -52.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="3e79fded6c">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <clipPath id="8a7ae8b7a3">
        <path d="M 80 73 L 88 73 L 88 80 L 80 80 Z M 80 73 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="71cde103da">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d54cbcc0aa">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="50f671132e">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="f01164bac1">
        <path d="M 1 1 L 7 1 L 7 6 L 1 6 Z M 1 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="8b3f8b3101">
        <path
          d="M -79.636719 -43.4375 L -49.003906 -72.789062 L 15.878906 -5.074219 L -14.753906 24.277344 Z M -79.636719 -43.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="1f6a99ad6c">
        <path
          d="M -79.636719 -43.4375 L -49.003906 -72.789062 L 15.878906 -5.074219 L -14.753906 24.277344 Z M -79.636719 -43.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="81d0df6969">
        <rect x={0} width={8} y={0} height={7} />
      </clipPath>
      <clipPath id="59147fac4c">
        <path d="M 89 64 L 96 64 L 96 72 L 89 72 Z M 89 64 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="51bb5cc828">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="673c98a060">
        <path
          d="M 0.363281 29.5625 L 30.996094 0.210938 L 95.878906 67.925781 L 65.246094 97.277344 Z M 0.363281 29.5625 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="bf9bd77b40">
        <g filter="url(#8295855490)">
          <rect
            x={-11.088}
            width={133.056}
            fill="#000000"
            y={-9.825}
            height={117.899996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="95ee67cbdc">
        <path d="M 2 1 L 7 1 L 7 6 L 2 6 Z M 2 1 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="44ab62155b">
        <path
          d="M -88.636719 -34.4375 L -58.003906 -63.789062 L 6.878906 3.925781 L -23.753906 33.277344 Z M -88.636719 -34.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="91eca3b78b">
        <path
          d="M -88.636719 -34.4375 L -58.003906 -63.789062 L 6.878906 3.925781 L -23.753906 33.277344 Z M -88.636719 -34.4375 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="49be33ee57">
        <rect x={0} width={7} y={0} height={8} />
      </clipPath>
      <clipPath id="8ce79e363f">
        <path
          d="M 61.28125 20.300781 L 109.992188 20.300781 L 109.992188 69.011719 L 61.28125 69.011719 Z M 61.28125 20.300781 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="726f6f7741">
        <path
          d="M 85.636719 20.300781 C 72.183594 20.300781 61.28125 31.203125 61.28125 44.65625 C 61.28125 58.109375 72.183594 69.011719 85.636719 69.011719 C 99.089844 69.011719 109.992188 58.109375 109.992188 44.65625 C 109.992188 31.203125 99.089844 20.300781 85.636719 20.300781 Z M 85.636719 20.300781 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#ea14b580d7)">
      <g clipPath="url(#7c1e452cbd)">
        <g clipPath="url(#0dd5d148a4)">
          <path
            fill="#fa4a59"
            d="M 4.882812 28.140625 C 5.074219 28.34375 5.222656 28.574219 5.324219 28.832031 C 5.425781 29.09375 5.472656 29.363281 5.464844 29.640625 C 5.460938 29.917969 5.402344 30.183594 5.289062 30.441406 C 5.175781 30.695312 5.019531 30.917969 4.820312 31.113281 C 4.617188 31.304688 4.386719 31.453125 4.128906 31.554688 C 3.867188 31.65625 3.597656 31.703125 3.320312 31.695312 C 3.042969 31.691406 2.773438 31.632812 2.519531 31.519531 C 2.265625 31.40625 2.039062 31.25 1.847656 31.050781 C 1.65625 30.847656 1.507812 30.617188 1.40625 30.359375 C 1.304688 30.097656 1.257812 29.828125 1.265625 29.550781 C 1.269531 29.273438 1.328125 29.003906 1.441406 28.75 C 1.554688 28.496094 1.710938 28.269531 1.910156 28.078125 C 2.113281 27.886719 2.34375 27.738281 2.601562 27.636719 C 2.863281 27.535156 3.132812 27.488281 3.410156 27.496094 C 3.6875 27.5 3.957031 27.558594 4.210938 27.671875 C 4.464844 27.785156 4.691406 27.941406 4.882812 28.140625 Z M 4.882812 28.140625 "
            fillOpacity={1}
            fillRule="nonzero"
          />
          <path
            fill="#fa4a59"
            d="M 4.882812 28.140625 C 5.074219 28.34375 5.222656 28.574219 5.324219 28.832031 C 5.425781 29.09375 5.472656 29.363281 5.464844 29.640625 C 5.460938 29.917969 5.402344 30.183594 5.289062 30.441406 C 5.175781 30.695312 5.019531 30.917969 4.820312 31.113281 C 4.617188 31.304688 4.386719 31.453125 4.128906 31.554688 C 3.867188 31.65625 3.597656 31.703125 3.320312 31.695312 C 3.042969 31.691406 2.773438 31.632812 2.519531 31.519531 C 2.265625 31.40625 2.039062 31.25 1.847656 31.050781 C 1.65625 30.847656 1.507812 30.617188 1.40625 30.359375 C 1.304688 30.097656 1.257812 29.828125 1.265625 29.550781 C 1.269531 29.273438 1.328125 29.003906 1.441406 28.75 C 1.554688 28.496094 1.710938 28.269531 1.910156 28.078125 C 2.113281 27.886719 2.34375 27.738281 2.601562 27.636719 C 2.863281 27.535156 3.132812 27.488281 3.410156 27.496094 C 3.6875 27.5 3.957031 27.558594 4.210938 27.671875 C 4.464844 27.785156 4.691406 27.941406 4.882812 28.140625 Z M 4.882812 28.140625 "
            fillOpacity={1}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#108df2691a)">
      <g clipPath="url(#6ecf407fc3)">
        <g clipPath="url(#03a3d86270)">
          <path
            fill="#fa4a59"
            d="M 14.125 19.285156 C 14.316406 19.488281 14.464844 19.71875 14.566406 19.976562 C 14.667969 20.238281 14.714844 20.507812 14.707031 20.785156 C 14.703125 21.0625 14.644531 21.332031 14.53125 21.585938 C 14.417969 21.839844 14.261719 22.0625 14.0625 22.257812 C 13.859375 22.449219 13.628906 22.597656 13.371094 22.699219 C 13.109375 22.800781 12.839844 22.847656 12.5625 22.839844 C 12.285156 22.835938 12.015625 22.777344 11.761719 22.664062 C 11.507812 22.550781 11.28125 22.394531 11.089844 22.195312 C 10.898438 21.992188 10.75 21.761719 10.648438 21.503906 C 10.546875 21.242188 10.5 20.972656 10.507812 20.695312 C 10.511719 20.417969 10.570312 20.148438 10.683594 19.894531 C 10.796875 19.640625 10.953125 19.414062 11.152344 19.222656 C 11.355469 19.03125 11.585938 18.882812 11.84375 18.78125 C 12.105469 18.679688 12.371094 18.632812 12.652344 18.640625 C 12.929688 18.644531 13.195312 18.703125 13.453125 18.816406 C 13.707031 18.929688 13.929688 19.085938 14.125 19.285156 Z M 14.125 19.285156 "
            fillOpacity={1}
            fillRule="nonzero"
          />
          <path
            fill="#fa4a59"
            d="M 14.125 19.285156 C 14.316406 19.488281 14.464844 19.71875 14.566406 19.976562 C 14.667969 20.238281 14.714844 20.507812 14.707031 20.785156 C 14.703125 21.0625 14.644531 21.332031 14.53125 21.585938 C 14.417969 21.839844 14.261719 22.0625 14.0625 22.257812 C 13.859375 22.449219 13.628906 22.597656 13.371094 22.699219 C 13.109375 22.800781 12.839844 22.847656 12.5625 22.839844 C 12.285156 22.835938 12.015625 22.777344 11.761719 22.664062 C 11.507812 22.550781 11.28125 22.394531 11.089844 22.195312 C 10.898438 21.992188 10.75 21.761719 10.648438 21.503906 C 10.546875 21.242188 10.5 20.972656 10.507812 20.695312 C 10.511719 20.417969 10.570312 20.148438 10.683594 19.894531 C 10.796875 19.640625 10.953125 19.414062 11.152344 19.222656 C 11.355469 19.03125 11.585938 18.882812 11.84375 18.78125 C 12.105469 18.679688 12.371094 18.632812 12.652344 18.640625 C 12.929688 18.644531 13.195312 18.703125 13.453125 18.816406 C 13.707031 18.929688 13.929688 19.085938 14.125 19.285156 Z M 14.125 19.285156 "
            fillOpacity={1}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#28b335d89c)">
      <g clipPath="url(#07e1c8dcea)">
        <g clipPath="url(#bebc473b71)">
          <path
            fill="#fa4a59"
            d="M 23.394531 10.402344 C 23.585938 10.605469 23.734375 10.835938 23.832031 11.09375 C 23.933594 11.355469 23.984375 11.625 23.976562 11.902344 C 23.972656 12.179688 23.910156 12.449219 23.800781 12.703125 C 23.6875 12.957031 23.53125 13.183594 23.328125 13.375 C 23.128906 13.566406 22.898438 13.714844 22.636719 13.816406 C 22.378906 13.917969 22.109375 13.964844 21.832031 13.960938 C 21.550781 13.953125 21.285156 13.894531 21.03125 13.78125 C 20.773438 13.667969 20.550781 13.511719 20.359375 13.3125 C 20.164062 13.109375 20.019531 12.878906 19.917969 12.621094 C 19.816406 12.359375 19.769531 12.09375 19.773438 11.8125 C 19.78125 11.535156 19.839844 11.269531 19.953125 11.011719 C 20.0625 10.757812 20.222656 10.535156 20.421875 10.339844 C 20.625 10.148438 20.855469 10 21.113281 9.898438 C 21.371094 9.800781 21.640625 9.75 21.921875 9.757812 C 22.199219 9.761719 22.464844 9.820312 22.722656 9.933594 C 22.976562 10.046875 23.199219 10.203125 23.394531 10.402344 Z M 23.394531 10.402344 "
            fillOpacity={1}
            fillRule="nonzero"
          />
          <path
            fill="#fa4a59"
            d="M 23.394531 10.402344 C 23.585938 10.605469 23.734375 10.835938 23.832031 11.09375 C 23.933594 11.355469 23.984375 11.625 23.976562 11.902344 C 23.972656 12.179688 23.910156 12.449219 23.800781 12.703125 C 23.6875 12.957031 23.53125 13.183594 23.328125 13.375 C 23.128906 13.566406 22.898438 13.714844 22.636719 13.816406 C 22.378906 13.917969 22.109375 13.964844 21.832031 13.960938 C 21.550781 13.953125 21.285156 13.894531 21.03125 13.78125 C 20.773438 13.667969 20.550781 13.511719 20.359375 13.3125 C 20.164062 13.109375 20.019531 12.878906 19.917969 12.621094 C 19.816406 12.359375 19.769531 12.09375 19.773438 11.8125 C 19.78125 11.535156 19.839844 11.269531 19.953125 11.011719 C 20.0625 10.757812 20.222656 10.535156 20.421875 10.339844 C 20.625 10.148438 20.855469 10 21.113281 9.898438 C 21.371094 9.800781 21.640625 9.75 21.921875 9.757812 C 22.199219 9.761719 22.464844 9.820312 22.722656 9.933594 C 22.976562 10.046875 23.199219 10.203125 23.394531 10.402344 Z M 23.394531 10.402344 "
            fillOpacity={1}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#d77c01b1dd)">
      <g clipPath="url(#b5fbeec518)">
        <g clipPath="url(#f343142350)">
          <path
            fill="#fa4a59"
            d="M 32.632812 1.550781 C 32.828125 1.75 32.972656 1.980469 33.074219 2.242188 C 33.175781 2.5 33.222656 2.769531 33.21875 3.046875 C 33.210938 3.328125 33.152344 3.59375 33.042969 3.847656 C 32.929688 4.101562 32.773438 4.328125 32.570312 4.519531 C 32.371094 4.710938 32.140625 4.859375 31.878906 4.960938 C 31.621094 5.0625 31.351562 5.109375 31.074219 5.105469 C 30.792969 5.097656 30.527344 5.039062 30.273438 4.925781 C 30.015625 4.816406 29.792969 4.65625 29.601562 4.457031 C 29.40625 4.253906 29.261719 4.023438 29.160156 3.765625 C 29.058594 3.503906 29.011719 3.238281 29.015625 2.957031 C 29.023438 2.679688 29.082031 2.414062 29.195312 2.15625 C 29.304688 1.902344 29.460938 1.679688 29.664062 1.484375 C 29.863281 1.292969 30.09375 1.144531 30.355469 1.042969 C 30.613281 0.945312 30.882812 0.894531 31.160156 0.902344 C 31.441406 0.90625 31.707031 0.96875 31.960938 1.078125 C 32.21875 1.191406 32.441406 1.347656 32.632812 1.550781 Z M 32.632812 1.550781 "
            fillOpacity={1}
            fillRule="nonzero"
          />
          <path
            fill="#fa4a59"
            d="M 32.632812 1.550781 C 32.828125 1.75 32.972656 1.980469 33.074219 2.242188 C 33.175781 2.5 33.222656 2.769531 33.21875 3.046875 C 33.210938 3.328125 33.152344 3.59375 33.042969 3.847656 C 32.929688 4.101562 32.773438 4.328125 32.570312 4.519531 C 32.371094 4.710938 32.140625 4.859375 31.878906 4.960938 C 31.621094 5.0625 31.351562 5.109375 31.074219 5.105469 C 30.792969 5.097656 30.527344 5.039062 30.273438 4.925781 C 30.015625 4.816406 29.792969 4.65625 29.601562 4.457031 C 29.40625 4.253906 29.261719 4.023438 29.160156 3.765625 C 29.058594 3.503906 29.011719 3.238281 29.015625 2.957031 C 29.023438 2.679688 29.082031 2.414062 29.195312 2.15625 C 29.304688 1.902344 29.460938 1.679688 29.664062 1.484375 C 29.863281 1.292969 30.09375 1.144531 30.355469 1.042969 C 30.613281 0.945312 30.882812 0.894531 31.160156 0.902344 C 31.441406 0.90625 31.707031 0.96875 31.960938 1.078125 C 32.21875 1.191406 32.441406 1.347656 32.632812 1.550781 Z M 32.632812 1.550781 "
            fillOpacity={1}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#7a2916f74b)">
      <g clipPath="url(#58260e210a)">
        <g clipPath="url(#7445166f4d)">
          <path
            fill="#fa4a59"
            d="M 13.765625 37.410156 C 13.957031 37.613281 14.105469 37.84375 14.207031 38.101562 C 14.304688 38.359375 14.355469 38.628906 14.347656 38.910156 C 14.34375 39.1875 14.28125 39.453125 14.171875 39.710938 C 14.058594 39.964844 13.902344 40.1875 13.699219 40.382812 C 13.5 40.574219 13.269531 40.722656 13.007812 40.824219 C 12.75 40.921875 12.480469 40.972656 12.203125 40.964844 C 11.921875 40.960938 11.65625 40.898438 11.402344 40.789062 C 11.148438 40.675781 10.921875 40.519531 10.730469 40.316406 C 10.535156 40.117188 10.390625 39.886719 10.289062 39.625 C 10.1875 39.367188 10.140625 39.097656 10.144531 38.820312 C 10.152344 38.539062 10.210938 38.273438 10.324219 38.019531 C 10.433594 37.761719 10.59375 37.539062 10.792969 37.347656 C 10.996094 37.152344 11.226562 37.007812 11.484375 36.90625 C 11.746094 36.804688 12.011719 36.757812 12.292969 36.761719 C 12.570312 36.769531 12.835938 36.828125 13.09375 36.941406 C 13.347656 37.050781 13.570312 37.210938 13.765625 37.410156 Z M 13.765625 37.410156 "
            fillOpacity={1}
            fillRule="nonzero"
          />
          <path
            fill="#fa4a59"
            d="M 13.765625 37.410156 C 13.957031 37.613281 14.105469 37.84375 14.207031 38.101562 C 14.304688 38.359375 14.355469 38.628906 14.347656 38.910156 C 14.34375 39.1875 14.28125 39.453125 14.171875 39.710938 C 14.058594 39.964844 13.902344 40.1875 13.699219 40.382812 C 13.5 40.574219 13.269531 40.722656 13.007812 40.824219 C 12.75 40.921875 12.480469 40.972656 12.203125 40.964844 C 11.921875 40.960938 11.65625 40.898438 11.402344 40.789062 C 11.148438 40.675781 10.921875 40.519531 10.730469 40.316406 C 10.535156 40.117188 10.390625 39.886719 10.289062 39.625 C 10.1875 39.367188 10.140625 39.097656 10.144531 38.820312 C 10.152344 38.539062 10.210938 38.273438 10.324219 38.019531 C 10.433594 37.761719 10.59375 37.539062 10.792969 37.347656 C 10.996094 37.152344 11.226562 37.007812 11.484375 36.90625 C 11.746094 36.804688 12.011719 36.757812 12.292969 36.761719 C 12.570312 36.769531 12.835938 36.828125 13.09375 36.941406 C 13.347656 37.050781 13.570312 37.210938 13.765625 37.410156 Z M 13.765625 37.410156 "
            fillOpacity={1}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#3b871251ed)">
      <g clipPath="url(#47ae09d3b4)">
        <g clipPath="url(#0476f05128)">
          <path
            fill="#fa4a59"
            d="M 23.003906 28.554688 C 23.199219 28.757812 23.34375 28.988281 23.445312 29.246094 C 23.546875 29.507812 23.59375 29.773438 23.589844 30.054688 C 23.582031 30.332031 23.523438 30.597656 23.414062 30.855469 C 23.300781 31.109375 23.144531 31.332031 22.941406 31.527344 C 22.742188 31.71875 22.511719 31.867188 22.25 31.96875 C 21.992188 32.066406 21.722656 32.117188 21.445312 32.109375 C 21.164062 32.105469 20.898438 32.042969 20.644531 31.933594 C 20.386719 31.820312 20.164062 31.664062 19.972656 31.460938 C 19.777344 31.261719 19.632812 31.03125 19.53125 30.769531 C 19.429688 30.511719 19.382812 30.242188 19.386719 29.964844 C 19.394531 29.683594 19.453125 29.417969 19.566406 29.164062 C 19.675781 28.910156 19.832031 28.683594 20.035156 28.492188 C 20.234375 28.296875 20.464844 28.152344 20.726562 28.050781 C 20.984375 27.949219 21.253906 27.902344 21.535156 27.90625 C 21.8125 27.914062 22.078125 27.972656 22.332031 28.085938 C 22.589844 28.195312 22.8125 28.355469 23.003906 28.554688 Z M 23.003906 28.554688 "
            fillOpacity={1}
            fillRule="nonzero"
          />
          <path
            fill="#fa4a59"
            d="M 23.003906 28.554688 C 23.199219 28.757812 23.34375 28.988281 23.445312 29.246094 C 23.546875 29.507812 23.59375 29.773438 23.589844 30.054688 C 23.582031 30.332031 23.523438 30.597656 23.414062 30.855469 C 23.300781 31.109375 23.144531 31.332031 22.941406 31.527344 C 22.742188 31.71875 22.511719 31.867188 22.25 31.96875 C 21.992188 32.066406 21.722656 32.117188 21.445312 32.109375 C 21.164062 32.105469 20.898438 32.042969 20.644531 31.933594 C 20.386719 31.820312 20.164062 31.664062 19.972656 31.460938 C 19.777344 31.261719 19.632812 31.03125 19.53125 30.769531 C 19.429688 30.511719 19.382812 30.242188 19.386719 29.964844 C 19.394531 29.683594 19.453125 29.417969 19.566406 29.164062 C 19.675781 28.910156 19.832031 28.683594 20.035156 28.492188 C 20.234375 28.296875 20.464844 28.152344 20.726562 28.050781 C 20.984375 27.949219 21.253906 27.902344 21.535156 27.90625 C 21.8125 27.914062 22.078125 27.972656 22.332031 28.085938 C 22.589844 28.195312 22.8125 28.355469 23.003906 28.554688 Z M 23.003906 28.554688 "
            fillOpacity={1}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#8f766f11ee)">
      <g clipPath="url(#4450d50962)">
        <g clipPath="url(#86545745d3)">
          <path
            fill="#fa4a59"
            d="M 32.273438 19.671875 C 32.46875 19.875 32.613281 20.105469 32.714844 20.363281 C 32.816406 20.625 32.863281 20.894531 32.859375 21.171875 C 32.851562 21.449219 32.792969 21.71875 32.679688 21.972656 C 32.570312 22.226562 32.414062 22.453125 32.210938 22.644531 C 32.011719 22.835938 31.78125 22.984375 31.519531 23.085938 C 31.261719 23.1875 30.992188 23.234375 30.710938 23.226562 C 30.433594 23.222656 30.167969 23.164062 29.914062 23.050781 C 29.65625 22.9375 29.433594 22.78125 29.242188 22.582031 C 29.046875 22.378906 28.902344 22.148438 28.800781 21.890625 C 28.699219 21.628906 28.652344 21.359375 28.65625 21.082031 C 28.664062 20.804688 28.722656 20.535156 28.832031 20.28125 C 28.945312 20.027344 29.101562 19.800781 29.304688 19.609375 C 29.503906 19.417969 29.734375 19.269531 29.996094 19.167969 C 30.253906 19.066406 30.523438 19.019531 30.800781 19.027344 C 31.082031 19.03125 31.347656 19.089844 31.601562 19.203125 C 31.859375 19.316406 32.082031 19.472656 32.273438 19.671875 Z M 32.273438 19.671875 "
            fillOpacity={1}
            fillRule="nonzero"
          />
          <path
            fill="#fa4a59"
            d="M 32.273438 19.671875 C 32.46875 19.875 32.613281 20.105469 32.714844 20.363281 C 32.816406 20.625 32.863281 20.894531 32.859375 21.171875 C 32.851562 21.449219 32.792969 21.71875 32.679688 21.972656 C 32.570312 22.226562 32.414062 22.453125 32.210938 22.644531 C 32.011719 22.835938 31.78125 22.984375 31.519531 23.085938 C 31.261719 23.1875 30.992188 23.234375 30.710938 23.226562 C 30.433594 23.222656 30.167969 23.164062 29.914062 23.050781 C 29.65625 22.9375 29.433594 22.78125 29.242188 22.582031 C 29.046875 22.378906 28.902344 22.148438 28.800781 21.890625 C 28.699219 21.628906 28.652344 21.359375 28.65625 21.082031 C 28.664062 20.804688 28.722656 20.535156 28.832031 20.28125 C 28.945312 20.027344 29.101562 19.800781 29.304688 19.609375 C 29.503906 19.417969 29.734375 19.269531 29.996094 19.167969 C 30.253906 19.066406 30.523438 19.019531 30.800781 19.027344 C 31.082031 19.03125 31.347656 19.089844 31.601562 19.203125 C 31.859375 19.316406 32.082031 19.472656 32.273438 19.671875 Z M 32.273438 19.671875 "
            fillOpacity={1}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#91e7fc4ce3)">
      <g clipPath="url(#66395fae89)">
        <g clipPath="url(#b42b78cc8b)">
          <path
            fill="#fa4a59"
            d="M 41.515625 10.816406 C 41.710938 11.019531 41.855469 11.25 41.957031 11.507812 C 42.058594 11.769531 42.105469 12.039062 42.101562 12.316406 C 42.09375 12.59375 42.035156 12.863281 41.921875 13.117188 C 41.8125 13.371094 41.652344 13.597656 41.453125 13.789062 C 41.25 13.980469 41.019531 14.128906 40.761719 14.230469 C 40.5 14.332031 40.234375 14.378906 39.953125 14.371094 C 39.675781 14.367188 39.410156 14.308594 39.152344 14.195312 C 38.898438 14.082031 38.675781 13.925781 38.480469 13.726562 C 38.289062 13.523438 38.140625 13.292969 38.039062 13.035156 C 37.941406 12.773438 37.890625 12.503906 37.898438 12.226562 C 37.902344 11.949219 37.964844 11.679688 38.074219 11.425781 C 38.1875 11.171875 38.34375 10.949219 38.546875 10.753906 C 38.746094 10.5625 38.976562 10.414062 39.238281 10.3125 C 39.496094 10.210938 39.765625 10.164062 40.042969 10.171875 C 40.324219 10.175781 40.589844 10.234375 40.84375 10.347656 C 41.097656 10.460938 41.324219 10.617188 41.515625 10.816406 Z M 41.515625 10.816406 "
            fillOpacity={1}
            fillRule="nonzero"
          />
          <path
            fill="#fa4a59"
            d="M 41.515625 10.816406 C 41.710938 11.019531 41.855469 11.25 41.957031 11.507812 C 42.058594 11.769531 42.105469 12.039062 42.101562 12.316406 C 42.09375 12.59375 42.035156 12.863281 41.921875 13.117188 C 41.8125 13.371094 41.652344 13.597656 41.453125 13.789062 C 41.25 13.980469 41.019531 14.128906 40.761719 14.230469 C 40.5 14.332031 40.234375 14.378906 39.953125 14.371094 C 39.675781 14.367188 39.410156 14.308594 39.152344 14.195312 C 38.898438 14.082031 38.675781 13.925781 38.480469 13.726562 C 38.289062 13.523438 38.140625 13.292969 38.039062 13.035156 C 37.941406 12.773438 37.890625 12.503906 37.898438 12.226562 C 37.902344 11.949219 37.964844 11.679688 38.074219 11.425781 C 38.1875 11.171875 38.34375 10.949219 38.546875 10.753906 C 38.746094 10.5625 38.976562 10.414062 39.238281 10.3125 C 39.496094 10.210938 39.765625 10.164062 40.042969 10.171875 C 40.324219 10.175781 40.589844 10.234375 40.84375 10.347656 C 41.097656 10.460938 41.324219 10.617188 41.515625 10.816406 Z M 41.515625 10.816406 "
            fillOpacity={1}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
    <g clipPath="url(#82fcd03283)">
      <g clipPath="url(#be7bfe25ee)">
        <g clipPath="url(#910d50c362)">
          <g mask="url(#7a689264e6)">
            <g transform="matrix(1, 0, 0, 1, 17, 44)">
              <g clipPath="url(#79baef2ac2)">
                <g clipPath="url(#9b7be89fba)">
                  <g clipPath="url(#176d128a08)">
                    <g clipPath="url(#5debe3f278)">
                      <path
                        fill="#fa4a59"
                        d="M 5.621094 2.652344 C 5.8125 2.851562 5.960938 3.082031 6.058594 3.34375 C 6.160156 3.601562 6.210938 3.871094 6.203125 4.152344 C 6.199219 4.429688 6.136719 4.695312 6.027344 4.949219 C 5.914062 5.207031 5.757812 5.429688 5.554688 5.621094 C 5.355469 5.816406 5.125 5.960938 4.863281 6.0625 C 4.605469 6.164062 4.335938 6.210938 4.058594 6.207031 C 3.777344 6.199219 3.511719 6.140625 3.257812 6.03125 C 3 5.917969 2.777344 5.761719 2.585938 5.558594 C 2.390625 5.359375 2.246094 5.128906 2.144531 4.867188 C 2.042969 4.609375 1.996094 4.339844 2 4.0625 C 2.007812 3.78125 2.066406 3.515625 2.179688 3.261719 C 2.289062 3.003906 2.449219 2.78125 2.648438 2.589844 C 2.851562 2.394531 3.082031 2.25 3.339844 2.148438 C 3.597656 2.046875 3.867188 2 4.148438 2.003906 C 4.425781 2.011719 4.691406 2.070312 4.949219 2.183594 C 5.203125 2.292969 5.425781 2.449219 5.621094 2.652344 Z M 5.621094 2.652344 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.621094 2.652344 C 5.8125 2.851562 5.960938 3.082031 6.058594 3.34375 C 6.160156 3.601562 6.210938 3.871094 6.203125 4.152344 C 6.199219 4.429688 6.136719 4.695312 6.027344 4.949219 C 5.914062 5.207031 5.757812 5.429688 5.554688 5.621094 C 5.355469 5.816406 5.125 5.960938 4.863281 6.0625 C 4.605469 6.164062 4.335938 6.210938 4.058594 6.207031 C 3.777344 6.199219 3.511719 6.140625 3.257812 6.03125 C 3 5.917969 2.777344 5.761719 2.585938 5.558594 C 2.390625 5.359375 2.246094 5.128906 2.144531 4.867188 C 2.042969 4.609375 1.996094 4.339844 2 4.0625 C 2.007812 3.78125 2.066406 3.515625 2.179688 3.261719 C 2.289062 3.003906 2.449219 2.78125 2.648438 2.589844 C 2.851562 2.394531 3.082031 2.25 3.339844 2.148438 C 3.597656 2.046875 3.867188 2 4.148438 2.003906 C 4.425781 2.011719 4.691406 2.070312 4.949219 2.183594 C 5.203125 2.292969 5.425781 2.449219 5.621094 2.652344 Z M 5.621094 2.652344 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#5cd6d68c35)">
      <g clipPath="url(#b549b06b81)">
        <g clipPath="url(#f0e86e2d93)">
          <g mask="url(#d0d8dea3d9)">
            <g transform="matrix(1, 0, 0, 1, 27, 35)">
              <g clipPath="url(#23c979f8d7)">
                <g clipPath="url(#5865731cc0)">
                  <g clipPath="url(#303d537329)">
                    <g clipPath="url(#7dab741062)">
                      <path
                        fill="#fa4a59"
                        d="M 4.859375 2.796875 C 5.054688 2.996094 5.199219 3.226562 5.300781 3.488281 C 5.402344 3.746094 5.449219 4.015625 5.445312 4.296875 C 5.4375 4.574219 5.378906 4.839844 5.269531 5.09375 C 5.15625 5.351562 5 5.574219 4.796875 5.765625 C 4.597656 5.960938 4.367188 6.105469 4.105469 6.207031 C 3.847656 6.308594 3.578125 6.355469 3.300781 6.351562 C 3.019531 6.34375 2.753906 6.285156 2.5 6.175781 C 2.242188 6.0625 2.019531 5.90625 1.828125 5.703125 C 1.632812 5.503906 1.488281 5.273438 1.386719 5.011719 C 1.285156 4.753906 1.238281 4.484375 1.242188 4.207031 C 1.25 3.925781 1.308594 3.660156 1.421875 3.40625 C 1.53125 3.148438 1.6875 2.925781 1.890625 2.734375 C 2.089844 2.539062 2.320312 2.394531 2.582031 2.292969 C 2.839844 2.191406 3.109375 2.144531 3.390625 2.148438 C 3.667969 2.15625 3.933594 2.214844 4.1875 2.328125 C 4.445312 2.4375 4.667969 2.59375 4.859375 2.796875 Z M 4.859375 2.796875 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 4.859375 2.796875 C 5.054688 2.996094 5.199219 3.226562 5.300781 3.488281 C 5.402344 3.746094 5.449219 4.015625 5.445312 4.296875 C 5.4375 4.574219 5.378906 4.839844 5.269531 5.09375 C 5.15625 5.351562 5 5.574219 4.796875 5.765625 C 4.597656 5.960938 4.367188 6.105469 4.105469 6.207031 C 3.847656 6.308594 3.578125 6.355469 3.300781 6.351562 C 3.019531 6.34375 2.753906 6.285156 2.5 6.175781 C 2.242188 6.0625 2.019531 5.90625 1.828125 5.703125 C 1.632812 5.503906 1.488281 5.273438 1.386719 5.011719 C 1.285156 4.753906 1.238281 4.484375 1.242188 4.207031 C 1.25 3.925781 1.308594 3.660156 1.421875 3.40625 C 1.53125 3.148438 1.6875 2.925781 1.890625 2.734375 C 2.089844 2.539062 2.320312 2.394531 2.582031 2.292969 C 2.839844 2.191406 3.109375 2.144531 3.390625 2.148438 C 3.667969 2.15625 3.933594 2.214844 4.1875 2.328125 C 4.445312 2.4375 4.667969 2.59375 4.859375 2.796875 Z M 4.859375 2.796875 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#1a463dc361)">
      <g clipPath="url(#bdae9775f6)">
        <g clipPath="url(#2ecffc5e30)">
          <g mask="url(#d6e900155d)">
            <g transform="matrix(1, 0, 0, 1, 36, 27)">
              <g clipPath="url(#ecdb95b113)">
                <g clipPath="url(#1856146bb5)">
                  <g clipPath="url(#1dda9bf4df)">
                    <g clipPath="url(#35bf396698)">
                      <path
                        fill="#fa4a59"
                        d="M 5.128906 1.914062 C 5.324219 2.117188 5.46875 2.347656 5.570312 2.605469 C 5.671875 2.867188 5.71875 3.136719 5.714844 3.414062 C 5.707031 3.691406 5.648438 3.957031 5.535156 4.214844 C 5.425781 4.46875 5.269531 4.691406 5.066406 4.886719 C 4.867188 5.078125 4.636719 5.226562 4.375 5.328125 C 4.117188 5.429688 3.847656 5.476562 3.566406 5.46875 C 3.289062 5.464844 3.023438 5.40625 2.765625 5.292969 C 2.511719 5.179688 2.289062 5.023438 2.09375 4.824219 C 1.902344 4.621094 1.757812 4.390625 1.65625 4.132812 C 1.554688 3.871094 1.507812 3.601562 1.511719 3.324219 C 1.519531 3.046875 1.578125 2.777344 1.6875 2.523438 C 1.800781 2.269531 1.957031 2.042969 2.160156 1.851562 C 2.359375 1.660156 2.589844 1.511719 2.851562 1.410156 C 3.109375 1.308594 3.378906 1.261719 3.65625 1.269531 C 3.9375 1.273438 4.203125 1.332031 4.457031 1.445312 C 4.714844 1.558594 4.9375 1.714844 5.128906 1.914062 Z M 5.128906 1.914062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.128906 1.914062 C 5.324219 2.117188 5.46875 2.347656 5.570312 2.605469 C 5.671875 2.867188 5.71875 3.136719 5.714844 3.414062 C 5.707031 3.691406 5.648438 3.957031 5.535156 4.214844 C 5.425781 4.46875 5.269531 4.691406 5.066406 4.886719 C 4.867188 5.078125 4.636719 5.226562 4.375 5.328125 C 4.117188 5.429688 3.847656 5.476562 3.566406 5.46875 C 3.289062 5.464844 3.023438 5.40625 2.765625 5.292969 C 2.511719 5.179688 2.289062 5.023438 2.09375 4.824219 C 1.902344 4.621094 1.757812 4.390625 1.65625 4.132812 C 1.554688 3.871094 1.507812 3.601562 1.511719 3.324219 C 1.519531 3.046875 1.578125 2.777344 1.6875 2.523438 C 1.800781 2.269531 1.957031 2.042969 2.160156 1.851562 C 2.359375 1.660156 2.589844 1.511719 2.851562 1.410156 C 3.109375 1.308594 3.378906 1.261719 3.65625 1.269531 C 3.9375 1.273438 4.203125 1.332031 4.457031 1.445312 C 4.714844 1.558594 4.9375 1.714844 5.128906 1.914062 Z M 5.128906 1.914062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#7329470d3c)">
      <g clipPath="url(#50b7f8c8f4)">
        <g clipPath="url(#94e6a3cabd)">
          <g mask="url(#44fdc0486c)">
            <g transform="matrix(1, 0, 0, 1, 45, 18)">
              <g clipPath="url(#a255f11a71)">
                <g clipPath="url(#efc29b9aa9)">
                  <g clipPath="url(#dda906ffd5)">
                    <g clipPath="url(#32665ee7ff)">
                      <path
                        fill="#fa4a59"
                        d="M 5.371094 2.058594 C 5.5625 2.261719 5.710938 2.492188 5.8125 2.75 C 5.914062 3.011719 5.960938 3.28125 5.957031 3.558594 C 5.949219 3.835938 5.890625 4.105469 5.777344 4.359375 C 5.664062 4.613281 5.507812 4.835938 5.308594 5.03125 C 5.105469 5.222656 4.875 5.371094 4.617188 5.472656 C 4.355469 5.574219 4.089844 5.621094 3.808594 5.613281 C 3.53125 5.609375 3.265625 5.550781 3.007812 5.4375 C 2.753906 5.324219 2.53125 5.167969 2.335938 4.96875 C 2.144531 4.765625 1.996094 4.535156 1.894531 4.277344 C 1.796875 4.015625 1.746094 3.746094 1.753906 3.46875 C 1.757812 3.191406 1.820312 2.921875 1.929688 2.667969 C 2.042969 2.414062 2.199219 2.1875 2.402344 1.996094 C 2.601562 1.804688 2.832031 1.65625 3.089844 1.554688 C 3.351562 1.453125 3.621094 1.40625 3.898438 1.414062 C 4.175781 1.417969 4.445312 1.476562 4.699219 1.589844 C 4.953125 1.703125 5.179688 1.859375 5.371094 2.058594 Z M 5.371094 2.058594 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.371094 2.058594 C 5.5625 2.261719 5.710938 2.492188 5.8125 2.75 C 5.914062 3.011719 5.960938 3.28125 5.957031 3.558594 C 5.949219 3.835938 5.890625 4.105469 5.777344 4.359375 C 5.664062 4.613281 5.507812 4.835938 5.308594 5.03125 C 5.105469 5.222656 4.875 5.371094 4.617188 5.472656 C 4.355469 5.574219 4.089844 5.621094 3.808594 5.613281 C 3.53125 5.609375 3.265625 5.550781 3.007812 5.4375 C 2.753906 5.324219 2.53125 5.167969 2.335938 4.96875 C 2.144531 4.765625 1.996094 4.535156 1.894531 4.277344 C 1.796875 4.015625 1.746094 3.746094 1.753906 3.46875 C 1.757812 3.191406 1.820312 2.921875 1.929688 2.667969 C 2.042969 2.414062 2.199219 2.1875 2.402344 1.996094 C 2.601562 1.804688 2.832031 1.65625 3.089844 1.554688 C 3.351562 1.453125 3.621094 1.40625 3.898438 1.414062 C 4.175781 1.417969 4.445312 1.476562 4.699219 1.589844 C 4.953125 1.703125 5.179688 1.859375 5.371094 2.058594 Z M 5.371094 2.058594 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#f5ac6abe12)">
      <g clipPath="url(#a1e52b5209)">
        <g clipPath="url(#29314168f6)">
          <g mask="url(#935c51ac16)">
            <g transform="matrix(1, 0, 0, 1, 26, 53)">
              <g clipPath="url(#5abf5e4ba9)">
                <g clipPath="url(#e3ad8f0e0d)">
                  <g clipPath="url(#fe37fb5dc8)">
                    <g clipPath="url(#7b46a3a860)">
                      <path
                        fill="#fa4a59"
                        d="M 5.476562 2.894531 C 5.667969 3.09375 5.816406 3.324219 5.914062 3.585938 C 6.015625 3.84375 6.066406 4.113281 6.058594 4.390625 C 6.050781 4.671875 5.992188 4.9375 5.882812 5.191406 C 5.769531 5.449219 5.613281 5.671875 5.410156 5.863281 C 5.210938 6.058594 4.980469 6.203125 4.71875 6.304688 C 4.460938 6.40625 4.191406 6.453125 3.914062 6.449219 C 3.632812 6.441406 3.367188 6.382812 3.113281 6.269531 C 2.855469 6.160156 2.632812 6.003906 2.441406 5.800781 C 2.246094 5.601562 2.101562 5.371094 2 5.109375 C 1.898438 4.851562 1.851562 4.582031 1.855469 4.300781 C 1.863281 4.023438 1.921875 3.757812 2.035156 3.5 C 2.144531 3.246094 2.300781 3.023438 2.503906 2.828125 C 2.703125 2.636719 2.933594 2.488281 3.195312 2.390625 C 3.453125 2.289062 3.722656 2.238281 4.003906 2.246094 C 4.28125 2.253906 4.546875 2.3125 4.804688 2.421875 C 5.058594 2.535156 5.28125 2.691406 5.476562 2.894531 Z M 5.476562 2.894531 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.476562 2.894531 C 5.667969 3.09375 5.816406 3.324219 5.914062 3.585938 C 6.015625 3.84375 6.066406 4.113281 6.058594 4.390625 C 6.050781 4.671875 5.992188 4.9375 5.882812 5.191406 C 5.769531 5.449219 5.613281 5.671875 5.410156 5.863281 C 5.210938 6.058594 4.980469 6.203125 4.71875 6.304688 C 4.460938 6.40625 4.191406 6.453125 3.914062 6.449219 C 3.632812 6.441406 3.367188 6.382812 3.113281 6.269531 C 2.855469 6.160156 2.632812 6.003906 2.441406 5.800781 C 2.246094 5.601562 2.101562 5.371094 2 5.109375 C 1.898438 4.851562 1.851562 4.582031 1.855469 4.300781 C 1.863281 4.023438 1.921875 3.757812 2.035156 3.5 C 2.144531 3.246094 2.300781 3.023438 2.503906 2.828125 C 2.703125 2.636719 2.933594 2.488281 3.195312 2.390625 C 3.453125 2.289062 3.722656 2.238281 4.003906 2.246094 C 4.28125 2.253906 4.546875 2.3125 4.804688 2.421875 C 5.058594 2.535156 5.28125 2.691406 5.476562 2.894531 Z M 5.476562 2.894531 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#c93bcb2b01)">
      <g clipPath="url(#710f8ff5d9)">
        <g clipPath="url(#4690fb61c9)">
          <g mask="url(#707b9af035)">
            <g transform="matrix(1, 0, 0, 1, 35, 45)">
              <g clipPath="url(#d14812ad8b)">
                <g clipPath="url(#9bd41d335c)">
                  <g clipPath="url(#d0ff3bb77c)">
                    <g clipPath="url(#9f9f01f344)">
                      <path
                        fill="#fa4a59"
                        d="M 5.714844 2.039062 C 5.910156 2.238281 6.054688 2.46875 6.15625 2.730469 C 6.257812 2.988281 6.304688 3.257812 6.300781 3.535156 C 6.292969 3.816406 6.234375 4.082031 6.121094 4.335938 C 6.011719 4.59375 5.855469 4.816406 5.652344 5.007812 C 5.453125 5.203125 5.222656 5.347656 4.960938 5.449219 C 4.703125 5.550781 4.433594 5.597656 4.15625 5.59375 C 3.875 5.585938 3.609375 5.527344 3.355469 5.414062 C 3.097656 5.304688 2.875 5.148438 2.683594 4.945312 C 2.488281 4.746094 2.34375 4.515625 2.242188 4.253906 C 2.140625 3.996094 2.09375 3.726562 2.097656 3.445312 C 2.105469 3.167969 2.164062 2.902344 2.273438 2.644531 C 2.386719 2.390625 2.542969 2.167969 2.746094 1.972656 C 2.945312 1.78125 3.175781 1.632812 3.4375 1.535156 C 3.695312 1.433594 3.964844 1.386719 4.242188 1.390625 C 4.523438 1.398438 4.789062 1.457031 5.042969 1.566406 C 5.300781 1.679688 5.523438 1.835938 5.714844 2.039062 Z M 5.714844 2.039062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.714844 2.039062 C 5.910156 2.238281 6.054688 2.46875 6.15625 2.730469 C 6.257812 2.988281 6.304688 3.257812 6.300781 3.535156 C 6.292969 3.816406 6.234375 4.082031 6.121094 4.335938 C 6.011719 4.59375 5.855469 4.816406 5.652344 5.007812 C 5.453125 5.203125 5.222656 5.347656 4.960938 5.449219 C 4.703125 5.550781 4.433594 5.597656 4.15625 5.59375 C 3.875 5.585938 3.609375 5.527344 3.355469 5.414062 C 3.097656 5.304688 2.875 5.148438 2.683594 4.945312 C 2.488281 4.746094 2.34375 4.515625 2.242188 4.253906 C 2.140625 3.996094 2.09375 3.726562 2.097656 3.445312 C 2.105469 3.167969 2.164062 2.902344 2.273438 2.644531 C 2.386719 2.390625 2.542969 2.167969 2.746094 1.972656 C 2.945312 1.78125 3.175781 1.632812 3.4375 1.535156 C 3.695312 1.433594 3.964844 1.386719 4.242188 1.390625 C 4.523438 1.398438 4.789062 1.457031 5.042969 1.566406 C 5.300781 1.679688 5.523438 1.835938 5.714844 2.039062 Z M 5.714844 2.039062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#08f6f8762d)">
      <g clipPath="url(#aef2ad886e)">
        <g clipPath="url(#08de772a36)">
          <g mask="url(#187eb1aa01)">
            <g transform="matrix(1, 0, 0, 1, 45, 36)">
              <g clipPath="url(#10ebdbd465)">
                <g clipPath="url(#69a0150e36)">
                  <g clipPath="url(#15a9b7c421)">
                    <g clipPath="url(#aff9ea4ddf)">
                      <path
                        fill="#fa4a59"
                        d="M 4.984375 2.15625 C 5.179688 2.355469 5.324219 2.585938 5.425781 2.847656 C 5.527344 3.105469 5.574219 3.375 5.570312 3.65625 C 5.5625 3.933594 5.503906 4.199219 5.390625 4.457031 C 5.28125 4.710938 5.125 4.933594 4.921875 5.128906 C 4.722656 5.320312 4.492188 5.46875 4.230469 5.566406 C 3.972656 5.667969 3.703125 5.71875 3.421875 5.710938 C 3.144531 5.703125 2.878906 5.644531 2.621094 5.535156 C 2.367188 5.421875 2.144531 5.265625 1.949219 5.0625 C 1.757812 4.863281 1.609375 4.632812 1.511719 4.371094 C 1.410156 4.113281 1.363281 3.84375 1.367188 3.566406 C 1.375 3.285156 1.433594 3.019531 1.542969 2.765625 C 1.65625 2.507812 1.8125 2.285156 2.015625 2.09375 C 2.214844 1.898438 2.445312 1.753906 2.707031 1.652344 C 2.964844 1.550781 3.234375 1.503906 3.511719 1.507812 C 3.792969 1.515625 4.058594 1.574219 4.3125 1.6875 C 4.570312 1.796875 4.792969 1.953125 4.984375 2.15625 Z M 4.984375 2.15625 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 4.984375 2.15625 C 5.179688 2.355469 5.324219 2.585938 5.425781 2.847656 C 5.527344 3.105469 5.574219 3.375 5.570312 3.65625 C 5.5625 3.933594 5.503906 4.199219 5.390625 4.457031 C 5.28125 4.710938 5.125 4.933594 4.921875 5.128906 C 4.722656 5.320312 4.492188 5.46875 4.230469 5.566406 C 3.972656 5.667969 3.703125 5.71875 3.421875 5.710938 C 3.144531 5.703125 2.878906 5.644531 2.621094 5.535156 C 2.367188 5.421875 2.144531 5.265625 1.949219 5.0625 C 1.757812 4.863281 1.609375 4.632812 1.511719 4.371094 C 1.410156 4.113281 1.363281 3.84375 1.367188 3.566406 C 1.375 3.285156 1.433594 3.019531 1.542969 2.765625 C 1.65625 2.507812 1.8125 2.285156 2.015625 2.09375 C 2.214844 1.898438 2.445312 1.753906 2.707031 1.652344 C 2.964844 1.550781 3.234375 1.503906 3.511719 1.507812 C 3.792969 1.515625 4.058594 1.574219 4.3125 1.6875 C 4.570312 1.796875 4.792969 1.953125 4.984375 2.15625 Z M 4.984375 2.15625 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#802ca9248a)">
      <g clipPath="url(#2551fe1e7d)">
        <g clipPath="url(#525ab9d9f6)">
          <g mask="url(#23b74a950d)">
            <g transform="matrix(1, 0, 0, 1, 54, 27)">
              <g clipPath="url(#a0fb9d9f3d)">
                <g clipPath="url(#e733e65f4b)">
                  <g clipPath="url(#d1a5e8cc8e)">
                    <g clipPath="url(#737d09d882)">
                      <path
                        fill="#fa4a59"
                        d="M 5.226562 2.300781 C 5.417969 2.503906 5.566406 2.734375 5.667969 2.992188 C 5.769531 3.25 5.816406 3.519531 5.808594 3.800781 C 5.804688 4.078125 5.746094 4.34375 5.632812 4.601562 C 5.519531 4.855469 5.363281 5.078125 5.164062 5.273438 C 4.960938 5.464844 4.730469 5.613281 4.472656 5.710938 C 4.210938 5.8125 3.945312 5.863281 3.664062 5.855469 C 3.386719 5.851562 3.121094 5.789062 2.863281 5.679688 C 2.609375 5.566406 2.386719 5.410156 2.191406 5.207031 C 2 5.007812 1.851562 4.777344 1.75 4.515625 C 1.648438 4.257812 1.601562 3.988281 1.609375 3.710938 C 1.613281 3.429688 1.671875 3.164062 1.785156 2.910156 C 1.898438 2.652344 2.054688 2.429688 2.253906 2.238281 C 2.457031 2.042969 2.6875 1.898438 2.945312 1.796875 C 3.207031 1.695312 3.476562 1.648438 3.753906 1.652344 C 4.03125 1.660156 4.300781 1.71875 4.554688 1.832031 C 4.808594 1.941406 5.035156 2.101562 5.226562 2.300781 Z M 5.226562 2.300781 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.226562 2.300781 C 5.417969 2.503906 5.566406 2.734375 5.667969 2.992188 C 5.769531 3.25 5.816406 3.519531 5.808594 3.800781 C 5.804688 4.078125 5.746094 4.34375 5.632812 4.601562 C 5.519531 4.855469 5.363281 5.078125 5.164062 5.273438 C 4.960938 5.464844 4.730469 5.613281 4.472656 5.710938 C 4.210938 5.8125 3.945312 5.863281 3.664062 5.855469 C 3.386719 5.851562 3.121094 5.789062 2.863281 5.679688 C 2.609375 5.566406 2.386719 5.410156 2.191406 5.207031 C 2 5.007812 1.851562 4.777344 1.75 4.515625 C 1.648438 4.257812 1.601562 3.988281 1.609375 3.710938 C 1.613281 3.429688 1.671875 3.164062 1.785156 2.910156 C 1.898438 2.652344 2.054688 2.429688 2.253906 2.238281 C 2.457031 2.042969 2.6875 1.898438 2.945312 1.796875 C 3.207031 1.695312 3.476562 1.648438 3.753906 1.652344 C 4.03125 1.660156 4.300781 1.71875 4.554688 1.832031 C 4.808594 1.941406 5.035156 2.101562 5.226562 2.300781 Z M 5.226562 2.300781 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#6f8cb0f5b4)">
      <g clipPath="url(#66c4f8590e)">
        <g clipPath="url(#194f7e8b4a)">
          <g mask="url(#ee181d0103)">
            <g transform="matrix(1, 0, 0, 1, 35, 63)">
              <g clipPath="url(#69ed3c189b)">
                <g clipPath="url(#0d9a484d83)">
                  <g clipPath="url(#261d47fed0)">
                    <g clipPath="url(#5d38916314)">
                      <path
                        fill="#fa4a59"
                        d="M 5.355469 2.164062 C 5.550781 2.363281 5.695312 2.59375 5.796875 2.851562 C 5.898438 3.113281 5.945312 3.382812 5.941406 3.660156 C 5.933594 3.941406 5.875 4.207031 5.761719 4.460938 C 5.652344 4.714844 5.496094 4.941406 5.292969 5.132812 C 5.09375 5.324219 4.863281 5.472656 4.601562 5.574219 C 4.34375 5.675781 4.074219 5.722656 3.792969 5.71875 C 3.515625 5.710938 3.25 5.652344 2.992188 5.539062 C 2.738281 5.429688 2.515625 5.269531 2.320312 5.070312 C 2.128906 4.867188 1.984375 4.636719 1.882812 4.378906 C 1.78125 4.117188 1.734375 3.851562 1.738281 3.570312 C 1.746094 3.292969 1.804688 3.027344 1.914062 2.769531 C 2.027344 2.515625 2.183594 2.292969 2.386719 2.097656 C 2.585938 1.90625 2.816406 1.757812 3.078125 1.65625 C 3.335938 1.558594 3.605469 1.507812 3.882812 1.515625 C 4.164062 1.519531 4.429688 1.582031 4.683594 1.691406 C 4.941406 1.804688 5.164062 1.960938 5.355469 2.164062 Z M 5.355469 2.164062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.355469 2.164062 C 5.550781 2.363281 5.695312 2.59375 5.796875 2.851562 C 5.898438 3.113281 5.945312 3.382812 5.941406 3.660156 C 5.933594 3.941406 5.875 4.207031 5.761719 4.460938 C 5.652344 4.714844 5.496094 4.941406 5.292969 5.132812 C 5.09375 5.324219 4.863281 5.472656 4.601562 5.574219 C 4.34375 5.675781 4.074219 5.722656 3.792969 5.71875 C 3.515625 5.710938 3.25 5.652344 2.992188 5.539062 C 2.738281 5.429688 2.515625 5.269531 2.320312 5.070312 C 2.128906 4.867188 1.984375 4.636719 1.882812 4.378906 C 1.78125 4.117188 1.734375 3.851562 1.738281 3.570312 C 1.746094 3.292969 1.804688 3.027344 1.914062 2.769531 C 2.027344 2.515625 2.183594 2.292969 2.386719 2.097656 C 2.585938 1.90625 2.816406 1.757812 3.078125 1.65625 C 3.335938 1.558594 3.605469 1.507812 3.882812 1.515625 C 4.164062 1.519531 4.429688 1.582031 4.683594 1.691406 C 4.941406 1.804688 5.164062 1.960938 5.355469 2.164062 Z M 5.355469 2.164062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#2da719c446)">
      <g clipPath="url(#b41dfbd69a)">
        <g clipPath="url(#b50ca2be29)">
          <g mask="url(#39575e2585)">
            <g transform="matrix(1, 0, 0, 1, 44, 54)">
              <g clipPath="url(#14b4c84e56)">
                <g clipPath="url(#0dab956dcc)">
                  <g clipPath="url(#7a9e0bc04d)">
                    <g clipPath="url(#5bff3b2f23)">
                      <path
                        fill="#fa4a59"
                        d="M 5.597656 2.308594 C 5.789062 2.507812 5.9375 2.738281 6.039062 3 C 6.140625 3.257812 6.1875 3.527344 6.183594 3.804688 C 6.175781 4.085938 6.117188 4.351562 6.003906 4.605469 C 5.894531 4.859375 5.734375 5.085938 5.535156 5.277344 C 5.332031 5.472656 5.101562 5.617188 4.84375 5.71875 C 4.582031 5.820312 4.316406 5.867188 4.035156 5.863281 C 3.757812 5.855469 3.492188 5.796875 3.234375 5.683594 C 2.980469 5.574219 2.757812 5.414062 2.5625 5.214844 C 2.371094 5.011719 2.222656 4.78125 2.121094 4.523438 C 2.023438 4.261719 1.972656 3.996094 1.980469 3.714844 C 1.984375 3.4375 2.046875 3.171875 2.15625 2.914062 C 2.269531 2.660156 2.425781 2.4375 2.628906 2.242188 C 2.828125 2.050781 3.058594 1.902344 3.316406 1.800781 C 3.578125 1.703125 3.847656 1.652344 4.125 1.660156 C 4.40625 1.664062 4.671875 1.726562 4.925781 1.835938 C 5.179688 1.949219 5.40625 2.105469 5.597656 2.308594 Z M 5.597656 2.308594 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.597656 2.308594 C 5.789062 2.507812 5.9375 2.738281 6.039062 3 C 6.140625 3.257812 6.1875 3.527344 6.183594 3.804688 C 6.175781 4.085938 6.117188 4.351562 6.003906 4.605469 C 5.894531 4.859375 5.734375 5.085938 5.535156 5.277344 C 5.332031 5.472656 5.101562 5.617188 4.84375 5.71875 C 4.582031 5.820312 4.316406 5.867188 4.035156 5.863281 C 3.757812 5.855469 3.492188 5.796875 3.234375 5.683594 C 2.980469 5.574219 2.757812 5.414062 2.5625 5.214844 C 2.371094 5.011719 2.222656 4.78125 2.121094 4.523438 C 2.023438 4.261719 1.972656 3.996094 1.980469 3.714844 C 1.984375 3.4375 2.046875 3.171875 2.15625 2.914062 C 2.269531 2.660156 2.425781 2.4375 2.628906 2.242188 C 2.828125 2.050781 3.058594 1.902344 3.316406 1.800781 C 3.578125 1.703125 3.847656 1.652344 4.125 1.660156 C 4.40625 1.664062 4.671875 1.726562 4.925781 1.835938 C 5.179688 1.949219 5.40625 2.105469 5.597656 2.308594 Z M 5.597656 2.308594 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#fcda64e9cf)">
      <g clipPath="url(#7655c984c0)">
        <g clipPath="url(#3ba587a60d)">
          <g mask="url(#3b2e2fe95b)">
            <g transform="matrix(1, 0, 0, 1, 54, 45)">
              <g clipPath="url(#8f6eff54b3)">
                <g clipPath="url(#bd0de14e84)">
                  <g clipPath="url(#c8ad27969e)">
                    <g clipPath="url(#ee648f83f5)">
                      <path
                        fill="#fa4a59"
                        d="M 4.867188 2.425781 C 5.058594 2.625 5.207031 2.855469 5.308594 3.117188 C 5.410156 3.375 5.457031 3.644531 5.449219 3.921875 C 5.445312 4.203125 5.386719 4.46875 5.273438 4.722656 C 5.160156 4.980469 5.003906 5.203125 4.804688 5.394531 C 4.601562 5.589844 4.371094 5.734375 4.113281 5.835938 C 3.851562 5.9375 3.582031 5.984375 3.304688 5.980469 C 3.027344 5.972656 2.757812 5.914062 2.503906 5.804688 C 2.25 5.691406 2.027344 5.535156 1.832031 5.332031 C 1.640625 5.132812 1.492188 4.902344 1.390625 4.640625 C 1.289062 4.382812 1.242188 4.113281 1.25 3.835938 C 1.253906 3.554688 1.3125 3.289062 1.425781 3.035156 C 1.539062 2.777344 1.695312 2.554688 1.894531 2.363281 C 2.097656 2.167969 2.328125 2.023438 2.585938 1.921875 C 2.847656 1.820312 3.117188 1.773438 3.394531 1.777344 C 3.671875 1.785156 3.941406 1.84375 4.195312 1.957031 C 4.449219 2.066406 4.675781 2.222656 4.867188 2.425781 Z M 4.867188 2.425781 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 4.867188 2.425781 C 5.058594 2.625 5.207031 2.855469 5.308594 3.117188 C 5.410156 3.375 5.457031 3.644531 5.449219 3.921875 C 5.445312 4.203125 5.386719 4.46875 5.273438 4.722656 C 5.160156 4.980469 5.003906 5.203125 4.804688 5.394531 C 4.601562 5.589844 4.371094 5.734375 4.113281 5.835938 C 3.851562 5.9375 3.582031 5.984375 3.304688 5.980469 C 3.027344 5.972656 2.757812 5.914062 2.503906 5.804688 C 2.25 5.691406 2.027344 5.535156 1.832031 5.332031 C 1.640625 5.132812 1.492188 4.902344 1.390625 4.640625 C 1.289062 4.382812 1.242188 4.113281 1.25 3.835938 C 1.253906 3.554688 1.3125 3.289062 1.425781 3.035156 C 1.539062 2.777344 1.695312 2.554688 1.894531 2.363281 C 2.097656 2.167969 2.328125 2.023438 2.585938 1.921875 C 2.847656 1.820312 3.117188 1.773438 3.394531 1.777344 C 3.671875 1.785156 3.941406 1.84375 4.195312 1.957031 C 4.449219 2.066406 4.675781 2.222656 4.867188 2.425781 Z M 4.867188 2.425781 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#12c20f53b6)">
      <g clipPath="url(#307247b3f8)">
        <g clipPath="url(#248bcd86e5)">
          <g mask="url(#ba55670f48)">
            <g transform="matrix(1, 0, 0, 1, 63, 36)">
              <g clipPath="url(#a76a924d4b)">
                <g clipPath="url(#16854a581c)">
                  <g clipPath="url(#197d72c3dd)">
                    <g clipPath="url(#994323793d)">
                      <path
                        fill="#fa4a59"
                        d="M 5.109375 2.570312 C 5.300781 2.769531 5.449219 3 5.550781 3.261719 C 5.652344 3.519531 5.699219 3.789062 5.691406 4.070312 C 5.6875 4.347656 5.628906 4.613281 5.515625 4.867188 C 5.402344 5.125 5.246094 5.347656 5.046875 5.539062 C 4.84375 5.734375 4.613281 5.878906 4.355469 5.980469 C 4.09375 6.082031 3.824219 6.128906 3.546875 6.125 C 3.269531 6.117188 3 6.058594 2.746094 5.949219 C 2.492188 5.835938 2.265625 5.679688 2.074219 5.476562 C 1.882812 5.277344 1.734375 5.046875 1.632812 4.785156 C 1.53125 4.527344 1.484375 4.257812 1.492188 3.980469 C 1.496094 3.699219 1.554688 3.433594 1.667969 3.179688 C 1.78125 2.921875 1.9375 2.699219 2.136719 2.507812 C 2.339844 2.3125 2.570312 2.167969 2.828125 2.066406 C 3.089844 1.964844 3.359375 1.917969 3.636719 1.921875 C 3.914062 1.929688 4.179688 1.988281 4.4375 2.101562 C 4.691406 2.210938 4.914062 2.367188 5.109375 2.570312 Z M 5.109375 2.570312 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.109375 2.570312 C 5.300781 2.769531 5.449219 3 5.550781 3.261719 C 5.652344 3.519531 5.699219 3.789062 5.691406 4.070312 C 5.6875 4.347656 5.628906 4.613281 5.515625 4.867188 C 5.402344 5.125 5.246094 5.347656 5.046875 5.539062 C 4.84375 5.734375 4.613281 5.878906 4.355469 5.980469 C 4.09375 6.082031 3.824219 6.128906 3.546875 6.125 C 3.269531 6.117188 3 6.058594 2.746094 5.949219 C 2.492188 5.835938 2.265625 5.679688 2.074219 5.476562 C 1.882812 5.277344 1.734375 5.046875 1.632812 4.785156 C 1.53125 4.527344 1.484375 4.257812 1.492188 3.980469 C 1.496094 3.699219 1.554688 3.433594 1.667969 3.179688 C 1.78125 2.921875 1.9375 2.699219 2.136719 2.507812 C 2.339844 2.3125 2.570312 2.167969 2.828125 2.066406 C 3.089844 1.964844 3.359375 1.917969 3.636719 1.921875 C 3.914062 1.929688 4.179688 1.988281 4.4375 2.101562 C 4.691406 2.210938 4.914062 2.367188 5.109375 2.570312 Z M 5.109375 2.570312 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#39bd712336)">
      <g clipPath="url(#cb0f54ff1f)">
        <g clipPath="url(#fcdbf91c32)">
          <g mask="url(#6b5869b6f2)">
            <g transform="matrix(1, 0, 0, 1, 44, 72)">
              <g clipPath="url(#bd75b4fb9a)">
                <g clipPath="url(#4bd6478ffa)">
                  <g clipPath="url(#feb7cdd7f8)">
                    <g clipPath="url(#2976b96f33)">
                      <path
                        fill="#fa4a59"
                        d="M 5.210938 2.402344 C 5.40625 2.605469 5.550781 2.835938 5.652344 3.09375 C 5.753906 3.355469 5.800781 3.625 5.796875 3.902344 C 5.789062 4.179688 5.730469 4.449219 5.617188 4.703125 C 5.507812 4.957031 5.351562 5.183594 5.148438 5.375 C 4.949219 5.566406 4.71875 5.714844 4.457031 5.816406 C 4.199219 5.917969 3.929688 5.964844 3.648438 5.957031 C 3.371094 5.953125 3.105469 5.894531 2.847656 5.78125 C 2.59375 5.667969 2.371094 5.511719 2.175781 5.3125 C 1.984375 5.109375 1.835938 4.878906 1.738281 4.621094 C 1.636719 4.359375 1.589844 4.089844 1.59375 3.8125 C 1.601562 3.535156 1.660156 3.265625 1.769531 3.011719 C 1.882812 2.757812 2.039062 2.53125 2.242188 2.339844 C 2.441406 2.148438 2.671875 2 2.933594 1.898438 C 3.191406 1.796875 3.460938 1.75 3.738281 1.757812 C 4.019531 1.761719 4.285156 1.820312 4.539062 1.933594 C 4.796875 2.046875 5.019531 2.203125 5.210938 2.402344 Z M 5.210938 2.402344 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.210938 2.402344 C 5.40625 2.605469 5.550781 2.835938 5.652344 3.09375 C 5.753906 3.355469 5.800781 3.625 5.796875 3.902344 C 5.789062 4.179688 5.730469 4.449219 5.617188 4.703125 C 5.507812 4.957031 5.351562 5.183594 5.148438 5.375 C 4.949219 5.566406 4.71875 5.714844 4.457031 5.816406 C 4.199219 5.917969 3.929688 5.964844 3.648438 5.957031 C 3.371094 5.953125 3.105469 5.894531 2.847656 5.78125 C 2.59375 5.667969 2.371094 5.511719 2.175781 5.3125 C 1.984375 5.109375 1.835938 4.878906 1.738281 4.621094 C 1.636719 4.359375 1.589844 4.089844 1.59375 3.8125 C 1.601562 3.535156 1.660156 3.265625 1.769531 3.011719 C 1.882812 2.757812 2.039062 2.53125 2.242188 2.339844 C 2.441406 2.148438 2.671875 2 2.933594 1.898438 C 3.191406 1.796875 3.460938 1.75 3.738281 1.757812 C 4.019531 1.761719 4.285156 1.820312 4.539062 1.933594 C 4.796875 2.046875 5.019531 2.203125 5.210938 2.402344 Z M 5.210938 2.402344 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#eb76fadac2)">
      <g clipPath="url(#50315ae47d)">
        <g clipPath="url(#9edffad452)">
          <g mask="url(#2af0184d75)">
            <g transform="matrix(1, 0, 0, 1, 53, 63)">
              <g clipPath="url(#0ebdb86284)">
                <g clipPath="url(#46a2bc05ab)">
                  <g clipPath="url(#d4737224c4)">
                    <g clipPath="url(#781acfad33)">
                      <path
                        fill="#fa4a59"
                        d="M 5.453125 2.546875 C 5.644531 2.75 5.792969 2.980469 5.894531 3.238281 C 5.996094 3.5 6.042969 3.769531 6.035156 4.046875 C 6.03125 4.324219 5.972656 4.59375 5.859375 4.847656 C 5.746094 5.101562 5.589844 5.328125 5.390625 5.519531 C 5.1875 5.710938 4.957031 5.859375 4.699219 5.960938 C 4.4375 6.0625 4.171875 6.109375 3.890625 6.101562 C 3.613281 6.097656 3.347656 6.039062 3.089844 5.925781 C 2.835938 5.8125 2.613281 5.65625 2.417969 5.457031 C 2.226562 5.253906 2.078125 5.023438 1.976562 4.765625 C 1.875 4.503906 1.828125 4.234375 1.835938 3.957031 C 1.839844 3.679688 1.898438 3.410156 2.011719 3.15625 C 2.125 2.902344 2.28125 2.675781 2.480469 2.484375 C 2.683594 2.292969 2.914062 2.144531 3.171875 2.042969 C 3.433594 1.941406 3.703125 1.894531 3.980469 1.902344 C 4.257812 1.90625 4.527344 1.964844 4.78125 2.078125 C 5.035156 2.191406 5.261719 2.347656 5.453125 2.546875 Z M 5.453125 2.546875 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.453125 2.546875 C 5.644531 2.75 5.792969 2.980469 5.894531 3.238281 C 5.996094 3.5 6.042969 3.769531 6.035156 4.046875 C 6.03125 4.324219 5.972656 4.59375 5.859375 4.847656 C 5.746094 5.101562 5.589844 5.328125 5.390625 5.519531 C 5.1875 5.710938 4.957031 5.859375 4.699219 5.960938 C 4.4375 6.0625 4.171875 6.109375 3.890625 6.101562 C 3.613281 6.097656 3.347656 6.039062 3.089844 5.925781 C 2.835938 5.8125 2.613281 5.65625 2.417969 5.457031 C 2.226562 5.253906 2.078125 5.023438 1.976562 4.765625 C 1.875 4.503906 1.828125 4.234375 1.835938 3.957031 C 1.839844 3.679688 1.898438 3.410156 2.011719 3.15625 C 2.125 2.902344 2.28125 2.675781 2.480469 2.484375 C 2.683594 2.292969 2.914062 2.144531 3.171875 2.042969 C 3.433594 1.941406 3.703125 1.894531 3.980469 1.902344 C 4.257812 1.90625 4.527344 1.964844 4.78125 2.078125 C 5.035156 2.191406 5.261719 2.347656 5.453125 2.546875 Z M 5.453125 2.546875 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#5727cf19e1)">
      <g clipPath="url(#05e505915e)">
        <g clipPath="url(#edf9af1d13)">
          <g mask="url(#5f6882e9d4)">
            <g transform="matrix(1, 0, 0, 1, 62, 54)">
              <g clipPath="url(#3bc1fe0c08)">
                <g clipPath="url(#5c718f0314)">
                  <g clipPath="url(#a84fd4d8e6)">
                    <g clipPath="url(#5c4b6d3e90)">
                      <path
                        fill="#fa4a59"
                        d="M 5.722656 2.667969 C 5.914062 2.867188 6.0625 3.097656 6.164062 3.359375 C 6.265625 3.617188 6.3125 3.886719 6.304688 4.164062 C 6.300781 4.445312 6.242188 4.710938 6.128906 4.964844 C 6.015625 5.222656 5.859375 5.445312 5.660156 5.636719 C 5.457031 5.832031 5.226562 5.976562 4.96875 6.078125 C 4.707031 6.179688 4.4375 6.226562 4.160156 6.222656 C 3.882812 6.214844 3.613281 6.15625 3.359375 6.042969 C 3.105469 5.933594 2.878906 5.777344 2.6875 5.574219 C 2.496094 5.375 2.347656 5.144531 2.246094 4.882812 C 2.144531 4.625 2.097656 4.355469 2.105469 4.074219 C 2.109375 3.796875 2.167969 3.53125 2.28125 3.273438 C 2.394531 3.019531 2.550781 2.796875 2.75 2.601562 C 2.953125 2.410156 3.183594 2.261719 3.441406 2.164062 C 3.703125 2.0625 3.972656 2.011719 4.25 2.019531 C 4.527344 2.027344 4.796875 2.085938 5.050781 2.195312 C 5.304688 2.308594 5.53125 2.464844 5.722656 2.667969 Z M 5.722656 2.667969 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.722656 2.667969 C 5.914062 2.867188 6.0625 3.097656 6.164062 3.359375 C 6.265625 3.617188 6.3125 3.886719 6.304688 4.164062 C 6.300781 4.445312 6.242188 4.710938 6.128906 4.964844 C 6.015625 5.222656 5.859375 5.445312 5.660156 5.636719 C 5.457031 5.832031 5.226562 5.976562 4.96875 6.078125 C 4.707031 6.179688 4.4375 6.226562 4.160156 6.222656 C 3.882812 6.214844 3.613281 6.15625 3.359375 6.042969 C 3.105469 5.933594 2.878906 5.777344 2.6875 5.574219 C 2.496094 5.375 2.347656 5.144531 2.246094 4.882812 C 2.144531 4.625 2.097656 4.355469 2.105469 4.074219 C 2.109375 3.796875 2.167969 3.53125 2.28125 3.273438 C 2.394531 3.019531 2.550781 2.796875 2.75 2.601562 C 2.953125 2.410156 3.183594 2.261719 3.441406 2.164062 C 3.703125 2.0625 3.972656 2.011719 4.25 2.019531 C 4.527344 2.027344 4.796875 2.085938 5.050781 2.195312 C 5.304688 2.308594 5.53125 2.464844 5.722656 2.667969 Z M 5.722656 2.667969 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#0ba862aae6)">
      <g clipPath="url(#90bb20e2ef)">
        <g clipPath="url(#f2ef41b265)">
          <g mask="url(#5251d8781a)">
            <g transform="matrix(1, 0, 0, 1, 71, 45)">
              <g clipPath="url(#f097978e73)">
                <g clipPath="url(#d8969854eb)">
                  <g clipPath="url(#d63fff177b)">
                    <g clipPath="url(#b6140e19cd)">
                      <path
                        fill="#fa4a59"
                        d="M 5.964844 2.8125 C 6.15625 3.011719 6.304688 3.242188 6.40625 3.503906 C 6.507812 3.761719 6.554688 4.03125 6.546875 4.308594 C 6.542969 4.589844 6.484375 4.855469 6.371094 5.109375 C 6.257812 5.367188 6.101562 5.589844 5.902344 5.78125 C 5.699219 5.976562 5.46875 6.121094 5.210938 6.222656 C 4.949219 6.324219 4.679688 6.371094 4.402344 6.367188 C 4.125 6.359375 3.855469 6.300781 3.601562 6.1875 C 3.347656 6.078125 3.121094 5.921875 2.929688 5.71875 C 2.738281 5.519531 2.589844 5.289062 2.488281 5.027344 C 2.386719 4.769531 2.339844 4.5 2.347656 4.21875 C 2.351562 3.941406 2.410156 3.675781 2.523438 3.417969 C 2.636719 3.164062 2.792969 2.941406 2.992188 2.746094 C 3.195312 2.554688 3.425781 2.40625 3.683594 2.308594 C 3.945312 2.207031 4.210938 2.160156 4.492188 2.164062 C 4.769531 2.171875 5.035156 2.230469 5.292969 2.339844 C 5.546875 2.453125 5.769531 2.609375 5.964844 2.8125 Z M 5.964844 2.8125 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.964844 2.8125 C 6.15625 3.011719 6.304688 3.242188 6.40625 3.503906 C 6.507812 3.761719 6.554688 4.03125 6.546875 4.308594 C 6.542969 4.589844 6.484375 4.855469 6.371094 5.109375 C 6.257812 5.367188 6.101562 5.589844 5.902344 5.78125 C 5.699219 5.976562 5.46875 6.121094 5.210938 6.222656 C 4.949219 6.324219 4.679688 6.371094 4.402344 6.367188 C 4.125 6.359375 3.855469 6.300781 3.601562 6.1875 C 3.347656 6.078125 3.121094 5.921875 2.929688 5.71875 C 2.738281 5.519531 2.589844 5.289062 2.488281 5.027344 C 2.386719 4.769531 2.339844 4.5 2.347656 4.21875 C 2.351562 3.941406 2.410156 3.675781 2.523438 3.417969 C 2.636719 3.164062 2.792969 2.941406 2.992188 2.746094 C 3.195312 2.554688 3.425781 2.40625 3.683594 2.308594 C 3.945312 2.207031 4.210938 2.160156 4.492188 2.164062 C 4.769531 2.171875 5.035156 2.230469 5.292969 2.339844 C 5.546875 2.453125 5.769531 2.609375 5.964844 2.8125 Z M 5.964844 2.8125 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#925c3c41b5)">
      <g clipPath="url(#f71d347a83)">
        <g clipPath="url(#4fccd02fe7)">
          <g mask="url(#b9a1d96769)">
            <g transform="matrix(1, 0, 0, 1, 53, 81)">
              <g clipPath="url(#b6516b93b3)">
                <g clipPath="url(#519d61379e)">
                  <g clipPath="url(#22602a54ce)">
                    <g clipPath="url(#f398590eae)">
                      <path
                        fill="#fa4a59"
                        d="M 5.066406 2.644531 C 5.261719 2.847656 5.40625 3.078125 5.507812 3.335938 C 5.609375 3.597656 5.65625 3.863281 5.652344 4.144531 C 5.644531 4.421875 5.585938 4.6875 5.472656 4.945312 C 5.363281 5.199219 5.207031 5.421875 5.003906 5.617188 C 4.800781 5.808594 4.574219 5.957031 4.3125 6.058594 C 4.054688 6.15625 3.785156 6.207031 3.503906 6.199219 C 3.226562 6.195312 2.960938 6.136719 2.703125 6.023438 C 2.449219 5.910156 2.226562 5.753906 2.03125 5.554688 C 1.839844 5.351562 1.691406 5.121094 1.59375 4.863281 C 1.492188 4.601562 1.441406 4.332031 1.449219 4.054688 C 1.457031 3.777344 1.515625 3.507812 1.625 3.253906 C 1.738281 3 1.894531 2.773438 2.097656 2.582031 C 2.296875 2.390625 2.527344 2.242188 2.789062 2.140625 C 3.046875 2.039062 3.316406 1.992188 3.59375 1.996094 C 3.875 2.003906 4.140625 2.0625 4.394531 2.175781 C 4.652344 2.289062 4.875 2.445312 5.066406 2.644531 Z M 5.066406 2.644531 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.066406 2.644531 C 5.261719 2.847656 5.40625 3.078125 5.507812 3.335938 C 5.609375 3.597656 5.65625 3.863281 5.652344 4.144531 C 5.644531 4.421875 5.585938 4.6875 5.472656 4.945312 C 5.363281 5.199219 5.207031 5.421875 5.003906 5.617188 C 4.800781 5.808594 4.574219 5.957031 4.3125 6.058594 C 4.054688 6.15625 3.785156 6.207031 3.503906 6.199219 C 3.226562 6.195312 2.960938 6.136719 2.703125 6.023438 C 2.449219 5.910156 2.226562 5.753906 2.03125 5.554688 C 1.839844 5.351562 1.691406 5.121094 1.59375 4.863281 C 1.492188 4.601562 1.441406 4.332031 1.449219 4.054688 C 1.457031 3.777344 1.515625 3.507812 1.625 3.253906 C 1.738281 3 1.894531 2.773438 2.097656 2.582031 C 2.296875 2.390625 2.527344 2.242188 2.789062 2.140625 C 3.046875 2.039062 3.316406 1.992188 3.59375 1.996094 C 3.875 2.003906 4.140625 2.0625 4.394531 2.175781 C 4.652344 2.289062 4.875 2.445312 5.066406 2.644531 Z M 5.066406 2.644531 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#26b6b746a3)">
      <g clipPath="url(#9a4d16b82b)">
        <g clipPath="url(#dae5626d40)">
          <g mask="url(#8afbfdb0bc)">
            <g transform="matrix(1, 0, 0, 1, 62, 72)">
              <g clipPath="url(#b2bb925a19)">
                <g clipPath="url(#f1917db80e)">
                  <g clipPath="url(#49d4cee09e)">
                    <g clipPath="url(#b5c892c50a)">
                      <path
                        fill="#fa4a59"
                        d="M 5.308594 2.789062 C 5.5 2.992188 5.648438 3.222656 5.75 3.480469 C 5.851562 3.742188 5.898438 4.011719 5.890625 4.289062 C 5.886719 4.566406 5.828125 4.832031 5.714844 5.089844 C 5.601562 5.34375 5.445312 5.566406 5.246094 5.761719 C 5.042969 5.953125 4.8125 6.101562 4.554688 6.203125 C 4.292969 6.304688 4.023438 6.351562 3.746094 6.34375 C 3.46875 6.339844 3.203125 6.28125 2.945312 6.167969 C 2.691406 6.054688 2.46875 5.898438 2.273438 5.699219 C 2.082031 5.496094 1.933594 5.265625 1.832031 5.007812 C 1.730469 4.746094 1.683594 4.476562 1.691406 4.199219 C 1.695312 3.921875 1.753906 3.652344 1.867188 3.398438 C 1.980469 3.144531 2.136719 2.917969 2.335938 2.726562 C 2.539062 2.535156 2.769531 2.386719 3.027344 2.285156 C 3.289062 2.183594 3.558594 2.136719 3.835938 2.144531 C 4.113281 2.148438 4.382812 2.207031 4.636719 2.320312 C 4.890625 2.433594 5.117188 2.589844 5.308594 2.789062 Z M 5.308594 2.789062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.308594 2.789062 C 5.5 2.992188 5.648438 3.222656 5.75 3.480469 C 5.851562 3.742188 5.898438 4.011719 5.890625 4.289062 C 5.886719 4.566406 5.828125 4.832031 5.714844 5.089844 C 5.601562 5.34375 5.445312 5.566406 5.246094 5.761719 C 5.042969 5.953125 4.8125 6.101562 4.554688 6.203125 C 4.292969 6.304688 4.023438 6.351562 3.746094 6.34375 C 3.46875 6.339844 3.203125 6.28125 2.945312 6.167969 C 2.691406 6.054688 2.46875 5.898438 2.273438 5.699219 C 2.082031 5.496094 1.933594 5.265625 1.832031 5.007812 C 1.730469 4.746094 1.683594 4.476562 1.691406 4.199219 C 1.695312 3.921875 1.753906 3.652344 1.867188 3.398438 C 1.980469 3.144531 2.136719 2.917969 2.335938 2.726562 C 2.539062 2.535156 2.769531 2.386719 3.027344 2.285156 C 3.289062 2.183594 3.558594 2.136719 3.835938 2.144531 C 4.113281 2.148438 4.382812 2.207031 4.636719 2.320312 C 4.890625 2.433594 5.117188 2.589844 5.308594 2.789062 Z M 5.308594 2.789062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#fd9a453d25)">
      <g clipPath="url(#353839057e)">
        <g clipPath="url(#f39aa28fae)">
          <g mask="url(#53e544d73f)">
            <g transform="matrix(1, 0, 0, 1, 71, 64)">
              <g clipPath="url(#62ef32d306)">
                <g clipPath="url(#7637951c09)">
                  <g clipPath="url(#0ac0ab804d)">
                    <g clipPath="url(#38da2d3540)">
                      <path
                        fill="#fa4a59"
                        d="M 5.578125 1.90625 C 5.769531 2.109375 5.917969 2.339844 6.019531 2.597656 C 6.121094 2.859375 6.167969 3.128906 6.160156 3.40625 C 6.15625 3.683594 6.097656 3.953125 5.984375 4.207031 C 5.871094 4.460938 5.714844 4.6875 5.515625 4.878906 C 5.3125 5.070312 5.082031 5.21875 4.824219 5.320312 C 4.5625 5.421875 4.292969 5.46875 4.015625 5.460938 C 3.738281 5.457031 3.46875 5.398438 3.214844 5.285156 C 2.960938 5.171875 2.734375 5.015625 2.542969 4.816406 C 2.351562 4.613281 2.203125 4.382812 2.101562 4.125 C 2 3.863281 1.953125 3.59375 1.960938 3.316406 C 1.964844 3.039062 2.023438 2.773438 2.136719 2.515625 C 2.25 2.261719 2.40625 2.039062 2.605469 1.84375 C 2.808594 1.652344 3.039062 1.503906 3.296875 1.402344 C 3.558594 1.300781 3.828125 1.253906 4.105469 1.261719 C 4.382812 1.265625 4.652344 1.324219 4.90625 1.4375 C 5.160156 1.550781 5.386719 1.707031 5.578125 1.90625 Z M 5.578125 1.90625 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.578125 1.90625 C 5.769531 2.109375 5.917969 2.339844 6.019531 2.597656 C 6.121094 2.859375 6.167969 3.128906 6.160156 3.40625 C 6.15625 3.683594 6.097656 3.953125 5.984375 4.207031 C 5.871094 4.460938 5.714844 4.6875 5.515625 4.878906 C 5.3125 5.070312 5.082031 5.21875 4.824219 5.320312 C 4.5625 5.421875 4.292969 5.46875 4.015625 5.460938 C 3.738281 5.457031 3.46875 5.398438 3.214844 5.285156 C 2.960938 5.171875 2.734375 5.015625 2.542969 4.816406 C 2.351562 4.613281 2.203125 4.382812 2.101562 4.125 C 2 3.863281 1.953125 3.59375 1.960938 3.316406 C 1.964844 3.039062 2.023438 2.773438 2.136719 2.515625 C 2.25 2.261719 2.40625 2.039062 2.605469 1.84375 C 2.808594 1.652344 3.039062 1.503906 3.296875 1.402344 C 3.558594 1.300781 3.828125 1.253906 4.105469 1.261719 C 4.382812 1.265625 4.652344 1.324219 4.90625 1.4375 C 5.160156 1.550781 5.386719 1.707031 5.578125 1.90625 Z M 5.578125 1.90625 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#a9d2dcf6b8)">
      <g clipPath="url(#6fb8988c81)">
        <g clipPath="url(#67456efcdb)">
          <g mask="url(#598abed214)">
            <g transform="matrix(1, 0, 0, 1, 80, 55)">
              <g clipPath="url(#b49a322988)">
                <g clipPath="url(#95ad556984)">
                  <g clipPath="url(#598c24a55d)">
                    <g clipPath="url(#d162906115)">
                      <path
                        fill="#fa4a59"
                        d="M 5.820312 2.050781 C 6.011719 2.253906 6.160156 2.484375 6.261719 2.742188 C 6.359375 3.003906 6.410156 3.273438 6.402344 3.550781 C 6.398438 3.828125 6.335938 4.097656 6.226562 4.351562 C 6.113281 4.605469 5.957031 4.832031 5.753906 5.023438 C 5.554688 5.214844 5.324219 5.363281 5.066406 5.464844 C 4.804688 5.566406 4.535156 5.613281 4.257812 5.609375 C 3.980469 5.601562 3.710938 5.542969 3.457031 5.429688 C 3.203125 5.316406 2.976562 5.160156 2.785156 4.960938 C 2.59375 4.757812 2.445312 4.527344 2.34375 4.269531 C 2.242188 4.007812 2.195312 3.742188 2.199219 3.460938 C 2.207031 3.183594 2.265625 2.917969 2.378906 2.660156 C 2.492188 2.40625 2.648438 2.183594 2.847656 1.988281 C 3.050781 1.796875 3.28125 1.648438 3.539062 1.546875 C 3.800781 1.449219 4.066406 1.398438 4.347656 1.40625 C 4.625 1.410156 4.890625 1.46875 5.148438 1.582031 C 5.402344 1.695312 5.625 1.851562 5.820312 2.050781 Z M 5.820312 2.050781 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.820312 2.050781 C 6.011719 2.253906 6.160156 2.484375 6.261719 2.742188 C 6.359375 3.003906 6.410156 3.273438 6.402344 3.550781 C 6.398438 3.828125 6.335938 4.097656 6.226562 4.351562 C 6.113281 4.605469 5.957031 4.832031 5.753906 5.023438 C 5.554688 5.214844 5.324219 5.363281 5.066406 5.464844 C 4.804688 5.566406 4.535156 5.613281 4.257812 5.609375 C 3.980469 5.601562 3.710938 5.542969 3.457031 5.429688 C 3.203125 5.316406 2.976562 5.160156 2.785156 4.960938 C 2.59375 4.757812 2.445312 4.527344 2.34375 4.269531 C 2.242188 4.007812 2.195312 3.742188 2.199219 3.460938 C 2.207031 3.183594 2.265625 2.917969 2.378906 2.660156 C 2.492188 2.40625 2.648438 2.183594 2.847656 1.988281 C 3.050781 1.796875 3.28125 1.648438 3.539062 1.546875 C 3.800781 1.449219 4.066406 1.398438 4.347656 1.40625 C 4.625 1.410156 4.890625 1.46875 5.148438 1.582031 C 5.402344 1.695312 5.625 1.851562 5.820312 2.050781 Z M 5.820312 2.050781 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#b205705436)">
      <g clipPath="url(#6b35610e51)">
        <g clipPath="url(#91dabc2310)">
          <g mask="url(#334388b3c5)">
            <g transform="matrix(1, 0, 0, 1, 62, 90)">
              <g clipPath="url(#08000dd6de)">
                <g clipPath="url(#b8b8749b6e)">
                  <g clipPath="url(#3d047baa0b)">
                    <g clipPath="url(#da9ba82848)">
                      <path
                        fill="#fa4a59"
                        d="M 4.949219 2.914062 C 5.140625 3.117188 5.289062 3.347656 5.390625 3.605469 C 5.492188 3.863281 5.539062 4.132812 5.53125 4.414062 C 5.527344 4.691406 5.46875 4.957031 5.355469 5.214844 C 5.242188 5.46875 5.085938 5.691406 4.886719 5.886719 C 4.683594 6.078125 4.453125 6.226562 4.195312 6.324219 C 3.933594 6.425781 3.664062 6.476562 3.386719 6.46875 C 3.109375 6.460938 2.839844 6.402344 2.585938 6.292969 C 2.332031 6.179688 2.105469 6.023438 1.914062 5.820312 C 1.722656 5.621094 1.574219 5.390625 1.472656 5.128906 C 1.371094 4.871094 1.324219 4.601562 1.332031 4.324219 C 1.335938 4.042969 1.394531 3.777344 1.507812 3.523438 C 1.621094 3.265625 1.777344 3.042969 1.976562 2.851562 C 2.179688 2.65625 2.410156 2.511719 2.667969 2.410156 C 2.929688 2.308594 3.199219 2.261719 3.476562 2.265625 C 3.753906 2.273438 4.023438 2.332031 4.277344 2.445312 C 4.53125 2.554688 4.757812 2.710938 4.949219 2.914062 Z M 4.949219 2.914062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 4.949219 2.914062 C 5.140625 3.117188 5.289062 3.347656 5.390625 3.605469 C 5.492188 3.863281 5.539062 4.132812 5.53125 4.414062 C 5.527344 4.691406 5.46875 4.957031 5.355469 5.214844 C 5.242188 5.46875 5.085938 5.691406 4.886719 5.886719 C 4.683594 6.078125 4.453125 6.226562 4.195312 6.324219 C 3.933594 6.425781 3.664062 6.476562 3.386719 6.46875 C 3.109375 6.460938 2.839844 6.402344 2.585938 6.292969 C 2.332031 6.179688 2.105469 6.023438 1.914062 5.820312 C 1.722656 5.621094 1.574219 5.390625 1.472656 5.128906 C 1.371094 4.871094 1.324219 4.601562 1.332031 4.324219 C 1.335938 4.042969 1.394531 3.777344 1.507812 3.523438 C 1.621094 3.265625 1.777344 3.042969 1.976562 2.851562 C 2.179688 2.65625 2.410156 2.511719 2.667969 2.410156 C 2.929688 2.308594 3.199219 2.261719 3.476562 2.265625 C 3.753906 2.273438 4.023438 2.332031 4.277344 2.445312 C 4.53125 2.554688 4.757812 2.710938 4.949219 2.914062 Z M 4.949219 2.914062 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#fc3b79adb4)">
      <g clipPath="url(#37b925d161)">
        <g clipPath="url(#90c19b875c)">
          <g mask="url(#013c1cb2f0)">
            <g transform="matrix(1, 0, 0, 1, 71, 82)">
              <g clipPath="url(#3e79fded6c)">
                <g clipPath="url(#8ccd4a58ca)">
                  <g clipPath="url(#dcc2279e9c)">
                    <g clipPath="url(#0bb186f95f)">
                      <path
                        fill="#fa4a59"
                        d="M 5.191406 2.058594 C 5.382812 2.261719 5.53125 2.492188 5.632812 2.75 C 5.734375 3.007812 5.78125 3.277344 5.773438 3.558594 C 5.769531 3.835938 5.710938 4.101562 5.597656 4.359375 C 5.484375 4.613281 5.328125 4.835938 5.128906 5.03125 C 4.925781 5.222656 4.695312 5.371094 4.4375 5.472656 C 4.175781 5.570312 3.90625 5.621094 3.628906 5.613281 C 3.351562 5.609375 3.082031 5.546875 2.828125 5.4375 C 2.574219 5.324219 2.347656 5.167969 2.15625 4.964844 C 1.964844 4.765625 1.816406 4.535156 1.714844 4.273438 C 1.613281 4.015625 1.566406 3.746094 1.574219 3.46875 C 1.578125 3.1875 1.636719 2.921875 1.75 2.667969 C 1.863281 2.414062 2.019531 2.1875 2.21875 1.996094 C 2.421875 1.800781 2.652344 1.65625 2.910156 1.554688 C 3.171875 1.453125 3.441406 1.40625 3.71875 1.410156 C 3.996094 1.417969 4.261719 1.476562 4.519531 1.589844 C 4.773438 1.699219 4.996094 1.859375 5.191406 2.058594 Z M 5.191406 2.058594 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.191406 2.058594 C 5.382812 2.261719 5.53125 2.492188 5.632812 2.75 C 5.734375 3.007812 5.78125 3.277344 5.773438 3.558594 C 5.769531 3.835938 5.710938 4.101562 5.597656 4.359375 C 5.484375 4.613281 5.328125 4.835938 5.128906 5.03125 C 4.925781 5.222656 4.695312 5.371094 4.4375 5.472656 C 4.175781 5.570312 3.90625 5.621094 3.628906 5.613281 C 3.351562 5.609375 3.082031 5.546875 2.828125 5.4375 C 2.574219 5.324219 2.347656 5.167969 2.15625 4.964844 C 1.964844 4.765625 1.816406 4.535156 1.714844 4.273438 C 1.613281 4.015625 1.566406 3.746094 1.574219 3.46875 C 1.578125 3.1875 1.636719 2.921875 1.75 2.667969 C 1.863281 2.414062 2.019531 2.1875 2.21875 1.996094 C 2.421875 1.800781 2.652344 1.65625 2.910156 1.554688 C 3.171875 1.453125 3.441406 1.40625 3.71875 1.410156 C 3.996094 1.417969 4.261719 1.476562 4.519531 1.589844 C 4.773438 1.699219 4.996094 1.859375 5.191406 2.058594 Z M 5.191406 2.058594 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#8a7ae8b7a3)">
      <g clipPath="url(#71cde103da)">
        <g clipPath="url(#d54cbcc0aa)">
          <g mask="url(#50f671132e)">
            <g transform="matrix(1, 0, 0, 1, 80, 73)">
              <g clipPath="url(#81d0df6969)">
                <g clipPath="url(#f01164bac1)">
                  <g clipPath="url(#8b3f8b3101)">
                    <g clipPath="url(#1f6a99ad6c)">
                      <path
                        fill="#fa4a59"
                        d="M 5.460938 2.175781 C 5.652344 2.378906 5.800781 2.609375 5.898438 2.867188 C 6 3.128906 6.050781 3.398438 6.042969 3.675781 C 6.039062 3.953125 5.976562 4.222656 5.867188 4.476562 C 5.753906 4.730469 5.597656 4.957031 5.394531 5.148438 C 5.195312 5.339844 4.964844 5.488281 4.703125 5.589844 C 4.445312 5.691406 4.175781 5.738281 3.898438 5.730469 C 3.617188 5.726562 3.351562 5.667969 3.097656 5.554688 C 2.839844 5.441406 2.617188 5.285156 2.425781 5.085938 C 2.230469 4.882812 2.085938 4.652344 1.984375 4.394531 C 1.882812 4.132812 1.835938 3.863281 1.839844 3.585938 C 1.847656 3.308594 1.90625 3.039062 2.019531 2.785156 C 2.128906 2.53125 2.289062 2.304688 2.488281 2.113281 C 2.691406 1.921875 2.921875 1.773438 3.179688 1.671875 C 3.4375 1.570312 3.707031 1.523438 3.988281 1.53125 C 4.265625 1.535156 4.53125 1.59375 4.789062 1.707031 C 5.042969 1.820312 5.265625 1.976562 5.460938 2.175781 Z M 5.460938 2.175781 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.460938 2.175781 C 5.652344 2.378906 5.800781 2.609375 5.898438 2.867188 C 6 3.128906 6.050781 3.398438 6.042969 3.675781 C 6.039062 3.953125 5.976562 4.222656 5.867188 4.476562 C 5.753906 4.730469 5.597656 4.957031 5.394531 5.148438 C 5.195312 5.339844 4.964844 5.488281 4.703125 5.589844 C 4.445312 5.691406 4.175781 5.738281 3.898438 5.730469 C 3.617188 5.726562 3.351562 5.667969 3.097656 5.554688 C 2.839844 5.441406 2.617188 5.285156 2.425781 5.085938 C 2.230469 4.882812 2.085938 4.652344 1.984375 4.394531 C 1.882812 4.132812 1.835938 3.863281 1.839844 3.585938 C 1.847656 3.308594 1.90625 3.039062 2.019531 2.785156 C 2.128906 2.53125 2.289062 2.304688 2.488281 2.113281 C 2.691406 1.921875 2.921875 1.773438 3.179688 1.671875 C 3.4375 1.570312 3.707031 1.523438 3.988281 1.53125 C 4.265625 1.535156 4.53125 1.59375 4.789062 1.707031 C 5.042969 1.820312 5.265625 1.976562 5.460938 2.175781 Z M 5.460938 2.175781 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#59147fac4c)">
      <g clipPath="url(#51bb5cc828)">
        <g clipPath="url(#673c98a060)">
          <g mask="url(#bf9bd77b40)">
            <g transform="matrix(1, 0, 0, 1, 89, 64)">
              <g clipPath="url(#49be33ee57)">
                <g clipPath="url(#95ee67cbdc)">
                  <g clipPath="url(#44ab62155b)">
                    <g clipPath="url(#91eca3b78b)">
                      <path
                        fill="#fa4a59"
                        d="M 5.699219 2.320312 C 5.894531 2.523438 6.039062 2.753906 6.140625 3.011719 C 6.242188 3.273438 6.289062 3.542969 6.285156 3.820312 C 6.277344 4.097656 6.21875 4.367188 6.109375 4.621094 C 5.996094 4.875 5.839844 5.101562 5.636719 5.292969 C 5.4375 5.484375 5.207031 5.632812 4.945312 5.734375 C 4.6875 5.835938 4.417969 5.882812 4.140625 5.875 C 3.859375 5.871094 3.59375 5.8125 3.339844 5.699219 C 3.082031 5.585938 2.859375 5.429688 2.667969 5.230469 C 2.472656 5.027344 2.328125 4.796875 2.226562 4.539062 C 2.125 4.277344 2.078125 4.007812 2.082031 3.730469 C 2.089844 3.453125 2.148438 3.183594 2.261719 2.929688 C 2.371094 2.675781 2.527344 2.449219 2.730469 2.257812 C 2.929688 2.066406 3.160156 1.917969 3.421875 1.816406 C 3.679688 1.714844 3.949219 1.667969 4.226562 1.675781 C 4.507812 1.679688 4.773438 1.738281 5.027344 1.851562 C 5.285156 1.964844 5.507812 2.121094 5.699219 2.320312 Z M 5.699219 2.320312 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                      <path
                        fill="#fa4a59"
                        d="M 5.699219 2.320312 C 5.894531 2.523438 6.039062 2.753906 6.140625 3.011719 C 6.242188 3.273438 6.289062 3.542969 6.285156 3.820312 C 6.277344 4.097656 6.21875 4.367188 6.109375 4.621094 C 5.996094 4.875 5.839844 5.101562 5.636719 5.292969 C 5.4375 5.484375 5.207031 5.632812 4.945312 5.734375 C 4.6875 5.835938 4.417969 5.882812 4.140625 5.875 C 3.859375 5.871094 3.59375 5.8125 3.339844 5.699219 C 3.082031 5.585938 2.859375 5.429688 2.667969 5.230469 C 2.472656 5.027344 2.328125 4.796875 2.226562 4.539062 C 2.125 4.277344 2.078125 4.007812 2.082031 3.730469 C 2.089844 3.453125 2.148438 3.183594 2.261719 2.929688 C 2.371094 2.675781 2.527344 2.449219 2.730469 2.257812 C 2.929688 2.066406 3.160156 1.917969 3.421875 1.816406 C 3.679688 1.714844 3.949219 1.667969 4.226562 1.675781 C 4.507812 1.679688 4.773438 1.738281 5.027344 1.851562 C 5.285156 1.964844 5.507812 2.121094 5.699219 2.320312 Z M 5.699219 2.320312 "
                        fillOpacity={1}
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#8ce79e363f)">
      <g clipPath="url(#726f6f7741)">
        <path
          fill="#829af9"
          d="M 61.28125 20.300781 L 109.992188 20.300781 L 109.992188 69.011719 L 61.28125 69.011719 Z M 61.28125 20.300781 "
          fillOpacity={1}
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);
export default Top;
