import * as React from 'react';

const Right = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={112}
    zoomAndPan="magnify"
    viewBox="0 0 84 116.249996"
    height={155}
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <defs>
      <filter x="0%" y="0%" width="100%" height="100%" id="458e15f755">
        <feColorMatrix
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          colorInterpolationFilters="sRGB"
        />
      </filter>
      <clipPath id="a23e6a4e3d">
        <path
          d="M 30.855469 109 L 37 109 L 37 114.757812 L 30.855469 114.757812 Z M 30.855469 109 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0ab174cb97">
        <path
          d="M 46 109 L 52 109 L 52 114.757812 L 46 114.757812 Z M 46 109 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="c060130503">
        <path
          d="M 62 109 L 68 109 L 68 114.757812 L 62 114.757812 Z M 62 109 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id={3384588076}>
        <path
          d="M 77 109 L 83.019531 109 L 83.019531 114.757812 L 77 114.757812 Z M 77 109 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="3538e2b53a">
        <path
          d="M 30.855469 94 L 37 94 L 37 100 L 30.855469 100 Z M 30.855469 94 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="738b0f9500">
        <path d="M 77 94 L 83.019531 94 L 83.019531 100 L 77 100 Z M 77 94 " clipRule="nonzero" />
      </clipPath>
      <clipPath id="26208f302e">
        <path
          d="M 30.855469 77 L 37 77 L 37 84 L 30.855469 84 Z M 30.855469 77 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="4c37e5d977">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="a551eb1a92">
        <path
          d="M 0.855469 1 L 6.515625 1 L 6.515625 6.699219 L 0.855469 6.699219 Z M 0.855469 1 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d2ce6a8694">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <mask id="fc4657abf7">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="cb07a294ca">
        <path
          d="M 0.0546875 1 L 6 1 L 6 6.699219 L 0.0546875 6.699219 Z M 0.0546875 1 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0bfb94c267">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <mask id="94781beff7">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="2ed1d821af">
        <path
          d="M 0.03125 1 L 5.753906 1 L 5.753906 6.699219 L 0.03125 6.699219 Z M 0.03125 1 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="2f160ec581">
        <rect x={0} width={6} y={0} height={7} />
      </clipPath>
      <clipPath id="aed6f714c3">
        <path d="M 77 77 L 83.019531 77 L 83.019531 84 L 77 84 Z M 77 77 " clipRule="nonzero" />
      </clipPath>
      <mask id="92132a10f3">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="1b9d8a0376">
        <path
          d="M 0.53125 1 L 6.019531 1 L 6.019531 6.699219 L 0.53125 6.699219 Z M 0.53125 1 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="74a2670c6c">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <clipPath id="6d9ba72ef2">
        <path
          d="M 30.855469 62 L 37 62 L 37 69 L 30.855469 69 Z M 30.855469 62 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="85c1a523ab">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="ed1c7235f7">
        <path
          d="M 0.855469 0.476562 L 6.515625 0.476562 L 6.515625 6 L 0.855469 6 Z M 0.855469 0.476562 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="70178cc786">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <mask id="2de6655494">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="a6e7637ed5">
        <path
          d="M 0.0546875 0.476562 L 6 0.476562 L 6 6 L 0.0546875 6 Z M 0.0546875 0.476562 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="9ddf57ee24">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <mask id="4071ac318b">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="204aab15ec">
        <path
          d="M 0.03125 0.476562 L 5.753906 0.476562 L 5.753906 6 L 0.03125 6 Z M 0.03125 0.476562 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="ecab3c183c">
        <rect x={0} width={6} y={0} height={7} />
      </clipPath>
      <clipPath id="8b568a9321">
        <path d="M 77 62 L 83.019531 62 L 83.019531 69 L 77 69 Z M 77 62 " clipRule="nonzero" />
      </clipPath>
      <mask id="3509e99de6">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.8}
          />
        </g>
      </mask>
      <clipPath id="c0f54799af">
        <path
          d="M 0.53125 0.476562 L 6.019531 0.476562 L 6.019531 6 L 0.53125 6 Z M 0.53125 0.476562 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="1ce3202eaf">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <clipPath id="7ae662c72a">
        <path
          d="M 30.855469 46 L 37 46 L 37 53 L 30.855469 53 Z M 30.855469 46 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="f7337b87aa">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="9041f42d72">
        <path
          d="M 0.855469 1 L 6.515625 1 L 6.515625 6.460938 L 0.855469 6.460938 Z M 0.855469 1 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="24e291f153">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <mask id="85da997131">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="3e5278d0c4">
        <path
          d="M 0.0546875 1 L 6 1 L 6 6.460938 L 0.0546875 6.460938 Z M 0.0546875 1 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="7defff9f99">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <mask id="94d93ef438">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="aaf5ec80cb">
        <path
          d="M 0.03125 1 L 5.753906 1 L 5.753906 6.460938 L 0.03125 6.460938 Z M 0.03125 1 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="50fd2a3b33">
        <rect x={0} width={6} y={0} height={7} />
      </clipPath>
      <clipPath id="d2854c0e69">
        <path d="M 77 46 L 83.019531 46 L 83.019531 53 L 77 53 Z M 77 46 " clipRule="nonzero" />
      </clipPath>
      <mask id="6a2960b4bd">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="a1c147fd43">
        <path
          d="M 0.53125 1 L 6.019531 1 L 6.019531 6.460938 L 0.53125 6.460938 Z M 0.53125 1 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="1965225a9c">
        <rect x={0} width={7} y={0} height={7} />
      </clipPath>
      <clipPath id="27dae315aa">
        <path
          d="M 30.855469 31 L 37 31 L 37 37 L 30.855469 37 Z M 30.855469 31 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="910c423fc2">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="7a78366ed7">
        <path
          d="M 0.855469 0.238281 L 6.515625 0.238281 L 6.515625 5.960938 L 0.855469 5.960938 Z M 0.855469 0.238281 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0d80a67edb">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <mask id="f649bcc856">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="bfda6a6545">
        <path
          d="M 0.0546875 0.238281 L 6 0.238281 L 6 5.960938 L 0.0546875 5.960938 Z M 0.0546875 0.238281 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="760c35f666">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <mask id="e3f4059d0c">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="ac3dbc5c47">
        <path
          d="M 0.03125 0.238281 L 5.753906 0.238281 L 5.753906 5.960938 L 0.03125 5.960938 Z M 0.03125 0.238281 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="58f6f13551">
        <rect x={0} width={6} y={0} height={6} />
      </clipPath>
      <clipPath id="5e023e15b4">
        <path d="M 77 31 L 83.019531 31 L 83.019531 37 L 77 37 Z M 77 31 " clipRule="nonzero" />
      </clipPath>
      <mask id="5ad1394927">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.6}
          />
        </g>
      </mask>
      <clipPath id="a59ef5c9d6">
        <path
          d="M 0.53125 0.238281 L 6.019531 0.238281 L 6.019531 5.960938 L 0.53125 5.960938 Z M 0.53125 0.238281 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="9739375c2d">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <clipPath id="8d59e649df">
        <path
          d="M 30.855469 15 L 37 15 L 37 21 L 30.855469 21 Z M 30.855469 15 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="baf9bc3e65">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="eb50a3b8eb">
        <path
          d="M 0.855469 0.261719 L 6.515625 0.261719 L 6.515625 5.984375 L 0.855469 5.984375 Z M 0.855469 0.261719 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="f3711ec276">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <mask id="f0453d47d6">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="e14aaca5fd">
        <path
          d="M 0.0546875 0.261719 L 6 0.261719 L 6 5.984375 L 0.0546875 5.984375 Z M 0.0546875 0.261719 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="c48eb53b9b">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <mask id="8dd8736b06">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="4eae291f83">
        <path
          d="M 0.03125 0.261719 L 5.753906 0.261719 L 5.753906 5.984375 L 0.03125 5.984375 Z M 0.03125 0.261719 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="c4df14e184">
        <rect x={0} width={6} y={0} height={6} />
      </clipPath>
      <clipPath id="c4e652f9b0">
        <path d="M 77 15 L 83.019531 15 L 83.019531 21 L 77 21 Z M 77 15 " clipRule="nonzero" />
      </clipPath>
      <mask id="af37cda519">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="945d376f4c">
        <path
          d="M 0.53125 0.261719 L 6.019531 0.261719 L 6.019531 5.984375 L 0.53125 5.984375 Z M 0.53125 0.261719 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="b3107aa31b">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <clipPath id="edafe29266">
        <path
          d="M 30.855469 0.105469 L 37 0.105469 L 37 6 L 30.855469 6 Z M 30.855469 0.105469 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="a4e0314c57">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="65facf98ab">
        <path
          d="M 0.855469 0.105469 L 6.515625 0.105469 L 6.515625 5.722656 L 0.855469 5.722656 Z M 0.855469 0.105469 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="4a874002f7">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <clipPath id="3f48fa6377">
        <path d="M 46 0.105469 L 53 0.105469 L 53 6 L 46 6 Z M 46 0.105469 " clipRule="nonzero" />
      </clipPath>
      <mask id="15f3784352">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="bf514a24ae">
        <path
          d="M 0.0546875 0.105469 L 6 0.105469 L 6 5.722656 L 0.0546875 5.722656 Z M 0.0546875 0.105469 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="569549ace4">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <clipPath id="9cda24ebbd">
        <path d="M 62 0.105469 L 68 0.105469 L 68 6 L 62 6 Z M 62 0.105469 " clipRule="nonzero" />
      </clipPath>
      <mask id="ed1d87fe3c">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="4f19ff5e25">
        <path
          d="M 0.03125 0.105469 L 5.753906 0.105469 L 5.753906 5.722656 L 0.03125 5.722656 Z M 0.03125 0.105469 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="3327153ac1">
        <rect x={0} width={6} y={0} height={6} />
      </clipPath>
      <clipPath id="cfd9a62bf5">
        <path
          d="M 77 0.105469 L 83.019531 0.105469 L 83.019531 6 L 77 6 Z M 77 0.105469 "
          clipRule="nonzero"
        />
      </clipPath>
      <mask id="e2df1999be">
        <g filter="url(#458e15f755)">
          <rect
            x={-8.4}
            width={100.8}
            fill="#000000"
            y={-11.625}
            height={139.499996}
            fillOpacity={0.4}
          />
        </g>
      </mask>
      <clipPath id="20166352dd">
        <path
          d="M 0.53125 0.105469 L 6.019531 0.105469 L 6.019531 5.722656 L 0.53125 5.722656 Z M 0.53125 0.105469 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="8acda35d9b">
        <rect x={0} width={7} y={0} height={6} />
      </clipPath>
      <clipPath id="ef5a3b5f60">
        <path
          d="M 0.269531 42.300781 L 49.039062 42.300781 L 49.039062 91.070312 L 0.269531 91.070312 Z M 0.269531 42.300781 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="c9f6d37c04">
        <path
          d="M 24.652344 42.300781 C 11.1875 42.300781 0.269531 53.21875 0.269531 66.6875 C 0.269531 80.15625 11.1875 91.070312 24.652344 91.070312 C 38.121094 91.070312 49.039062 80.15625 49.039062 66.6875 C 49.039062 53.21875 38.121094 42.300781 24.652344 42.300781 Z M 24.652344 42.300781 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#a23e6a4e3d)">
      <path
        fill="#fa4a59"
        d="M 36.046875 112.292969 C 36.046875 111.953125 35.980469 111.625 35.851562 111.3125 C 35.722656 110.996094 35.535156 110.71875 35.292969 110.476562 C 35.054688 110.234375 34.777344 110.050781 34.460938 109.917969 C 34.144531 109.789062 33.816406 109.722656 33.476562 109.722656 C 33.136719 109.722656 32.808594 109.789062 32.492188 109.917969 C 32.179688 110.050781 31.902344 110.234375 31.660156 110.476562 C 31.417969 110.71875 31.234375 110.996094 31.101562 111.3125 C 30.972656 111.625 30.90625 111.953125 30.90625 112.292969 C 30.90625 112.636719 30.972656 112.964844 31.101562 113.277344 C 31.234375 113.59375 31.417969 113.871094 31.660156 114.113281 C 31.902344 114.351562 32.179688 114.539062 32.492188 114.667969 C 32.808594 114.800781 33.136719 114.863281 33.476562 114.863281 C 33.816406 114.863281 34.144531 114.800781 34.460938 114.667969 C 34.777344 114.539062 35.054688 114.351562 35.292969 114.113281 C 35.535156 113.871094 35.722656 113.59375 35.851562 113.277344 C 35.980469 112.964844 36.046875 112.636719 36.046875 112.292969 Z M 36.046875 112.292969 "
        fillOpacity={1}
        fillRule="nonzero"
      />
      <path
        fill="#fa4a59"
        d="M 36.046875 112.292969 C 36.046875 111.953125 35.980469 111.625 35.851562 111.3125 C 35.722656 110.996094 35.535156 110.71875 35.292969 110.476562 C 35.054688 110.234375 34.777344 110.050781 34.460938 109.917969 C 34.144531 109.789062 33.816406 109.722656 33.476562 109.722656 C 33.136719 109.722656 32.808594 109.789062 32.492188 109.917969 C 32.179688 110.050781 31.902344 110.234375 31.660156 110.476562 C 31.417969 110.71875 31.234375 110.996094 31.101562 111.3125 C 30.972656 111.625 30.90625 111.953125 30.90625 112.292969 C 30.90625 112.636719 30.972656 112.964844 31.101562 113.277344 C 31.234375 113.59375 31.417969 113.871094 31.660156 114.113281 C 31.902344 114.351562 32.179688 114.539062 32.492188 114.667969 C 32.808594 114.800781 33.136719 114.863281 33.476562 114.863281 C 33.816406 114.863281 34.144531 114.800781 34.460938 114.667969 C 34.777344 114.539062 35.054688 114.351562 35.292969 114.113281 C 35.535156 113.871094 35.722656 113.59375 35.851562 113.277344 C 35.980469 112.964844 36.046875 112.636719 36.046875 112.292969 Z M 36.046875 112.292969 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#0ab174cb97)">
      <path
        fill="#fa4a59"
        d="M 51.703125 112.292969 C 51.703125 111.953125 51.636719 111.625 51.507812 111.3125 C 51.375 110.996094 51.191406 110.71875 50.949219 110.476562 C 50.707031 110.234375 50.429688 110.050781 50.117188 109.917969 C 49.800781 109.789062 49.472656 109.722656 49.132812 109.722656 C 48.792969 109.722656 48.464844 109.789062 48.148438 109.917969 C 47.832031 110.050781 47.554688 110.234375 47.316406 110.476562 C 47.074219 110.71875 46.886719 110.996094 46.757812 111.3125 C 46.628906 111.625 46.5625 111.953125 46.5625 112.292969 C 46.5625 112.636719 46.628906 112.964844 46.757812 113.277344 C 46.886719 113.59375 47.074219 113.871094 47.316406 114.113281 C 47.554688 114.351562 47.832031 114.539062 48.148438 114.667969 C 48.464844 114.800781 48.792969 114.863281 49.132812 114.863281 C 49.472656 114.863281 49.800781 114.800781 50.117188 114.667969 C 50.429688 114.539062 50.707031 114.351562 50.949219 114.113281 C 51.191406 113.871094 51.375 113.59375 51.507812 113.277344 C 51.636719 112.964844 51.703125 112.636719 51.703125 112.292969 Z M 51.703125 112.292969 "
        fillOpacity={1}
        fillRule="nonzero"
      />
      <path
        fill="#fa4a59"
        d="M 51.703125 112.292969 C 51.703125 111.953125 51.636719 111.625 51.507812 111.3125 C 51.375 110.996094 51.191406 110.71875 50.949219 110.476562 C 50.707031 110.234375 50.429688 110.050781 50.117188 109.917969 C 49.800781 109.789062 49.472656 109.722656 49.132812 109.722656 C 48.792969 109.722656 48.464844 109.789062 48.148438 109.917969 C 47.832031 110.050781 47.554688 110.234375 47.316406 110.476562 C 47.074219 110.71875 46.886719 110.996094 46.757812 111.3125 C 46.628906 111.625 46.5625 111.953125 46.5625 112.292969 C 46.5625 112.636719 46.628906 112.964844 46.757812 113.277344 C 46.886719 113.59375 47.074219 113.871094 47.316406 114.113281 C 47.554688 114.351562 47.832031 114.539062 48.148438 114.667969 C 48.464844 114.800781 48.792969 114.863281 49.132812 114.863281 C 49.472656 114.863281 49.800781 114.800781 50.117188 114.667969 C 50.429688 114.539062 50.707031 114.351562 50.949219 114.113281 C 51.191406 113.871094 51.375 113.59375 51.507812 113.277344 C 51.636719 112.964844 51.703125 112.636719 51.703125 112.292969 Z M 51.703125 112.292969 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#c060130503)">
      <path
        fill="#fa4a59"
        d="M 67.402344 112.292969 C 67.402344 111.953125 67.339844 111.625 67.207031 111.3125 C 67.078125 110.996094 66.890625 110.71875 66.652344 110.476562 C 66.410156 110.234375 66.132812 110.050781 65.816406 109.917969 C 65.503906 109.789062 65.175781 109.722656 64.832031 109.722656 C 64.492188 109.722656 64.164062 109.789062 63.851562 109.917969 C 63.535156 110.050781 63.257812 110.234375 63.015625 110.476562 C 62.773438 110.71875 62.589844 110.996094 62.457031 111.3125 C 62.328125 111.625 62.261719 111.953125 62.261719 112.292969 C 62.261719 112.636719 62.328125 112.964844 62.457031 113.277344 C 62.589844 113.59375 62.773438 113.871094 63.015625 114.113281 C 63.257812 114.351562 63.535156 114.539062 63.851562 114.667969 C 64.164062 114.800781 64.492188 114.863281 64.832031 114.863281 C 65.175781 114.863281 65.503906 114.800781 65.816406 114.667969 C 66.132812 114.539062 66.410156 114.351562 66.652344 114.113281 C 66.890625 113.871094 67.078125 113.59375 67.207031 113.277344 C 67.339844 112.964844 67.402344 112.636719 67.402344 112.292969 Z M 67.402344 112.292969 "
        fillOpacity={1}
        fillRule="nonzero"
      />
      <path
        fill="#fa4a59"
        d="M 67.402344 112.292969 C 67.402344 111.953125 67.339844 111.625 67.207031 111.3125 C 67.078125 110.996094 66.890625 110.71875 66.652344 110.476562 C 66.410156 110.234375 66.132812 110.050781 65.816406 109.917969 C 65.503906 109.789062 65.175781 109.722656 64.832031 109.722656 C 64.492188 109.722656 64.164062 109.789062 63.851562 109.917969 C 63.535156 110.050781 63.257812 110.234375 63.015625 110.476562 C 62.773438 110.71875 62.589844 110.996094 62.457031 111.3125 C 62.328125 111.625 62.261719 111.953125 62.261719 112.292969 C 62.261719 112.636719 62.328125 112.964844 62.457031 113.277344 C 62.589844 113.59375 62.773438 113.871094 63.015625 114.113281 C 63.257812 114.351562 63.535156 114.539062 63.851562 114.667969 C 64.164062 114.800781 64.492188 114.863281 64.832031 114.863281 C 65.175781 114.863281 65.503906 114.800781 65.816406 114.667969 C 66.132812 114.539062 66.410156 114.351562 66.652344 114.113281 C 66.890625 113.871094 67.078125 113.59375 67.207031 113.277344 C 67.339844 112.964844 67.402344 112.636719 67.402344 112.292969 Z M 67.402344 112.292969 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#3384588076)">
      <path
        fill="#fa4a59"
        d="M 83.058594 112.292969 C 83.058594 111.953125 82.992188 111.625 82.863281 111.3125 C 82.734375 110.996094 82.546875 110.71875 82.304688 110.476562 C 82.066406 110.234375 81.785156 110.050781 81.472656 109.917969 C 81.15625 109.789062 80.828125 109.722656 80.488281 109.722656 C 80.148438 109.722656 79.820312 109.789062 79.503906 109.917969 C 79.191406 110.050781 78.910156 110.234375 78.671875 110.476562 C 78.429688 110.71875 78.246094 110.996094 78.113281 111.3125 C 77.984375 111.625 77.917969 111.953125 77.917969 112.292969 C 77.917969 112.636719 77.984375 112.964844 78.113281 113.277344 C 78.246094 113.59375 78.429688 113.871094 78.671875 114.113281 C 78.910156 114.351562 79.191406 114.539062 79.503906 114.667969 C 79.820312 114.800781 80.148438 114.863281 80.488281 114.863281 C 80.828125 114.863281 81.15625 114.800781 81.472656 114.667969 C 81.785156 114.539062 82.066406 114.351562 82.304688 114.113281 C 82.546875 113.871094 82.734375 113.59375 82.863281 113.277344 C 82.992188 112.964844 83.058594 112.636719 83.058594 112.292969 Z M 83.058594 112.292969 "
        fillOpacity={1}
        fillRule="nonzero"
      />
      <path
        fill="#fa4a59"
        d="M 83.058594 112.292969 C 83.058594 111.953125 82.992188 111.625 82.863281 111.3125 C 82.734375 110.996094 82.546875 110.71875 82.304688 110.476562 C 82.066406 110.234375 81.785156 110.050781 81.472656 109.917969 C 81.15625 109.789062 80.828125 109.722656 80.488281 109.722656 C 80.148438 109.722656 79.820312 109.789062 79.503906 109.917969 C 79.191406 110.050781 78.910156 110.234375 78.671875 110.476562 C 78.429688 110.71875 78.246094 110.996094 78.113281 111.3125 C 77.984375 111.625 77.917969 111.953125 77.917969 112.292969 C 77.917969 112.636719 77.984375 112.964844 78.113281 113.277344 C 78.246094 113.59375 78.429688 113.871094 78.671875 114.113281 C 78.910156 114.351562 79.191406 114.539062 79.503906 114.667969 C 79.820312 114.800781 80.148438 114.863281 80.488281 114.863281 C 80.828125 114.863281 81.15625 114.800781 81.472656 114.667969 C 81.785156 114.539062 82.066406 114.351562 82.304688 114.113281 C 82.546875 113.871094 82.734375 113.59375 82.863281 113.277344 C 82.992188 112.964844 83.058594 112.636719 83.058594 112.292969 Z M 83.058594 112.292969 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#3538e2b53a)">
      <path
        fill="#fa4a59"
        d="M 36.046875 96.59375 C 36.046875 96.25 35.980469 95.925781 35.851562 95.609375 C 35.722656 95.292969 35.535156 95.015625 35.292969 94.773438 C 35.054688 94.535156 34.777344 94.347656 34.460938 94.21875 C 34.144531 94.085938 33.816406 94.023438 33.476562 94.023438 C 33.136719 94.023438 32.808594 94.085938 32.492188 94.21875 C 32.179688 94.347656 31.902344 94.535156 31.660156 94.773438 C 31.417969 95.015625 31.234375 95.292969 31.101562 95.609375 C 30.972656 95.925781 30.90625 96.25 30.90625 96.59375 C 30.90625 96.933594 30.972656 97.261719 31.101562 97.578125 C 31.234375 97.890625 31.417969 98.167969 31.660156 98.410156 C 31.902344 98.652344 32.179688 98.835938 32.492188 98.96875 C 32.808594 99.097656 33.136719 99.164062 33.476562 99.164062 C 33.816406 99.164062 34.144531 99.097656 34.460938 98.96875 C 34.777344 98.835938 35.054688 98.652344 35.292969 98.410156 C 35.535156 98.167969 35.722656 97.890625 35.851562 97.578125 C 35.980469 97.261719 36.046875 96.933594 36.046875 96.59375 Z M 36.046875 96.59375 "
        fillOpacity={1}
        fillRule="nonzero"
      />
      <path
        fill="#fa4a59"
        d="M 36.046875 96.59375 C 36.046875 96.25 35.980469 95.925781 35.851562 95.609375 C 35.722656 95.292969 35.535156 95.015625 35.292969 94.773438 C 35.054688 94.535156 34.777344 94.347656 34.460938 94.21875 C 34.144531 94.085938 33.816406 94.023438 33.476562 94.023438 C 33.136719 94.023438 32.808594 94.085938 32.492188 94.21875 C 32.179688 94.347656 31.902344 94.535156 31.660156 94.773438 C 31.417969 95.015625 31.234375 95.292969 31.101562 95.609375 C 30.972656 95.925781 30.90625 96.25 30.90625 96.59375 C 30.90625 96.933594 30.972656 97.261719 31.101562 97.578125 C 31.234375 97.890625 31.417969 98.167969 31.660156 98.410156 C 31.902344 98.652344 32.179688 98.835938 32.492188 98.96875 C 32.808594 99.097656 33.136719 99.164062 33.476562 99.164062 C 33.816406 99.164062 34.144531 99.097656 34.460938 98.96875 C 34.777344 98.835938 35.054688 98.652344 35.292969 98.410156 C 35.535156 98.167969 35.722656 97.890625 35.851562 97.578125 C 35.980469 97.261719 36.046875 96.933594 36.046875 96.59375 Z M 36.046875 96.59375 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <path
      fill="#fa4a59"
      d="M 51.703125 96.59375 C 51.703125 96.25 51.636719 95.925781 51.507812 95.609375 C 51.375 95.292969 51.191406 95.015625 50.949219 94.773438 C 50.707031 94.535156 50.429688 94.347656 50.117188 94.21875 C 49.800781 94.085938 49.472656 94.023438 49.132812 94.023438 C 48.792969 94.023438 48.464844 94.085938 48.148438 94.21875 C 47.832031 94.347656 47.554688 94.535156 47.316406 94.773438 C 47.074219 95.015625 46.886719 95.292969 46.757812 95.609375 C 46.628906 95.925781 46.5625 96.25 46.5625 96.59375 C 46.5625 96.933594 46.628906 97.261719 46.757812 97.578125 C 46.886719 97.890625 47.074219 98.167969 47.316406 98.410156 C 47.554688 98.652344 47.832031 98.835938 48.148438 98.96875 C 48.464844 99.097656 48.792969 99.164062 49.132812 99.164062 C 49.472656 99.164062 49.800781 99.097656 50.117188 98.96875 C 50.429688 98.835938 50.707031 98.652344 50.949219 98.410156 C 51.191406 98.167969 51.375 97.890625 51.507812 97.578125 C 51.636719 97.261719 51.703125 96.933594 51.703125 96.59375 Z M 51.703125 96.59375 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#fa4a59"
      d="M 51.703125 96.59375 C 51.703125 96.25 51.636719 95.925781 51.507812 95.609375 C 51.375 95.292969 51.191406 95.015625 50.949219 94.773438 C 50.707031 94.535156 50.429688 94.347656 50.117188 94.21875 C 49.800781 94.085938 49.472656 94.023438 49.132812 94.023438 C 48.792969 94.023438 48.464844 94.085938 48.148438 94.21875 C 47.832031 94.347656 47.554688 94.535156 47.316406 94.773438 C 47.074219 95.015625 46.886719 95.292969 46.757812 95.609375 C 46.628906 95.925781 46.5625 96.25 46.5625 96.59375 C 46.5625 96.933594 46.628906 97.261719 46.757812 97.578125 C 46.886719 97.890625 47.074219 98.167969 47.316406 98.410156 C 47.554688 98.652344 47.832031 98.835938 48.148438 98.96875 C 48.464844 99.097656 48.792969 99.164062 49.132812 99.164062 C 49.472656 99.164062 49.800781 99.097656 50.117188 98.96875 C 50.429688 98.835938 50.707031 98.652344 50.949219 98.410156 C 51.191406 98.167969 51.375 97.890625 51.507812 97.578125 C 51.636719 97.261719 51.703125 96.933594 51.703125 96.59375 Z M 51.703125 96.59375 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#fa4a59"
      d="M 67.402344 96.59375 C 67.402344 96.25 67.339844 95.925781 67.207031 95.609375 C 67.078125 95.292969 66.890625 95.015625 66.652344 94.773438 C 66.410156 94.535156 66.132812 94.347656 65.816406 94.21875 C 65.503906 94.085938 65.175781 94.023438 64.832031 94.023438 C 64.492188 94.023438 64.164062 94.085938 63.851562 94.21875 C 63.535156 94.347656 63.257812 94.535156 63.015625 94.773438 C 62.773438 95.015625 62.589844 95.292969 62.457031 95.609375 C 62.328125 95.925781 62.261719 96.25 62.261719 96.59375 C 62.261719 96.933594 62.328125 97.261719 62.457031 97.578125 C 62.589844 97.890625 62.773438 98.167969 63.015625 98.410156 C 63.257812 98.652344 63.535156 98.835938 63.851562 98.96875 C 64.164062 99.097656 64.492188 99.164062 64.832031 99.164062 C 65.175781 99.164062 65.503906 99.097656 65.816406 98.96875 C 66.132812 98.835938 66.410156 98.652344 66.652344 98.410156 C 66.890625 98.167969 67.078125 97.890625 67.207031 97.578125 C 67.339844 97.261719 67.402344 96.933594 67.402344 96.59375 Z M 67.402344 96.59375 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#fa4a59"
      d="M 67.402344 96.59375 C 67.402344 96.25 67.339844 95.925781 67.207031 95.609375 C 67.078125 95.292969 66.890625 95.015625 66.652344 94.773438 C 66.410156 94.535156 66.132812 94.347656 65.816406 94.21875 C 65.503906 94.085938 65.175781 94.023438 64.832031 94.023438 C 64.492188 94.023438 64.164062 94.085938 63.851562 94.21875 C 63.535156 94.347656 63.257812 94.535156 63.015625 94.773438 C 62.773438 95.015625 62.589844 95.292969 62.457031 95.609375 C 62.328125 95.925781 62.261719 96.25 62.261719 96.59375 C 62.261719 96.933594 62.328125 97.261719 62.457031 97.578125 C 62.589844 97.890625 62.773438 98.167969 63.015625 98.410156 C 63.257812 98.652344 63.535156 98.835938 63.851562 98.96875 C 64.164062 99.097656 64.492188 99.164062 64.832031 99.164062 C 65.175781 99.164062 65.503906 99.097656 65.816406 98.96875 C 66.132812 98.835938 66.410156 98.652344 66.652344 98.410156 C 66.890625 98.167969 67.078125 97.890625 67.207031 97.578125 C 67.339844 97.261719 67.402344 96.933594 67.402344 96.59375 Z M 67.402344 96.59375 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <g clipPath="url(#738b0f9500)">
      <path
        fill="#fa4a59"
        d="M 83.058594 96.59375 C 83.058594 96.25 82.992188 95.925781 82.863281 95.609375 C 82.734375 95.292969 82.546875 95.015625 82.304688 94.773438 C 82.066406 94.535156 81.785156 94.347656 81.472656 94.21875 C 81.15625 94.085938 80.828125 94.023438 80.488281 94.023438 C 80.148438 94.023438 79.820312 94.085938 79.503906 94.21875 C 79.191406 94.347656 78.910156 94.535156 78.671875 94.773438 C 78.429688 95.015625 78.246094 95.292969 78.113281 95.609375 C 77.984375 95.925781 77.917969 96.25 77.917969 96.59375 C 77.917969 96.933594 77.984375 97.261719 78.113281 97.578125 C 78.246094 97.890625 78.429688 98.167969 78.671875 98.410156 C 78.910156 98.652344 79.191406 98.835938 79.503906 98.96875 C 79.820312 99.097656 80.148438 99.164062 80.488281 99.164062 C 80.828125 99.164062 81.15625 99.097656 81.472656 98.96875 C 81.785156 98.835938 82.066406 98.652344 82.304688 98.410156 C 82.546875 98.167969 82.734375 97.890625 82.863281 97.578125 C 82.992188 97.261719 83.058594 96.933594 83.058594 96.59375 Z M 83.058594 96.59375 "
        fillOpacity={1}
        fillRule="nonzero"
      />
      <path
        fill="#fa4a59"
        d="M 83.058594 96.59375 C 83.058594 96.25 82.992188 95.925781 82.863281 95.609375 C 82.734375 95.292969 82.546875 95.015625 82.304688 94.773438 C 82.066406 94.535156 81.785156 94.347656 81.472656 94.21875 C 81.15625 94.085938 80.828125 94.023438 80.488281 94.023438 C 80.148438 94.023438 79.820312 94.085938 79.503906 94.21875 C 79.191406 94.347656 78.910156 94.535156 78.671875 94.773438 C 78.429688 95.015625 78.246094 95.292969 78.113281 95.609375 C 77.984375 95.925781 77.917969 96.25 77.917969 96.59375 C 77.917969 96.933594 77.984375 97.261719 78.113281 97.578125 C 78.246094 97.890625 78.429688 98.167969 78.671875 98.410156 C 78.910156 98.652344 79.191406 98.835938 79.503906 98.96875 C 79.820312 99.097656 80.148438 99.164062 80.488281 99.164062 C 80.828125 99.164062 81.15625 99.097656 81.472656 98.96875 C 81.785156 98.835938 82.066406 98.652344 82.304688 98.410156 C 82.546875 98.167969 82.734375 97.890625 82.863281 97.578125 C 82.992188 97.261719 83.058594 96.933594 83.058594 96.59375 Z M 83.058594 96.59375 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#26208f302e)">
      <g mask="url(#4c37e5d977)">
        <g transform="matrix(1, 0, 0, 1, 30, 77)">
          <g clipPath="url(#d2ce6a8694)">
            <g clipPath="url(#a551eb1a92)">
              <path
                fill="#fa4a59"
                d="M 6.046875 3.9375 C 6.046875 3.597656 5.980469 3.269531 5.851562 2.953125 C 5.722656 2.640625 5.535156 2.363281 5.292969 2.121094 C 5.054688 1.878906 4.777344 1.695312 4.460938 1.5625 C 4.144531 1.433594 3.816406 1.367188 3.476562 1.367188 C 3.136719 1.367188 2.808594 1.433594 2.492188 1.5625 C 2.179688 1.695312 1.902344 1.878906 1.660156 2.121094 C 1.417969 2.363281 1.234375 2.640625 1.101562 2.953125 C 0.972656 3.269531 0.90625 3.597656 0.90625 3.9375 C 0.90625 4.277344 0.972656 4.605469 1.101562 4.921875 C 1.234375 5.234375 1.417969 5.515625 1.660156 5.753906 C 1.902344 5.996094 2.179688 6.183594 2.492188 6.3125 C 2.808594 6.441406 3.136719 6.507812 3.476562 6.507812 C 3.816406 6.507812 4.144531 6.441406 4.460938 6.3125 C 4.777344 6.183594 5.054688 5.996094 5.292969 5.753906 C 5.535156 5.515625 5.722656 5.234375 5.851562 4.921875 C 5.980469 4.605469 6.046875 4.277344 6.046875 3.9375 Z M 6.046875 3.9375 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.046875 3.9375 C 6.046875 3.597656 5.980469 3.269531 5.851562 2.953125 C 5.722656 2.640625 5.535156 2.363281 5.292969 2.121094 C 5.054688 1.878906 4.777344 1.695312 4.460938 1.5625 C 4.144531 1.433594 3.816406 1.367188 3.476562 1.367188 C 3.136719 1.367188 2.808594 1.433594 2.492188 1.5625 C 2.179688 1.695312 1.902344 1.878906 1.660156 2.121094 C 1.417969 2.363281 1.234375 2.640625 1.101562 2.953125 C 0.972656 3.269531 0.90625 3.597656 0.90625 3.9375 C 0.90625 4.277344 0.972656 4.605469 1.101562 4.921875 C 1.234375 5.234375 1.417969 5.515625 1.660156 5.753906 C 1.902344 5.996094 2.179688 6.183594 2.492188 6.3125 C 2.808594 6.441406 3.136719 6.507812 3.476562 6.507812 C 3.816406 6.507812 4.144531 6.441406 4.460938 6.3125 C 4.777344 6.183594 5.054688 5.996094 5.292969 5.753906 C 5.535156 5.515625 5.722656 5.234375 5.851562 4.921875 C 5.980469 4.605469 6.046875 4.277344 6.046875 3.9375 Z M 6.046875 3.9375 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#fc4657abf7)">
      <g transform="matrix(1, 0, 0, 1, 46, 77)">
        <g clipPath="url(#0bfb94c267)">
          <g clipPath="url(#cb07a294ca)">
            <path
              fill="#fa4a59"
              d="M 5.703125 3.9375 C 5.703125 3.597656 5.636719 3.269531 5.507812 2.953125 C 5.375 2.640625 5.191406 2.363281 4.949219 2.121094 C 4.707031 1.878906 4.429688 1.695312 4.117188 1.5625 C 3.800781 1.433594 3.472656 1.367188 3.132812 1.367188 C 2.792969 1.367188 2.464844 1.433594 2.148438 1.5625 C 1.832031 1.695312 1.554688 1.878906 1.316406 2.121094 C 1.074219 2.363281 0.886719 2.640625 0.757812 2.953125 C 0.628906 3.269531 0.5625 3.597656 0.5625 3.9375 C 0.5625 4.277344 0.628906 4.605469 0.757812 4.921875 C 0.886719 5.234375 1.074219 5.515625 1.316406 5.753906 C 1.554688 5.996094 1.832031 6.183594 2.148438 6.3125 C 2.464844 6.441406 2.792969 6.507812 3.132812 6.507812 C 3.472656 6.507812 3.800781 6.441406 4.117188 6.3125 C 4.429688 6.183594 4.707031 5.996094 4.949219 5.753906 C 5.191406 5.515625 5.375 5.234375 5.507812 4.921875 C 5.636719 4.605469 5.703125 4.277344 5.703125 3.9375 Z M 5.703125 3.9375 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.703125 3.9375 C 5.703125 3.597656 5.636719 3.269531 5.507812 2.953125 C 5.375 2.640625 5.191406 2.363281 4.949219 2.121094 C 4.707031 1.878906 4.429688 1.695312 4.117188 1.5625 C 3.800781 1.433594 3.472656 1.367188 3.132812 1.367188 C 2.792969 1.367188 2.464844 1.433594 2.148438 1.5625 C 1.832031 1.695312 1.554688 1.878906 1.316406 2.121094 C 1.074219 2.363281 0.886719 2.640625 0.757812 2.953125 C 0.628906 3.269531 0.5625 3.597656 0.5625 3.9375 C 0.5625 4.277344 0.628906 4.605469 0.757812 4.921875 C 0.886719 5.234375 1.074219 5.515625 1.316406 5.753906 C 1.554688 5.996094 1.832031 6.183594 2.148438 6.3125 C 2.464844 6.441406 2.792969 6.507812 3.132812 6.507812 C 3.472656 6.507812 3.800781 6.441406 4.117188 6.3125 C 4.429688 6.183594 4.707031 5.996094 4.949219 5.753906 C 5.191406 5.515625 5.375 5.234375 5.507812 4.921875 C 5.636719 4.605469 5.703125 4.277344 5.703125 3.9375 Z M 5.703125 3.9375 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#94781beff7)">
      <g transform="matrix(1, 0, 0, 1, 62, 77)">
        <g clipPath="url(#2f160ec581)">
          <g clipPath="url(#2ed1d821af)">
            <path
              fill="#fa4a59"
              d="M 5.402344 3.9375 C 5.402344 3.597656 5.339844 3.269531 5.207031 2.953125 C 5.078125 2.640625 4.890625 2.363281 4.652344 2.121094 C 4.410156 1.878906 4.132812 1.695312 3.816406 1.5625 C 3.503906 1.433594 3.175781 1.367188 2.832031 1.367188 C 2.492188 1.367188 2.164062 1.433594 1.851562 1.5625 C 1.535156 1.695312 1.257812 1.878906 1.015625 2.121094 C 0.773438 2.363281 0.589844 2.640625 0.457031 2.953125 C 0.328125 3.269531 0.261719 3.597656 0.261719 3.9375 C 0.261719 4.277344 0.328125 4.605469 0.457031 4.921875 C 0.589844 5.234375 0.773438 5.515625 1.015625 5.753906 C 1.257812 5.996094 1.535156 6.183594 1.851562 6.3125 C 2.164062 6.441406 2.492188 6.507812 2.832031 6.507812 C 3.175781 6.507812 3.503906 6.441406 3.816406 6.3125 C 4.132812 6.183594 4.410156 5.996094 4.652344 5.753906 C 4.890625 5.515625 5.078125 5.234375 5.207031 4.921875 C 5.339844 4.605469 5.402344 4.277344 5.402344 3.9375 Z M 5.402344 3.9375 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.402344 3.9375 C 5.402344 3.597656 5.339844 3.269531 5.207031 2.953125 C 5.078125 2.640625 4.890625 2.363281 4.652344 2.121094 C 4.410156 1.878906 4.132812 1.695312 3.816406 1.5625 C 3.503906 1.433594 3.175781 1.367188 2.832031 1.367188 C 2.492188 1.367188 2.164062 1.433594 1.851562 1.5625 C 1.535156 1.695312 1.257812 1.878906 1.015625 2.121094 C 0.773438 2.363281 0.589844 2.640625 0.457031 2.953125 C 0.328125 3.269531 0.261719 3.597656 0.261719 3.9375 C 0.261719 4.277344 0.328125 4.605469 0.457031 4.921875 C 0.589844 5.234375 0.773438 5.515625 1.015625 5.753906 C 1.257812 5.996094 1.535156 6.183594 1.851562 6.3125 C 2.164062 6.441406 2.492188 6.507812 2.832031 6.507812 C 3.175781 6.507812 3.503906 6.441406 3.816406 6.3125 C 4.132812 6.183594 4.410156 5.996094 4.652344 5.753906 C 4.890625 5.515625 5.078125 5.234375 5.207031 4.921875 C 5.339844 4.605469 5.402344 4.277344 5.402344 3.9375 Z M 5.402344 3.9375 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#aed6f714c3)">
      <g mask="url(#92132a10f3)">
        <g transform="matrix(1, 0, 0, 1, 77, 77)">
          <g clipPath="url(#74a2670c6c)">
            <g clipPath="url(#1b9d8a0376)">
              <path
                fill="#fa4a59"
                d="M 6.058594 3.9375 C 6.058594 3.597656 5.992188 3.269531 5.863281 2.953125 C 5.734375 2.640625 5.546875 2.363281 5.304688 2.121094 C 5.066406 1.878906 4.785156 1.695312 4.472656 1.5625 C 4.15625 1.433594 3.828125 1.367188 3.488281 1.367188 C 3.148438 1.367188 2.820312 1.433594 2.503906 1.5625 C 2.191406 1.695312 1.910156 1.878906 1.671875 2.121094 C 1.429688 2.363281 1.246094 2.640625 1.113281 2.953125 C 0.984375 3.269531 0.917969 3.597656 0.917969 3.9375 C 0.917969 4.277344 0.984375 4.605469 1.113281 4.921875 C 1.246094 5.234375 1.429688 5.515625 1.671875 5.753906 C 1.910156 5.996094 2.191406 6.183594 2.503906 6.3125 C 2.820312 6.441406 3.148438 6.507812 3.488281 6.507812 C 3.828125 6.507812 4.15625 6.441406 4.472656 6.3125 C 4.785156 6.183594 5.066406 5.996094 5.304688 5.753906 C 5.546875 5.515625 5.734375 5.234375 5.863281 4.921875 C 5.992188 4.605469 6.058594 4.277344 6.058594 3.9375 Z M 6.058594 3.9375 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.058594 3.9375 C 6.058594 3.597656 5.992188 3.269531 5.863281 2.953125 C 5.734375 2.640625 5.546875 2.363281 5.304688 2.121094 C 5.066406 1.878906 4.785156 1.695312 4.472656 1.5625 C 4.15625 1.433594 3.828125 1.367188 3.488281 1.367188 C 3.148438 1.367188 2.820312 1.433594 2.503906 1.5625 C 2.191406 1.695312 1.910156 1.878906 1.671875 2.121094 C 1.429688 2.363281 1.246094 2.640625 1.113281 2.953125 C 0.984375 3.269531 0.917969 3.597656 0.917969 3.9375 C 0.917969 4.277344 0.984375 4.605469 1.113281 4.921875 C 1.246094 5.234375 1.429688 5.515625 1.671875 5.753906 C 1.910156 5.996094 2.191406 6.183594 2.503906 6.3125 C 2.820312 6.441406 3.148438 6.507812 3.488281 6.507812 C 3.828125 6.507812 4.15625 6.441406 4.472656 6.3125 C 4.785156 6.183594 5.066406 5.996094 5.304688 5.753906 C 5.546875 5.515625 5.734375 5.234375 5.863281 4.921875 C 5.992188 4.605469 6.058594 4.277344 6.058594 3.9375 Z M 6.058594 3.9375 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#6d9ba72ef2)">
      <g mask="url(#85c1a523ab)">
        <g transform="matrix(1, 0, 0, 1, 30, 62)">
          <g clipPath="url(#70178cc786)">
            <g clipPath="url(#ed1c7235f7)">
              <path
                fill="#fa4a59"
                d="M 6.046875 3.28125 C 6.046875 2.941406 5.980469 2.613281 5.851562 2.300781 C 5.722656 1.984375 5.535156 1.707031 5.292969 1.464844 C 5.054688 1.222656 4.777344 1.039062 4.460938 0.910156 C 4.144531 0.777344 3.816406 0.710938 3.476562 0.710938 C 3.136719 0.710938 2.808594 0.777344 2.492188 0.910156 C 2.179688 1.039062 1.902344 1.222656 1.660156 1.464844 C 1.417969 1.707031 1.234375 1.984375 1.101562 2.300781 C 0.972656 2.613281 0.90625 2.941406 0.90625 3.28125 C 0.90625 3.625 0.972656 3.953125 1.101562 4.265625 C 1.234375 4.582031 1.417969 4.859375 1.660156 5.101562 C 1.902344 5.339844 2.179688 5.527344 2.492188 5.65625 C 2.808594 5.789062 3.136719 5.851562 3.476562 5.851562 C 3.816406 5.851562 4.144531 5.789062 4.460938 5.65625 C 4.777344 5.527344 5.054688 5.339844 5.292969 5.101562 C 5.535156 4.859375 5.722656 4.582031 5.851562 4.265625 C 5.980469 3.953125 6.046875 3.625 6.046875 3.28125 Z M 6.046875 3.28125 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.046875 3.28125 C 6.046875 2.941406 5.980469 2.613281 5.851562 2.300781 C 5.722656 1.984375 5.535156 1.707031 5.292969 1.464844 C 5.054688 1.222656 4.777344 1.039062 4.460938 0.910156 C 4.144531 0.777344 3.816406 0.710938 3.476562 0.710938 C 3.136719 0.710938 2.808594 0.777344 2.492188 0.910156 C 2.179688 1.039062 1.902344 1.222656 1.660156 1.464844 C 1.417969 1.707031 1.234375 1.984375 1.101562 2.300781 C 0.972656 2.613281 0.90625 2.941406 0.90625 3.28125 C 0.90625 3.625 0.972656 3.953125 1.101562 4.265625 C 1.234375 4.582031 1.417969 4.859375 1.660156 5.101562 C 1.902344 5.339844 2.179688 5.527344 2.492188 5.65625 C 2.808594 5.789062 3.136719 5.851562 3.476562 5.851562 C 3.816406 5.851562 4.144531 5.789062 4.460938 5.65625 C 4.777344 5.527344 5.054688 5.339844 5.292969 5.101562 C 5.535156 4.859375 5.722656 4.582031 5.851562 4.265625 C 5.980469 3.953125 6.046875 3.625 6.046875 3.28125 Z M 6.046875 3.28125 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#2de6655494)">
      <g transform="matrix(1, 0, 0, 1, 46, 62)">
        <g clipPath="url(#9ddf57ee24)">
          <g clipPath="url(#a6e7637ed5)">
            <path
              fill="#fa4a59"
              d="M 5.703125 3.28125 C 5.703125 2.941406 5.636719 2.613281 5.507812 2.300781 C 5.375 1.984375 5.191406 1.707031 4.949219 1.464844 C 4.707031 1.222656 4.429688 1.039062 4.117188 0.910156 C 3.800781 0.777344 3.472656 0.710938 3.132812 0.710938 C 2.792969 0.710938 2.464844 0.777344 2.148438 0.910156 C 1.832031 1.039062 1.554688 1.222656 1.316406 1.464844 C 1.074219 1.707031 0.886719 1.984375 0.757812 2.300781 C 0.628906 2.613281 0.5625 2.941406 0.5625 3.28125 C 0.5625 3.625 0.628906 3.953125 0.757812 4.265625 C 0.886719 4.582031 1.074219 4.859375 1.316406 5.101562 C 1.554688 5.339844 1.832031 5.527344 2.148438 5.65625 C 2.464844 5.789062 2.792969 5.851562 3.132812 5.851562 C 3.472656 5.851562 3.800781 5.789062 4.117188 5.65625 C 4.429688 5.527344 4.707031 5.339844 4.949219 5.101562 C 5.191406 4.859375 5.375 4.582031 5.507812 4.265625 C 5.636719 3.953125 5.703125 3.625 5.703125 3.28125 Z M 5.703125 3.28125 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.703125 3.28125 C 5.703125 2.941406 5.636719 2.613281 5.507812 2.300781 C 5.375 1.984375 5.191406 1.707031 4.949219 1.464844 C 4.707031 1.222656 4.429688 1.039062 4.117188 0.910156 C 3.800781 0.777344 3.472656 0.710938 3.132812 0.710938 C 2.792969 0.710938 2.464844 0.777344 2.148438 0.910156 C 1.832031 1.039062 1.554688 1.222656 1.316406 1.464844 C 1.074219 1.707031 0.886719 1.984375 0.757812 2.300781 C 0.628906 2.613281 0.5625 2.941406 0.5625 3.28125 C 0.5625 3.625 0.628906 3.953125 0.757812 4.265625 C 0.886719 4.582031 1.074219 4.859375 1.316406 5.101562 C 1.554688 5.339844 1.832031 5.527344 2.148438 5.65625 C 2.464844 5.789062 2.792969 5.851562 3.132812 5.851562 C 3.472656 5.851562 3.800781 5.789062 4.117188 5.65625 C 4.429688 5.527344 4.707031 5.339844 4.949219 5.101562 C 5.191406 4.859375 5.375 4.582031 5.507812 4.265625 C 5.636719 3.953125 5.703125 3.625 5.703125 3.28125 Z M 5.703125 3.28125 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#4071ac318b)">
      <g transform="matrix(1, 0, 0, 1, 62, 62)">
        <g clipPath="url(#ecab3c183c)">
          <g clipPath="url(#204aab15ec)">
            <path
              fill="#fa4a59"
              d="M 5.402344 3.28125 C 5.402344 2.941406 5.339844 2.613281 5.207031 2.300781 C 5.078125 1.984375 4.890625 1.707031 4.652344 1.464844 C 4.410156 1.222656 4.132812 1.039062 3.816406 0.910156 C 3.503906 0.777344 3.175781 0.710938 2.832031 0.710938 C 2.492188 0.710938 2.164062 0.777344 1.851562 0.910156 C 1.535156 1.039062 1.257812 1.222656 1.015625 1.464844 C 0.773438 1.707031 0.589844 1.984375 0.457031 2.300781 C 0.328125 2.613281 0.261719 2.941406 0.261719 3.28125 C 0.261719 3.625 0.328125 3.953125 0.457031 4.265625 C 0.589844 4.582031 0.773438 4.859375 1.015625 5.101562 C 1.257812 5.339844 1.535156 5.527344 1.851562 5.65625 C 2.164062 5.789062 2.492188 5.851562 2.832031 5.851562 C 3.175781 5.851562 3.503906 5.789062 3.816406 5.65625 C 4.132812 5.527344 4.410156 5.339844 4.652344 5.101562 C 4.890625 4.859375 5.078125 4.582031 5.207031 4.265625 C 5.339844 3.953125 5.402344 3.625 5.402344 3.28125 Z M 5.402344 3.28125 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.402344 3.28125 C 5.402344 2.941406 5.339844 2.613281 5.207031 2.300781 C 5.078125 1.984375 4.890625 1.707031 4.652344 1.464844 C 4.410156 1.222656 4.132812 1.039062 3.816406 0.910156 C 3.503906 0.777344 3.175781 0.710938 2.832031 0.710938 C 2.492188 0.710938 2.164062 0.777344 1.851562 0.910156 C 1.535156 1.039062 1.257812 1.222656 1.015625 1.464844 C 0.773438 1.707031 0.589844 1.984375 0.457031 2.300781 C 0.328125 2.613281 0.261719 2.941406 0.261719 3.28125 C 0.261719 3.625 0.328125 3.953125 0.457031 4.265625 C 0.589844 4.582031 0.773438 4.859375 1.015625 5.101562 C 1.257812 5.339844 1.535156 5.527344 1.851562 5.65625 C 2.164062 5.789062 2.492188 5.851562 2.832031 5.851562 C 3.175781 5.851562 3.503906 5.789062 3.816406 5.65625 C 4.132812 5.527344 4.410156 5.339844 4.652344 5.101562 C 4.890625 4.859375 5.078125 4.582031 5.207031 4.265625 C 5.339844 3.953125 5.402344 3.625 5.402344 3.28125 Z M 5.402344 3.28125 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#8b568a9321)">
      <g mask="url(#3509e99de6)">
        <g transform="matrix(1, 0, 0, 1, 77, 62)">
          <g clipPath="url(#1ce3202eaf)">
            <g clipPath="url(#c0f54799af)">
              <path
                fill="#fa4a59"
                d="M 6.058594 3.28125 C 6.058594 2.941406 5.992188 2.613281 5.863281 2.300781 C 5.734375 1.984375 5.546875 1.707031 5.304688 1.464844 C 5.066406 1.222656 4.785156 1.039062 4.472656 0.910156 C 4.15625 0.777344 3.828125 0.710938 3.488281 0.710938 C 3.148438 0.710938 2.820312 0.777344 2.503906 0.910156 C 2.191406 1.039062 1.910156 1.222656 1.671875 1.464844 C 1.429688 1.707031 1.246094 1.984375 1.113281 2.300781 C 0.984375 2.613281 0.917969 2.941406 0.917969 3.28125 C 0.917969 3.625 0.984375 3.953125 1.113281 4.265625 C 1.246094 4.582031 1.429688 4.859375 1.671875 5.101562 C 1.910156 5.339844 2.191406 5.527344 2.503906 5.65625 C 2.820312 5.789062 3.148438 5.851562 3.488281 5.851562 C 3.828125 5.851562 4.15625 5.789062 4.472656 5.65625 C 4.785156 5.527344 5.066406 5.339844 5.304688 5.101562 C 5.546875 4.859375 5.734375 4.582031 5.863281 4.265625 C 5.992188 3.953125 6.058594 3.625 6.058594 3.28125 Z M 6.058594 3.28125 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.058594 3.28125 C 6.058594 2.941406 5.992188 2.613281 5.863281 2.300781 C 5.734375 1.984375 5.546875 1.707031 5.304688 1.464844 C 5.066406 1.222656 4.785156 1.039062 4.472656 0.910156 C 4.15625 0.777344 3.828125 0.710938 3.488281 0.710938 C 3.148438 0.710938 2.820312 0.777344 2.503906 0.910156 C 2.191406 1.039062 1.910156 1.222656 1.671875 1.464844 C 1.429688 1.707031 1.246094 1.984375 1.113281 2.300781 C 0.984375 2.613281 0.917969 2.941406 0.917969 3.28125 C 0.917969 3.625 0.984375 3.953125 1.113281 4.265625 C 1.246094 4.582031 1.429688 4.859375 1.671875 5.101562 C 1.910156 5.339844 2.191406 5.527344 2.503906 5.65625 C 2.820312 5.789062 3.148438 5.851562 3.488281 5.851562 C 3.828125 5.851562 4.15625 5.789062 4.472656 5.65625 C 4.785156 5.527344 5.066406 5.339844 5.304688 5.101562 C 5.546875 4.859375 5.734375 4.582031 5.863281 4.265625 C 5.992188 3.953125 6.058594 3.625 6.058594 3.28125 Z M 6.058594 3.28125 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#7ae662c72a)">
      <g mask="url(#f7337b87aa)">
        <g transform="matrix(1, 0, 0, 1, 30, 46)">
          <g clipPath="url(#24e291f153)">
            <g clipPath="url(#9041f42d72)">
              <path
                fill="#fa4a59"
                d="M 6.046875 3.582031 C 6.046875 3.242188 5.980469 2.914062 5.851562 2.597656 C 5.722656 2.28125 5.535156 2.003906 5.292969 1.765625 C 5.054688 1.523438 4.777344 1.335938 4.460938 1.207031 C 4.144531 1.078125 3.816406 1.011719 3.476562 1.011719 C 3.136719 1.011719 2.808594 1.078125 2.492188 1.207031 C 2.179688 1.335938 1.902344 1.523438 1.660156 1.765625 C 1.417969 2.003906 1.234375 2.28125 1.101562 2.597656 C 0.972656 2.914062 0.90625 3.242188 0.90625 3.582031 C 0.90625 3.921875 0.972656 4.25 1.101562 4.566406 C 1.234375 4.878906 1.417969 5.15625 1.660156 5.398438 C 1.902344 5.640625 2.179688 5.824219 2.492188 5.957031 C 2.808594 6.085938 3.136719 6.152344 3.476562 6.152344 C 3.816406 6.152344 4.144531 6.085938 4.460938 5.957031 C 4.777344 5.824219 5.054688 5.640625 5.292969 5.398438 C 5.535156 5.15625 5.722656 4.878906 5.851562 4.566406 C 5.980469 4.25 6.046875 3.921875 6.046875 3.582031 Z M 6.046875 3.582031 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.046875 3.582031 C 6.046875 3.242188 5.980469 2.914062 5.851562 2.597656 C 5.722656 2.28125 5.535156 2.003906 5.292969 1.765625 C 5.054688 1.523438 4.777344 1.335938 4.460938 1.207031 C 4.144531 1.078125 3.816406 1.011719 3.476562 1.011719 C 3.136719 1.011719 2.808594 1.078125 2.492188 1.207031 C 2.179688 1.335938 1.902344 1.523438 1.660156 1.765625 C 1.417969 2.003906 1.234375 2.28125 1.101562 2.597656 C 0.972656 2.914062 0.90625 3.242188 0.90625 3.582031 C 0.90625 3.921875 0.972656 4.25 1.101562 4.566406 C 1.234375 4.878906 1.417969 5.15625 1.660156 5.398438 C 1.902344 5.640625 2.179688 5.824219 2.492188 5.957031 C 2.808594 6.085938 3.136719 6.152344 3.476562 6.152344 C 3.816406 6.152344 4.144531 6.085938 4.460938 5.957031 C 4.777344 5.824219 5.054688 5.640625 5.292969 5.398438 C 5.535156 5.15625 5.722656 4.878906 5.851562 4.566406 C 5.980469 4.25 6.046875 3.921875 6.046875 3.582031 Z M 6.046875 3.582031 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#85da997131)">
      <g transform="matrix(1, 0, 0, 1, 46, 46)">
        <g clipPath="url(#7defff9f99)">
          <g clipPath="url(#3e5278d0c4)">
            <path
              fill="#fa4a59"
              d="M 5.703125 3.582031 C 5.703125 3.242188 5.636719 2.914062 5.507812 2.597656 C 5.375 2.28125 5.191406 2.003906 4.949219 1.765625 C 4.707031 1.523438 4.429688 1.335938 4.117188 1.207031 C 3.800781 1.078125 3.472656 1.011719 3.132812 1.011719 C 2.792969 1.011719 2.464844 1.078125 2.148438 1.207031 C 1.832031 1.335938 1.554688 1.523438 1.316406 1.765625 C 1.074219 2.003906 0.886719 2.28125 0.757812 2.597656 C 0.628906 2.914062 0.5625 3.242188 0.5625 3.582031 C 0.5625 3.921875 0.628906 4.25 0.757812 4.566406 C 0.886719 4.878906 1.074219 5.15625 1.316406 5.398438 C 1.554688 5.640625 1.832031 5.824219 2.148438 5.957031 C 2.464844 6.085938 2.792969 6.152344 3.132812 6.152344 C 3.472656 6.152344 3.800781 6.085938 4.117188 5.957031 C 4.429688 5.824219 4.707031 5.640625 4.949219 5.398438 C 5.191406 5.15625 5.375 4.878906 5.507812 4.566406 C 5.636719 4.25 5.703125 3.921875 5.703125 3.582031 Z M 5.703125 3.582031 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.703125 3.582031 C 5.703125 3.242188 5.636719 2.914062 5.507812 2.597656 C 5.375 2.28125 5.191406 2.003906 4.949219 1.765625 C 4.707031 1.523438 4.429688 1.335938 4.117188 1.207031 C 3.800781 1.078125 3.472656 1.011719 3.132812 1.011719 C 2.792969 1.011719 2.464844 1.078125 2.148438 1.207031 C 1.832031 1.335938 1.554688 1.523438 1.316406 1.765625 C 1.074219 2.003906 0.886719 2.28125 0.757812 2.597656 C 0.628906 2.914062 0.5625 3.242188 0.5625 3.582031 C 0.5625 3.921875 0.628906 4.25 0.757812 4.566406 C 0.886719 4.878906 1.074219 5.15625 1.316406 5.398438 C 1.554688 5.640625 1.832031 5.824219 2.148438 5.957031 C 2.464844 6.085938 2.792969 6.152344 3.132812 6.152344 C 3.472656 6.152344 3.800781 6.085938 4.117188 5.957031 C 4.429688 5.824219 4.707031 5.640625 4.949219 5.398438 C 5.191406 5.15625 5.375 4.878906 5.507812 4.566406 C 5.636719 4.25 5.703125 3.921875 5.703125 3.582031 Z M 5.703125 3.582031 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#94d93ef438)">
      <g transform="matrix(1, 0, 0, 1, 62, 46)">
        <g clipPath="url(#50fd2a3b33)">
          <g clipPath="url(#aaf5ec80cb)">
            <path
              fill="#fa4a59"
              d="M 5.402344 3.582031 C 5.402344 3.242188 5.339844 2.914062 5.207031 2.597656 C 5.078125 2.28125 4.890625 2.003906 4.652344 1.765625 C 4.410156 1.523438 4.132812 1.335938 3.816406 1.207031 C 3.503906 1.078125 3.175781 1.011719 2.832031 1.011719 C 2.492188 1.011719 2.164062 1.078125 1.851562 1.207031 C 1.535156 1.335938 1.257812 1.523438 1.015625 1.765625 C 0.773438 2.003906 0.589844 2.28125 0.457031 2.597656 C 0.328125 2.914062 0.261719 3.242188 0.261719 3.582031 C 0.261719 3.921875 0.328125 4.25 0.457031 4.566406 C 0.589844 4.878906 0.773438 5.15625 1.015625 5.398438 C 1.257812 5.640625 1.535156 5.824219 1.851562 5.957031 C 2.164062 6.085938 2.492188 6.152344 2.832031 6.152344 C 3.175781 6.152344 3.503906 6.085938 3.816406 5.957031 C 4.132812 5.824219 4.410156 5.640625 4.652344 5.398438 C 4.890625 5.15625 5.078125 4.878906 5.207031 4.566406 C 5.339844 4.25 5.402344 3.921875 5.402344 3.582031 Z M 5.402344 3.582031 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.402344 3.582031 C 5.402344 3.242188 5.339844 2.914062 5.207031 2.597656 C 5.078125 2.28125 4.890625 2.003906 4.652344 1.765625 C 4.410156 1.523438 4.132812 1.335938 3.816406 1.207031 C 3.503906 1.078125 3.175781 1.011719 2.832031 1.011719 C 2.492188 1.011719 2.164062 1.078125 1.851562 1.207031 C 1.535156 1.335938 1.257812 1.523438 1.015625 1.765625 C 0.773438 2.003906 0.589844 2.28125 0.457031 2.597656 C 0.328125 2.914062 0.261719 3.242188 0.261719 3.582031 C 0.261719 3.921875 0.328125 4.25 0.457031 4.566406 C 0.589844 4.878906 0.773438 5.15625 1.015625 5.398438 C 1.257812 5.640625 1.535156 5.824219 1.851562 5.957031 C 2.164062 6.085938 2.492188 6.152344 2.832031 6.152344 C 3.175781 6.152344 3.503906 6.085938 3.816406 5.957031 C 4.132812 5.824219 4.410156 5.640625 4.652344 5.398438 C 4.890625 5.15625 5.078125 4.878906 5.207031 4.566406 C 5.339844 4.25 5.402344 3.921875 5.402344 3.582031 Z M 5.402344 3.582031 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#d2854c0e69)">
      <g mask="url(#6a2960b4bd)">
        <g transform="matrix(1, 0, 0, 1, 77, 46)">
          <g clipPath="url(#1965225a9c)">
            <g clipPath="url(#a1c147fd43)">
              <path
                fill="#fa4a59"
                d="M 6.058594 3.582031 C 6.058594 3.242188 5.992188 2.914062 5.863281 2.597656 C 5.734375 2.28125 5.546875 2.003906 5.304688 1.765625 C 5.066406 1.523438 4.785156 1.335938 4.472656 1.207031 C 4.15625 1.078125 3.828125 1.011719 3.488281 1.011719 C 3.148438 1.011719 2.820312 1.078125 2.503906 1.207031 C 2.191406 1.335938 1.910156 1.523438 1.671875 1.765625 C 1.429688 2.003906 1.246094 2.28125 1.113281 2.597656 C 0.984375 2.914062 0.917969 3.242188 0.917969 3.582031 C 0.917969 3.921875 0.984375 4.25 1.113281 4.566406 C 1.246094 4.878906 1.429688 5.15625 1.671875 5.398438 C 1.910156 5.640625 2.191406 5.824219 2.503906 5.957031 C 2.820312 6.085938 3.148438 6.152344 3.488281 6.152344 C 3.828125 6.152344 4.15625 6.085938 4.472656 5.957031 C 4.785156 5.824219 5.066406 5.640625 5.304688 5.398438 C 5.546875 5.15625 5.734375 4.878906 5.863281 4.566406 C 5.992188 4.25 6.058594 3.921875 6.058594 3.582031 Z M 6.058594 3.582031 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.058594 3.582031 C 6.058594 3.242188 5.992188 2.914062 5.863281 2.597656 C 5.734375 2.28125 5.546875 2.003906 5.304688 1.765625 C 5.066406 1.523438 4.785156 1.335938 4.472656 1.207031 C 4.15625 1.078125 3.828125 1.011719 3.488281 1.011719 C 3.148438 1.011719 2.820312 1.078125 2.503906 1.207031 C 2.191406 1.335938 1.910156 1.523438 1.671875 1.765625 C 1.429688 2.003906 1.246094 2.28125 1.113281 2.597656 C 0.984375 2.914062 0.917969 3.242188 0.917969 3.582031 C 0.917969 3.921875 0.984375 4.25 1.113281 4.566406 C 1.246094 4.878906 1.429688 5.15625 1.671875 5.398438 C 1.910156 5.640625 2.191406 5.824219 2.503906 5.957031 C 2.820312 6.085938 3.148438 6.152344 3.488281 6.152344 C 3.828125 6.152344 4.15625 6.085938 4.472656 5.957031 C 4.785156 5.824219 5.066406 5.640625 5.304688 5.398438 C 5.546875 5.15625 5.734375 4.878906 5.863281 4.566406 C 5.992188 4.25 6.058594 3.921875 6.058594 3.582031 Z M 6.058594 3.582031 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#27dae315aa)">
      <g mask="url(#910c423fc2)">
        <g transform="matrix(1, 0, 0, 1, 30, 31)">
          <g clipPath="url(#0d80a67edb)">
            <g clipPath="url(#7a78366ed7)">
              <path
                fill="#fa4a59"
                d="M 6.046875 2.925781 C 6.046875 2.585938 5.980469 2.257812 5.851562 1.941406 C 5.722656 1.628906 5.535156 1.351562 5.292969 1.109375 C 5.054688 0.867188 4.777344 0.683594 4.460938 0.550781 C 4.144531 0.421875 3.816406 0.355469 3.476562 0.355469 C 3.136719 0.355469 2.808594 0.421875 2.492188 0.550781 C 2.179688 0.683594 1.902344 0.867188 1.660156 1.109375 C 1.417969 1.351562 1.234375 1.628906 1.101562 1.941406 C 0.972656 2.257812 0.90625 2.585938 0.90625 2.925781 C 0.90625 3.265625 0.972656 3.59375 1.101562 3.910156 C 1.234375 4.226562 1.417969 4.503906 1.660156 4.742188 C 1.902344 4.984375 2.179688 5.171875 2.492188 5.300781 C 2.808594 5.429688 3.136719 5.496094 3.476562 5.496094 C 3.816406 5.496094 4.144531 5.429688 4.460938 5.300781 C 4.777344 5.171875 5.054688 4.984375 5.292969 4.742188 C 5.535156 4.503906 5.722656 4.226562 5.851562 3.910156 C 5.980469 3.59375 6.046875 3.265625 6.046875 2.925781 Z M 6.046875 2.925781 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.046875 2.925781 C 6.046875 2.585938 5.980469 2.257812 5.851562 1.941406 C 5.722656 1.628906 5.535156 1.351562 5.292969 1.109375 C 5.054688 0.867188 4.777344 0.683594 4.460938 0.550781 C 4.144531 0.421875 3.816406 0.355469 3.476562 0.355469 C 3.136719 0.355469 2.808594 0.421875 2.492188 0.550781 C 2.179688 0.683594 1.902344 0.867188 1.660156 1.109375 C 1.417969 1.351562 1.234375 1.628906 1.101562 1.941406 C 0.972656 2.257812 0.90625 2.585938 0.90625 2.925781 C 0.90625 3.265625 0.972656 3.59375 1.101562 3.910156 C 1.234375 4.226562 1.417969 4.503906 1.660156 4.742188 C 1.902344 4.984375 2.179688 5.171875 2.492188 5.300781 C 2.808594 5.429688 3.136719 5.496094 3.476562 5.496094 C 3.816406 5.496094 4.144531 5.429688 4.460938 5.300781 C 4.777344 5.171875 5.054688 4.984375 5.292969 4.742188 C 5.535156 4.503906 5.722656 4.226562 5.851562 3.910156 C 5.980469 3.59375 6.046875 3.265625 6.046875 2.925781 Z M 6.046875 2.925781 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#f649bcc856)">
      <g transform="matrix(1, 0, 0, 1, 46, 31)">
        <g clipPath="url(#760c35f666)">
          <g clipPath="url(#bfda6a6545)">
            <path
              fill="#fa4a59"
              d="M 5.703125 2.925781 C 5.703125 2.585938 5.636719 2.257812 5.507812 1.941406 C 5.375 1.628906 5.191406 1.351562 4.949219 1.109375 C 4.707031 0.867188 4.429688 0.683594 4.117188 0.550781 C 3.800781 0.421875 3.472656 0.355469 3.132812 0.355469 C 2.792969 0.355469 2.464844 0.421875 2.148438 0.550781 C 1.832031 0.683594 1.554688 0.867188 1.316406 1.109375 C 1.074219 1.351562 0.886719 1.628906 0.757812 1.941406 C 0.628906 2.257812 0.5625 2.585938 0.5625 2.925781 C 0.5625 3.265625 0.628906 3.59375 0.757812 3.910156 C 0.886719 4.226562 1.074219 4.503906 1.316406 4.742188 C 1.554688 4.984375 1.832031 5.171875 2.148438 5.300781 C 2.464844 5.429688 2.792969 5.496094 3.132812 5.496094 C 3.472656 5.496094 3.800781 5.429688 4.117188 5.300781 C 4.429688 5.171875 4.707031 4.984375 4.949219 4.742188 C 5.191406 4.503906 5.375 4.226562 5.507812 3.910156 C 5.636719 3.59375 5.703125 3.265625 5.703125 2.925781 Z M 5.703125 2.925781 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.703125 2.925781 C 5.703125 2.585938 5.636719 2.257812 5.507812 1.941406 C 5.375 1.628906 5.191406 1.351562 4.949219 1.109375 C 4.707031 0.867188 4.429688 0.683594 4.117188 0.550781 C 3.800781 0.421875 3.472656 0.355469 3.132812 0.355469 C 2.792969 0.355469 2.464844 0.421875 2.148438 0.550781 C 1.832031 0.683594 1.554688 0.867188 1.316406 1.109375 C 1.074219 1.351562 0.886719 1.628906 0.757812 1.941406 C 0.628906 2.257812 0.5625 2.585938 0.5625 2.925781 C 0.5625 3.265625 0.628906 3.59375 0.757812 3.910156 C 0.886719 4.226562 1.074219 4.503906 1.316406 4.742188 C 1.554688 4.984375 1.832031 5.171875 2.148438 5.300781 C 2.464844 5.429688 2.792969 5.496094 3.132812 5.496094 C 3.472656 5.496094 3.800781 5.429688 4.117188 5.300781 C 4.429688 5.171875 4.707031 4.984375 4.949219 4.742188 C 5.191406 4.503906 5.375 4.226562 5.507812 3.910156 C 5.636719 3.59375 5.703125 3.265625 5.703125 2.925781 Z M 5.703125 2.925781 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#e3f4059d0c)">
      <g transform="matrix(1, 0, 0, 1, 62, 31)">
        <g clipPath="url(#58f6f13551)">
          <g clipPath="url(#ac3dbc5c47)">
            <path
              fill="#fa4a59"
              d="M 5.402344 2.925781 C 5.402344 2.585938 5.339844 2.257812 5.207031 1.941406 C 5.078125 1.628906 4.890625 1.351562 4.652344 1.109375 C 4.410156 0.867188 4.132812 0.683594 3.816406 0.550781 C 3.503906 0.421875 3.175781 0.355469 2.832031 0.355469 C 2.492188 0.355469 2.164062 0.421875 1.851562 0.550781 C 1.535156 0.683594 1.257812 0.867188 1.015625 1.109375 C 0.773438 1.351562 0.589844 1.628906 0.457031 1.941406 C 0.328125 2.257812 0.261719 2.585938 0.261719 2.925781 C 0.261719 3.265625 0.328125 3.59375 0.457031 3.910156 C 0.589844 4.226562 0.773438 4.503906 1.015625 4.742188 C 1.257812 4.984375 1.535156 5.171875 1.851562 5.300781 C 2.164062 5.429688 2.492188 5.496094 2.832031 5.496094 C 3.175781 5.496094 3.503906 5.429688 3.816406 5.300781 C 4.132812 5.171875 4.410156 4.984375 4.652344 4.742188 C 4.890625 4.503906 5.078125 4.226562 5.207031 3.910156 C 5.339844 3.59375 5.402344 3.265625 5.402344 2.925781 Z M 5.402344 2.925781 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.402344 2.925781 C 5.402344 2.585938 5.339844 2.257812 5.207031 1.941406 C 5.078125 1.628906 4.890625 1.351562 4.652344 1.109375 C 4.410156 0.867188 4.132812 0.683594 3.816406 0.550781 C 3.503906 0.421875 3.175781 0.355469 2.832031 0.355469 C 2.492188 0.355469 2.164062 0.421875 1.851562 0.550781 C 1.535156 0.683594 1.257812 0.867188 1.015625 1.109375 C 0.773438 1.351562 0.589844 1.628906 0.457031 1.941406 C 0.328125 2.257812 0.261719 2.585938 0.261719 2.925781 C 0.261719 3.265625 0.328125 3.59375 0.457031 3.910156 C 0.589844 4.226562 0.773438 4.503906 1.015625 4.742188 C 1.257812 4.984375 1.535156 5.171875 1.851562 5.300781 C 2.164062 5.429688 2.492188 5.496094 2.832031 5.496094 C 3.175781 5.496094 3.503906 5.429688 3.816406 5.300781 C 4.132812 5.171875 4.410156 4.984375 4.652344 4.742188 C 4.890625 4.503906 5.078125 4.226562 5.207031 3.910156 C 5.339844 3.59375 5.402344 3.265625 5.402344 2.925781 Z M 5.402344 2.925781 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#5e023e15b4)">
      <g mask="url(#5ad1394927)">
        <g transform="matrix(1, 0, 0, 1, 77, 31)">
          <g clipPath="url(#9739375c2d)">
            <g clipPath="url(#a59ef5c9d6)">
              <path
                fill="#fa4a59"
                d="M 6.058594 2.925781 C 6.058594 2.585938 5.992188 2.257812 5.863281 1.941406 C 5.734375 1.628906 5.546875 1.351562 5.304688 1.109375 C 5.066406 0.867188 4.785156 0.683594 4.472656 0.550781 C 4.15625 0.421875 3.828125 0.355469 3.488281 0.355469 C 3.148438 0.355469 2.820312 0.421875 2.503906 0.550781 C 2.191406 0.683594 1.910156 0.867188 1.671875 1.109375 C 1.429688 1.351562 1.246094 1.628906 1.113281 1.941406 C 0.984375 2.257812 0.917969 2.585938 0.917969 2.925781 C 0.917969 3.265625 0.984375 3.59375 1.113281 3.910156 C 1.246094 4.226562 1.429688 4.503906 1.671875 4.742188 C 1.910156 4.984375 2.191406 5.171875 2.503906 5.300781 C 2.820312 5.429688 3.148438 5.496094 3.488281 5.496094 C 3.828125 5.496094 4.15625 5.429688 4.472656 5.300781 C 4.785156 5.171875 5.066406 4.984375 5.304688 4.742188 C 5.546875 4.503906 5.734375 4.226562 5.863281 3.910156 C 5.992188 3.59375 6.058594 3.265625 6.058594 2.925781 Z M 6.058594 2.925781 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.058594 2.925781 C 6.058594 2.585938 5.992188 2.257812 5.863281 1.941406 C 5.734375 1.628906 5.546875 1.351562 5.304688 1.109375 C 5.066406 0.867188 4.785156 0.683594 4.472656 0.550781 C 4.15625 0.421875 3.828125 0.355469 3.488281 0.355469 C 3.148438 0.355469 2.820312 0.421875 2.503906 0.550781 C 2.191406 0.683594 1.910156 0.867188 1.671875 1.109375 C 1.429688 1.351562 1.246094 1.628906 1.113281 1.941406 C 0.984375 2.257812 0.917969 2.585938 0.917969 2.925781 C 0.917969 3.265625 0.984375 3.59375 1.113281 3.910156 C 1.246094 4.226562 1.429688 4.503906 1.671875 4.742188 C 1.910156 4.984375 2.191406 5.171875 2.503906 5.300781 C 2.820312 5.429688 3.148438 5.496094 3.488281 5.496094 C 3.828125 5.496094 4.15625 5.429688 4.472656 5.300781 C 4.785156 5.171875 5.066406 4.984375 5.304688 4.742188 C 5.546875 4.503906 5.734375 4.226562 5.863281 3.910156 C 5.992188 3.59375 6.058594 3.265625 6.058594 2.925781 Z M 6.058594 2.925781 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#8d59e649df)">
      <g mask="url(#baf9bc3e65)">
        <g transform="matrix(1, 0, 0, 1, 30, 15)">
          <g clipPath="url(#f3711ec276)">
            <g clipPath="url(#eb50a3b8eb)">
              <path
                fill="#fa4a59"
                d="M 6.046875 3.273438 C 6.046875 2.929688 5.980469 2.601562 5.851562 2.289062 C 5.722656 1.972656 5.535156 1.695312 5.292969 1.453125 C 5.054688 1.214844 4.777344 1.027344 4.460938 0.898438 C 4.144531 0.765625 3.816406 0.703125 3.476562 0.703125 C 3.136719 0.703125 2.808594 0.765625 2.492188 0.898438 C 2.179688 1.027344 1.902344 1.214844 1.660156 1.453125 C 1.417969 1.695312 1.234375 1.972656 1.101562 2.289062 C 0.972656 2.601562 0.90625 2.929688 0.90625 3.273438 C 0.90625 3.613281 0.972656 3.941406 1.101562 4.253906 C 1.234375 4.570312 1.417969 4.847656 1.660156 5.089844 C 1.902344 5.332031 2.179688 5.515625 2.492188 5.644531 C 2.808594 5.777344 3.136719 5.84375 3.476562 5.84375 C 3.816406 5.84375 4.144531 5.777344 4.460938 5.644531 C 4.777344 5.515625 5.054688 5.332031 5.292969 5.089844 C 5.535156 4.847656 5.722656 4.570312 5.851562 4.253906 C 5.980469 3.941406 6.046875 3.613281 6.046875 3.273438 Z M 6.046875 3.273438 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.046875 3.273438 C 6.046875 2.929688 5.980469 2.601562 5.851562 2.289062 C 5.722656 1.972656 5.535156 1.695312 5.292969 1.453125 C 5.054688 1.214844 4.777344 1.027344 4.460938 0.898438 C 4.144531 0.765625 3.816406 0.703125 3.476562 0.703125 C 3.136719 0.703125 2.808594 0.765625 2.492188 0.898438 C 2.179688 1.027344 1.902344 1.214844 1.660156 1.453125 C 1.417969 1.695312 1.234375 1.972656 1.101562 2.289062 C 0.972656 2.601562 0.90625 2.929688 0.90625 3.273438 C 0.90625 3.613281 0.972656 3.941406 1.101562 4.253906 C 1.234375 4.570312 1.417969 4.847656 1.660156 5.089844 C 1.902344 5.332031 2.179688 5.515625 2.492188 5.644531 C 2.808594 5.777344 3.136719 5.84375 3.476562 5.84375 C 3.816406 5.84375 4.144531 5.777344 4.460938 5.644531 C 4.777344 5.515625 5.054688 5.332031 5.292969 5.089844 C 5.535156 4.847656 5.722656 4.570312 5.851562 4.253906 C 5.980469 3.941406 6.046875 3.613281 6.046875 3.273438 Z M 6.046875 3.273438 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#f0453d47d6)">
      <g transform="matrix(1, 0, 0, 1, 46, 15)">
        <g clipPath="url(#c48eb53b9b)">
          <g clipPath="url(#e14aaca5fd)">
            <path
              fill="#fa4a59"
              d="M 5.703125 3.273438 C 5.703125 2.929688 5.636719 2.601562 5.507812 2.289062 C 5.375 1.972656 5.191406 1.695312 4.949219 1.453125 C 4.707031 1.214844 4.429688 1.027344 4.117188 0.898438 C 3.800781 0.765625 3.472656 0.703125 3.132812 0.703125 C 2.792969 0.703125 2.464844 0.765625 2.148438 0.898438 C 1.832031 1.027344 1.554688 1.214844 1.316406 1.453125 C 1.074219 1.695312 0.886719 1.972656 0.757812 2.289062 C 0.628906 2.601562 0.5625 2.929688 0.5625 3.273438 C 0.5625 3.613281 0.628906 3.941406 0.757812 4.253906 C 0.886719 4.570312 1.074219 4.847656 1.316406 5.089844 C 1.554688 5.332031 1.832031 5.515625 2.148438 5.644531 C 2.464844 5.777344 2.792969 5.84375 3.132812 5.84375 C 3.472656 5.84375 3.800781 5.777344 4.117188 5.644531 C 4.429688 5.515625 4.707031 5.332031 4.949219 5.089844 C 5.191406 4.847656 5.375 4.570312 5.507812 4.253906 C 5.636719 3.941406 5.703125 3.613281 5.703125 3.273438 Z M 5.703125 3.273438 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.703125 3.273438 C 5.703125 2.929688 5.636719 2.601562 5.507812 2.289062 C 5.375 1.972656 5.191406 1.695312 4.949219 1.453125 C 4.707031 1.214844 4.429688 1.027344 4.117188 0.898438 C 3.800781 0.765625 3.472656 0.703125 3.132812 0.703125 C 2.792969 0.703125 2.464844 0.765625 2.148438 0.898438 C 1.832031 1.027344 1.554688 1.214844 1.316406 1.453125 C 1.074219 1.695312 0.886719 1.972656 0.757812 2.289062 C 0.628906 2.601562 0.5625 2.929688 0.5625 3.273438 C 0.5625 3.613281 0.628906 3.941406 0.757812 4.253906 C 0.886719 4.570312 1.074219 4.847656 1.316406 5.089844 C 1.554688 5.332031 1.832031 5.515625 2.148438 5.644531 C 2.464844 5.777344 2.792969 5.84375 3.132812 5.84375 C 3.472656 5.84375 3.800781 5.777344 4.117188 5.644531 C 4.429688 5.515625 4.707031 5.332031 4.949219 5.089844 C 5.191406 4.847656 5.375 4.570312 5.507812 4.253906 C 5.636719 3.941406 5.703125 3.613281 5.703125 3.273438 Z M 5.703125 3.273438 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g mask="url(#8dd8736b06)">
      <g transform="matrix(1, 0, 0, 1, 62, 15)">
        <g clipPath="url(#c4df14e184)">
          <g clipPath="url(#4eae291f83)">
            <path
              fill="#fa4a59"
              d="M 5.402344 3.273438 C 5.402344 2.929688 5.339844 2.601562 5.207031 2.289062 C 5.078125 1.972656 4.890625 1.695312 4.652344 1.453125 C 4.410156 1.214844 4.132812 1.027344 3.816406 0.898438 C 3.503906 0.765625 3.175781 0.703125 2.832031 0.703125 C 2.492188 0.703125 2.164062 0.765625 1.851562 0.898438 C 1.535156 1.027344 1.257812 1.214844 1.015625 1.453125 C 0.773438 1.695312 0.589844 1.972656 0.457031 2.289062 C 0.328125 2.601562 0.261719 2.929688 0.261719 3.273438 C 0.261719 3.613281 0.328125 3.941406 0.457031 4.253906 C 0.589844 4.570312 0.773438 4.847656 1.015625 5.089844 C 1.257812 5.332031 1.535156 5.515625 1.851562 5.644531 C 2.164062 5.777344 2.492188 5.84375 2.832031 5.84375 C 3.175781 5.84375 3.503906 5.777344 3.816406 5.644531 C 4.132812 5.515625 4.410156 5.332031 4.652344 5.089844 C 4.890625 4.847656 5.078125 4.570312 5.207031 4.253906 C 5.339844 3.941406 5.402344 3.613281 5.402344 3.273438 Z M 5.402344 3.273438 "
              fillOpacity={1}
              fillRule="nonzero"
            />
            <path
              fill="#fa4a59"
              d="M 5.402344 3.273438 C 5.402344 2.929688 5.339844 2.601562 5.207031 2.289062 C 5.078125 1.972656 4.890625 1.695312 4.652344 1.453125 C 4.410156 1.214844 4.132812 1.027344 3.816406 0.898438 C 3.503906 0.765625 3.175781 0.703125 2.832031 0.703125 C 2.492188 0.703125 2.164062 0.765625 1.851562 0.898438 C 1.535156 1.027344 1.257812 1.214844 1.015625 1.453125 C 0.773438 1.695312 0.589844 1.972656 0.457031 2.289062 C 0.328125 2.601562 0.261719 2.929688 0.261719 3.273438 C 0.261719 3.613281 0.328125 3.941406 0.457031 4.253906 C 0.589844 4.570312 0.773438 4.847656 1.015625 5.089844 C 1.257812 5.332031 1.535156 5.515625 1.851562 5.644531 C 2.164062 5.777344 2.492188 5.84375 2.832031 5.84375 C 3.175781 5.84375 3.503906 5.777344 3.816406 5.644531 C 4.132812 5.515625 4.410156 5.332031 4.652344 5.089844 C 4.890625 4.847656 5.078125 4.570312 5.207031 4.253906 C 5.339844 3.941406 5.402344 3.613281 5.402344 3.273438 Z M 5.402344 3.273438 "
              fillOpacity={1}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#c4e652f9b0)">
      <g mask="url(#af37cda519)">
        <g transform="matrix(1, 0, 0, 1, 77, 15)">
          <g clipPath="url(#b3107aa31b)">
            <g clipPath="url(#945d376f4c)">
              <path
                fill="#fa4a59"
                d="M 6.058594 3.273438 C 6.058594 2.929688 5.992188 2.601562 5.863281 2.289062 C 5.734375 1.972656 5.546875 1.695312 5.304688 1.453125 C 5.066406 1.214844 4.785156 1.027344 4.472656 0.898438 C 4.15625 0.765625 3.828125 0.703125 3.488281 0.703125 C 3.148438 0.703125 2.820312 0.765625 2.503906 0.898438 C 2.191406 1.027344 1.910156 1.214844 1.671875 1.453125 C 1.429688 1.695312 1.246094 1.972656 1.113281 2.289062 C 0.984375 2.601562 0.917969 2.929688 0.917969 3.273438 C 0.917969 3.613281 0.984375 3.941406 1.113281 4.253906 C 1.246094 4.570312 1.429688 4.847656 1.671875 5.089844 C 1.910156 5.332031 2.191406 5.515625 2.503906 5.644531 C 2.820312 5.777344 3.148438 5.84375 3.488281 5.84375 C 3.828125 5.84375 4.15625 5.777344 4.472656 5.644531 C 4.785156 5.515625 5.066406 5.332031 5.304688 5.089844 C 5.546875 4.847656 5.734375 4.570312 5.863281 4.253906 C 5.992188 3.941406 6.058594 3.613281 6.058594 3.273438 Z M 6.058594 3.273438 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.058594 3.273438 C 6.058594 2.929688 5.992188 2.601562 5.863281 2.289062 C 5.734375 1.972656 5.546875 1.695312 5.304688 1.453125 C 5.066406 1.214844 4.785156 1.027344 4.472656 0.898438 C 4.15625 0.765625 3.828125 0.703125 3.488281 0.703125 C 3.148438 0.703125 2.820312 0.765625 2.503906 0.898438 C 2.191406 1.027344 1.910156 1.214844 1.671875 1.453125 C 1.429688 1.695312 1.246094 1.972656 1.113281 2.289062 C 0.984375 2.601562 0.917969 2.929688 0.917969 3.273438 C 0.917969 3.613281 0.984375 3.941406 1.113281 4.253906 C 1.246094 4.570312 1.429688 4.847656 1.671875 5.089844 C 1.910156 5.332031 2.191406 5.515625 2.503906 5.644531 C 2.820312 5.777344 3.148438 5.84375 3.488281 5.84375 C 3.828125 5.84375 4.15625 5.777344 4.472656 5.644531 C 4.785156 5.515625 5.066406 5.332031 5.304688 5.089844 C 5.546875 4.847656 5.734375 4.570312 5.863281 4.253906 C 5.992188 3.941406 6.058594 3.613281 6.058594 3.273438 Z M 6.058594 3.273438 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#edafe29266)">
      <g mask="url(#a4e0314c57)">
        <g transform="matrix(1, 0, 0, 1, 30, 0)">
          <g clipPath="url(#4a874002f7)">
            <g clipPath="url(#65facf98ab)">
              <path
                fill="#fa4a59"
                d="M 6.046875 2.570312 C 6.046875 2.230469 5.980469 1.902344 5.851562 1.585938 C 5.722656 1.273438 5.535156 0.992188 5.292969 0.753906 C 5.054688 0.511719 4.777344 0.324219 4.460938 0.195312 C 4.144531 0.0664062 3.816406 0 3.476562 0 C 3.136719 0 2.808594 0.0664062 2.492188 0.195312 C 2.179688 0.324219 1.902344 0.511719 1.660156 0.753906 C 1.417969 0.992188 1.234375 1.273438 1.101562 1.585938 C 0.972656 1.902344 0.90625 2.230469 0.90625 2.570312 C 0.90625 2.910156 0.972656 3.238281 1.101562 3.554688 C 1.234375 3.867188 1.417969 4.148438 1.660156 4.386719 C 1.902344 4.628906 2.179688 4.8125 2.492188 4.945312 C 2.808594 5.074219 3.136719 5.140625 3.476562 5.140625 C 3.816406 5.140625 4.144531 5.074219 4.460938 4.945312 C 4.777344 4.8125 5.054688 4.628906 5.292969 4.386719 C 5.535156 4.148438 5.722656 3.867188 5.851562 3.554688 C 5.980469 3.238281 6.046875 2.910156 6.046875 2.570312 Z M 6.046875 2.570312 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.046875 2.570312 C 6.046875 2.230469 5.980469 1.902344 5.851562 1.585938 C 5.722656 1.273438 5.535156 0.992188 5.292969 0.753906 C 5.054688 0.511719 4.777344 0.324219 4.460938 0.195312 C 4.144531 0.0664062 3.816406 0 3.476562 0 C 3.136719 0 2.808594 0.0664062 2.492188 0.195312 C 2.179688 0.324219 1.902344 0.511719 1.660156 0.753906 C 1.417969 0.992188 1.234375 1.273438 1.101562 1.585938 C 0.972656 1.902344 0.90625 2.230469 0.90625 2.570312 C 0.90625 2.910156 0.972656 3.238281 1.101562 3.554688 C 1.234375 3.867188 1.417969 4.148438 1.660156 4.386719 C 1.902344 4.628906 2.179688 4.8125 2.492188 4.945312 C 2.808594 5.074219 3.136719 5.140625 3.476562 5.140625 C 3.816406 5.140625 4.144531 5.074219 4.460938 4.945312 C 4.777344 4.8125 5.054688 4.628906 5.292969 4.386719 C 5.535156 4.148438 5.722656 3.867188 5.851562 3.554688 C 5.980469 3.238281 6.046875 2.910156 6.046875 2.570312 Z M 6.046875 2.570312 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#3f48fa6377)">
      <g mask="url(#15f3784352)">
        <g transform="matrix(1, 0, 0, 1, 46, 0)">
          <g clipPath="url(#569549ace4)">
            <g clipPath="url(#bf514a24ae)">
              <path
                fill="#fa4a59"
                d="M 5.703125 2.570312 C 5.703125 2.230469 5.636719 1.902344 5.507812 1.585938 C 5.375 1.273438 5.191406 0.992188 4.949219 0.753906 C 4.707031 0.511719 4.429688 0.324219 4.117188 0.195312 C 3.800781 0.0664062 3.472656 0 3.132812 0 C 2.792969 0 2.464844 0.0664062 2.148438 0.195312 C 1.832031 0.324219 1.554688 0.511719 1.316406 0.753906 C 1.074219 0.992188 0.886719 1.273438 0.757812 1.585938 C 0.628906 1.902344 0.5625 2.230469 0.5625 2.570312 C 0.5625 2.910156 0.628906 3.238281 0.757812 3.554688 C 0.886719 3.867188 1.074219 4.148438 1.316406 4.386719 C 1.554688 4.628906 1.832031 4.8125 2.148438 4.945312 C 2.464844 5.074219 2.792969 5.140625 3.132812 5.140625 C 3.472656 5.140625 3.800781 5.074219 4.117188 4.945312 C 4.429688 4.8125 4.707031 4.628906 4.949219 4.386719 C 5.191406 4.148438 5.375 3.867188 5.507812 3.554688 C 5.636719 3.238281 5.703125 2.910156 5.703125 2.570312 Z M 5.703125 2.570312 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 5.703125 2.570312 C 5.703125 2.230469 5.636719 1.902344 5.507812 1.585938 C 5.375 1.273438 5.191406 0.992188 4.949219 0.753906 C 4.707031 0.511719 4.429688 0.324219 4.117188 0.195312 C 3.800781 0.0664062 3.472656 0 3.132812 0 C 2.792969 0 2.464844 0.0664062 2.148438 0.195312 C 1.832031 0.324219 1.554688 0.511719 1.316406 0.753906 C 1.074219 0.992188 0.886719 1.273438 0.757812 1.585938 C 0.628906 1.902344 0.5625 2.230469 0.5625 2.570312 C 0.5625 2.910156 0.628906 3.238281 0.757812 3.554688 C 0.886719 3.867188 1.074219 4.148438 1.316406 4.386719 C 1.554688 4.628906 1.832031 4.8125 2.148438 4.945312 C 2.464844 5.074219 2.792969 5.140625 3.132812 5.140625 C 3.472656 5.140625 3.800781 5.074219 4.117188 4.945312 C 4.429688 4.8125 4.707031 4.628906 4.949219 4.386719 C 5.191406 4.148438 5.375 3.867188 5.507812 3.554688 C 5.636719 3.238281 5.703125 2.910156 5.703125 2.570312 Z M 5.703125 2.570312 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#9cda24ebbd)">
      <g mask="url(#ed1d87fe3c)">
        <g transform="matrix(1, 0, 0, 1, 62, 0)">
          <g clipPath="url(#3327153ac1)">
            <g clipPath="url(#4f19ff5e25)">
              <path
                fill="#fa4a59"
                d="M 5.402344 2.570312 C 5.402344 2.230469 5.339844 1.902344 5.207031 1.585938 C 5.078125 1.273438 4.890625 0.992188 4.652344 0.753906 C 4.410156 0.511719 4.132812 0.324219 3.816406 0.195312 C 3.503906 0.0664062 3.175781 0 2.832031 0 C 2.492188 0 2.164062 0.0664062 1.851562 0.195312 C 1.535156 0.324219 1.257812 0.511719 1.015625 0.753906 C 0.773438 0.992188 0.589844 1.273438 0.457031 1.585938 C 0.328125 1.902344 0.261719 2.230469 0.261719 2.570312 C 0.261719 2.910156 0.328125 3.238281 0.457031 3.554688 C 0.589844 3.867188 0.773438 4.148438 1.015625 4.386719 C 1.257812 4.628906 1.535156 4.8125 1.851562 4.945312 C 2.164062 5.074219 2.492188 5.140625 2.832031 5.140625 C 3.175781 5.140625 3.503906 5.074219 3.816406 4.945312 C 4.132812 4.8125 4.410156 4.628906 4.652344 4.386719 C 4.890625 4.148438 5.078125 3.867188 5.207031 3.554688 C 5.339844 3.238281 5.402344 2.910156 5.402344 2.570312 Z M 5.402344 2.570312 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 5.402344 2.570312 C 5.402344 2.230469 5.339844 1.902344 5.207031 1.585938 C 5.078125 1.273438 4.890625 0.992188 4.652344 0.753906 C 4.410156 0.511719 4.132812 0.324219 3.816406 0.195312 C 3.503906 0.0664062 3.175781 0 2.832031 0 C 2.492188 0 2.164062 0.0664062 1.851562 0.195312 C 1.535156 0.324219 1.257812 0.511719 1.015625 0.753906 C 0.773438 0.992188 0.589844 1.273438 0.457031 1.585938 C 0.328125 1.902344 0.261719 2.230469 0.261719 2.570312 C 0.261719 2.910156 0.328125 3.238281 0.457031 3.554688 C 0.589844 3.867188 0.773438 4.148438 1.015625 4.386719 C 1.257812 4.628906 1.535156 4.8125 1.851562 4.945312 C 2.164062 5.074219 2.492188 5.140625 2.832031 5.140625 C 3.175781 5.140625 3.503906 5.074219 3.816406 4.945312 C 4.132812 4.8125 4.410156 4.628906 4.652344 4.386719 C 4.890625 4.148438 5.078125 3.867188 5.207031 3.554688 C 5.339844 3.238281 5.402344 2.910156 5.402344 2.570312 Z M 5.402344 2.570312 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#cfd9a62bf5)">
      <g mask="url(#e2df1999be)">
        <g transform="matrix(1, 0, 0, 1, 77, 0)">
          <g clipPath="url(#8acda35d9b)">
            <g clipPath="url(#20166352dd)">
              <path
                fill="#fa4a59"
                d="M 6.058594 2.570312 C 6.058594 2.230469 5.992188 1.902344 5.863281 1.585938 C 5.734375 1.273438 5.546875 0.992188 5.304688 0.753906 C 5.066406 0.511719 4.785156 0.324219 4.472656 0.195312 C 4.15625 0.0664062 3.828125 0 3.488281 0 C 3.148438 0 2.820312 0.0664062 2.503906 0.195312 C 2.191406 0.324219 1.910156 0.511719 1.671875 0.753906 C 1.429688 0.992188 1.246094 1.273438 1.113281 1.585938 C 0.984375 1.902344 0.917969 2.230469 0.917969 2.570312 C 0.917969 2.910156 0.984375 3.238281 1.113281 3.554688 C 1.246094 3.867188 1.429688 4.148438 1.671875 4.386719 C 1.910156 4.628906 2.191406 4.8125 2.503906 4.945312 C 2.820312 5.074219 3.148438 5.140625 3.488281 5.140625 C 3.828125 5.140625 4.15625 5.074219 4.472656 4.945312 C 4.785156 4.8125 5.066406 4.628906 5.304688 4.386719 C 5.546875 4.148438 5.734375 3.867188 5.863281 3.554688 C 5.992188 3.238281 6.058594 2.910156 6.058594 2.570312 Z M 6.058594 2.570312 "
                fillOpacity={1}
                fillRule="nonzero"
              />
              <path
                fill="#fa4a59"
                d="M 6.058594 2.570312 C 6.058594 2.230469 5.992188 1.902344 5.863281 1.585938 C 5.734375 1.273438 5.546875 0.992188 5.304688 0.753906 C 5.066406 0.511719 4.785156 0.324219 4.472656 0.195312 C 4.15625 0.0664062 3.828125 0 3.488281 0 C 3.148438 0 2.820312 0.0664062 2.503906 0.195312 C 2.191406 0.324219 1.910156 0.511719 1.671875 0.753906 C 1.429688 0.992188 1.246094 1.273438 1.113281 1.585938 C 0.984375 1.902344 0.917969 2.230469 0.917969 2.570312 C 0.917969 2.910156 0.984375 3.238281 1.113281 3.554688 C 1.246094 3.867188 1.429688 4.148438 1.671875 4.386719 C 1.910156 4.628906 2.191406 4.8125 2.503906 4.945312 C 2.820312 5.074219 3.148438 5.140625 3.488281 5.140625 C 3.828125 5.140625 4.15625 5.074219 4.472656 4.945312 C 4.785156 4.8125 5.066406 4.628906 5.304688 4.386719 C 5.546875 4.148438 5.734375 3.867188 5.863281 3.554688 C 5.992188 3.238281 6.058594 2.910156 6.058594 2.570312 Z M 6.058594 2.570312 "
                fillOpacity={1}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g clipPath="url(#ef5a3b5f60)">
      <g clipPath="url(#c9f6d37c04)">
        <path
          fill="#825596"
          d="M 0.269531 42.300781 L 49.039062 42.300781 L 49.039062 91.070312 L 0.269531 91.070312 Z M 0.269531 42.300781 "
          fillOpacity={1}
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);
export default Right;
