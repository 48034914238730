import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  required,
  composeValidators,
  emailFormatValid,
  autocompleteValidPlaceNotRequired,
} from '../../util/validators';
import { Form, Button, FieldTextInput, LocationAutocompleteInputField, FieldSelectOption } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { PLACE_TYPE_COUNTRY, PLACE_TYPE_PLACE } from '../../util/types';
import Tooltip from '../../components/Tooltip/Tooltip';
import { careerPhaseOptions, REQUIRED_LABEL } from '../../marketplace-custom-config';

import css from './EditListingCoacheeForm.module.css';

const identity = v => v;

const EditListingCoacheeFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values,
      } = formRenderProps;

      // First name
      const firstNameLabel = intl.formatMessage({
        id: 'EditListingCoacheeForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'EditListingCoacheeForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'EditListingCoacheeForm.firstNameRequired',
      });
      const firstNameRequired = required(firstNameRequiredMessage);

      // Family name
      const familyNameLabel = intl.formatMessage({
        id: 'EditListingCoacheeForm.familyNameLabel',
      });
      const familyNamePlaceholder = intl.formatMessage({
        id: 'EditListingCoacheeForm.familyNamePlaceholder',
      });
      const familyNameRequiredMessage = intl.formatMessage({
        id: 'EditListingCoacheeForm.familyNameRequired',
      });
      const familyNameRequired = required(familyNameRequiredMessage);

      // Role title
      const roleTitleLabel = intl.formatMessage({
        id: 'EditListingCoacheeForm.roleTitleLabel',
      });
      const roleTitlePlaceholder = intl.formatMessage({
        id: 'EditListingCoacheeForm.roleTitlePlaceholder',
      });
      const roleTitleRequiredMessage = intl.formatMessage({
        id: 'EditListingCoacheeForm.roleTitleRequired',
      });
      const roleTitleRequired = required(roleTitleRequiredMessage);

      // Email
      const emailLabel = intl.formatMessage({
        id: 'EditListingCoacheeForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'EditListingCoacheeForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'EditListingCoacheeForm.emailRequired',
      });
      const emailRequired = required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'EditListingCoacheeForm.emailInvalid',
      });
      const emailValid = emailFormatValid(emailInvalidMessage);

      // Location
      const addressLabel = intl.formatMessage({ id: 'EditListingCoacheeForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCoacheeForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingCoacheeForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingCoacheeForm.addressNotRecognized',
      });

      // Reporting line
      const reportingLineLabel = intl.formatMessage({
        id: 'EditListingCoacheeForm.reportingLineLabel',
      });
      const reportingLinePlaceholder = intl.formatMessage({
        id: 'EditListingCoacheeForm.reportingLinePlaceholder',
      });

      // Career phase
      const careerPhaseLabel = intl.formatMessage({
        id: 'EditListingCoacheeForm.careerPhaseLabel',
      });
      const careerPhasePlaceholder = intl.formatMessage({
        id: 'EditListingCoacheeForm.careerPhasePlaceholder',
      });

      // Preferred coaching language
      const preferredLanguageLabel = intl.formatMessage({
        id: 'EditListingCoacheeForm.preferredLanguageLabel',
      });
      const preferredLanguagePlaceholder = intl.formatMessage({
        id: 'EditListingCoacheeForm.preferredLanguagePlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const preferredLanguageOptions = findOptionsForSelectFilter('languages', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <div className={css.rowWrapper}>
            <FieldTextInput
              className={css.firstName}
              type="text"
              id="firstName"
              name="firstName"
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              validate={firstNameRequired}
              requiredLabel={REQUIRED_LABEL}
            />
            <FieldTextInput
              className={css.familyName}
              type="text"
              id="familyName"
              name="familyName"
              label={familyNameLabel}
              placeholder={familyNamePlaceholder}
              validate={familyNameRequired}
              requiredLabel={REQUIRED_LABEL}
            />
          </div>

          <div className={css.rowWrapper}>
            <FieldTextInput
              className={css.roleTitle}
              type="text"
              id="roleTitle"
              name="roleTitle"
              label={roleTitleLabel}
              placeholder={roleTitlePlaceholder}
              validate={roleTitleRequired}
              requiredLabel={REQUIRED_LABEL}
            />
            <Tooltip
              top
              className={css.emailTooltip}
              contentClassName={css.emailTooltipContent}
              content={<FormattedMessage id={'EditListingCoacheeForm.emailTooltip'} tagName={null} />}
            >
              <FieldTextInput
                className={css.emailCoachee}
                type="text"
                id="emailCoachee"
                name="emailCoachee"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={composeValidators(emailRequired, emailValid)}
                requiredLabel={REQUIRED_LABEL}
              />
            </Tooltip>
          </div>

          <div className={css.rowWrapper}>
            <LocationAutocompleteInputField
              className={classNames(css.locationAddress, css.formMargins)}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={addressLabel}
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={autocompleteValidPlaceNotRequired(addressNotRecognizedMessage)}
              placeTypes={[PLACE_TYPE_COUNTRY, PLACE_TYPE_PLACE]}
            />
            <FieldTextInput
              className={css.reportingLine}
              type="text"
              id="reportingLine"
              name="reportingLine"
              label={reportingLineLabel}
              placeholder={reportingLinePlaceholder}
            />
          </div>

          <div className={css.rowWrapper}>
            <FieldSelectOption
              className={css.dropdownField}
              id="careerPhase"
              name="careerPhase"
              options={careerPhaseOptions}
              label={careerPhaseLabel}
              placeHolder={careerPhasePlaceholder}
            />
            <FieldSelectOption
              className={css.dropdownField}
              id="preferredLanguage"
              name="preferredLanguage"
              options={preferredLanguageOptions}
              label={preferredLanguageLabel}
              placeHolder={preferredLanguagePlaceholder}
            />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCoacheeFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingCoacheeFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingCoacheeFormComponent);
